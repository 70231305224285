import { MODULE_TABLE } from '@app/app.config'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import {
    IDS,
    MODULE_VERSION,
    PASSWORD_STRENGTH_LIMIT,
    REGISTER_FORMIK_INITIAL_VALUES,
    REGISTER_VALIDATION_SCHEMA
} from '@registration/constants'
import { RegisterKeys } from '@registration/type'
import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
// for react-datepicker locale. requires date-fns.

import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import {
    selectCurrentWorkflowStep,
    selectWorkflowCount,
    selectWorkflowId
} from '@login/slices/slice.workflow'
import {
    useGetOverallProgressMutation,
    useSetAccountMutation,
    useUpdateWorkflowProgressMutation
} from '@registration/api'
import { toast } from 'react-toastify'

import UpdateWorkflowProgressConsumer from '@login/workflow/UpdateWorkflowConsumer'
import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/registration/Components'
import { useMediaQuery } from 'react-responsive'

import FixedImage from '@app/components/FixedImage'
import { TokenData } from '@app/types/type.token'
import _ from 'lodash'
import { useDebouncedCallback } from 'use-debounce'

function Registration () {
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)
    const currentWorkflowStep = useAppSelector(selectCurrentWorkflowStep)
    const workflowId = useAppSelector(selectWorkflowId)
    const workflowCount = useAppSelector(selectWorkflowCount)

    const validateAPIPath = useValidateAPIPath()
    const revalidateToken = useRevalidateToken()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }
    const [
        updateWorkflowProgress,
        updateWorkflowProgressMutation
    ] = useUpdateWorkflowProgressMutation()

    const [showPassword, toggleShowPassword] = useState(false)
    const [showRepeatPassword, toggleShowRepeatPassword] = useState(false)
    const [strength, setStrength] = useState(0)

    const [setAccount, setAccountMutation] = useSetAccountMutation()
    const [weakPasswordMessage, setWeakPasswordMessage] = useState<string>('')

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        initializeSidebarVisibility(false)
    }, [])

    const calculateStrength = (value: string) => {
        let score = 0
        if (value.length >= 8) {
            score += 1
        }
        if (/[a-z]/.test(value)) {
            score += 1
        }
        if (/[A-Z]/.test(value)) {
            score += 1
        }
        if (/[0-9]/.test(value)) {
            score += 1
        }
        if (/[$&+,:;=?@#|'<>.^*()%!-]/.test(value)) {
            score += 1
        }

        setStrength(
            Math.min(
                Math.round(
                    score / (PASSWORD_STRENGTH_LIMIT / 10)),
                PASSWORD_STRENGTH_LIMIT
            )
        )
    }

    useEffect(() => {
        if (setAccountMutation.error) {
            const message = getErrorText(setAccountMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [setAccountMutation.error])

    const {
        handleSubmit, handleChange, values, errors
    } = useFormik({
        initialValues: REGISTER_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: REGISTER_VALIDATION_SCHEMA(
            strings.app?.message.error.email || '',
            strings.app?.message.error.empty || '',
            strings.app?.message.error.password_match || ''
        ),
        onSubmit: useDebouncedCallback((values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken({
                        value: token.value,
                        id: token.id
                    }, token.mode)
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.registration.moduleName,
                        MODULE_TABLE.registration.apiPaths.setAccount.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        setAccount({
                            authToken: newToken.value,
                            data: {
                                password: values.password,
                                username: values.username
                            }
                        })
                    }
                }
            }
            call()
        }, 1000)
    })

    useEffect(() => {
        const data = setAccountMutation.data

        const call = async () => {
            if (currentWorkflowStep) {
                if (data?.status === 'OK') {
                    if (token.valid) {
                        const newToken = await revalidateToken({
                            value: token.value,
                            id: token.id
                        }, token.mode)
                        const percentComplete = 100
                        const isValid = validateAPIPath(
                            activeModules.arr,
                            MODULE_TABLE.registration.moduleName,
                            MODULE_TABLE.registration.apiPaths.updateWorkflowProgress.path,
                            true
                        )

                        if (isValid && newToken.value) {
                            updateWorkflowProgress({
                                authToken: newToken.value,
                                data: {
                                    stepId: currentWorkflowStep.stepId,
                                    workflowId,
                                    percentComplete
                                }
                            })
                        }
                    }
                } else if (data?.status === 'NOT_OK') {
                    toast.error(data?.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                    setWeakPasswordMessage(data.message)
                }
            }
        }
        call()
    }, [setAccountMutation.data])

    useEffect(() => {
        calculateStrength(values.password)
    }, [values.password])

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
             * to the server.
             */
        unsubscribeGetOverallProgress()

        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.registration.moduleName,
                        MODULE_TABLE.registration.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getOverallProgressPromise && getOverallProgressPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    const SubmitButton = useMemo(() => {
        const buttonContent = setAccountMutation.isLoading ||
                updateWorkflowProgressMutation.isLoading
            ? (
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>
                                {strings.app?.text?.submitting || ''}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>
            )
        // if it's not the last step, use "next", else "complete"
            : <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col text-center'}>
                        {
                            currentWorkflowStep && currentWorkflowStep.stepSequence < workflowCount
                                ? strings.app?.text.next
                                : strings.app?.text.complete
                        }
                    </div>
                    <div className={'col-auto'}>
                        <i className={'fa-regular fa-arrow-right float-end'}
                            aria-hidden={'true'} ></i>
                    </div>
                </div>
            </div>

        return (<button type={'submit'}
            disabled={setAccountMutation.isLoading ||
                updateWorkflowProgressMutation.isLoading}
            form={IDS.REGISTER.FORM} className={'btn btn-primary btn-lg w-100'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    {buttonContent}
                </div>
            </div>
        </button>)
    }, [strings, setAccountMutation, currentWorkflowStep, workflowCount,
        updateWorkflowProgressMutation.isLoading])

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: RegisterKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                handleSubmit()
            }
        }
    }

    const UsernameInput = useMemo(() => {
        const fieldName: RegisterKeys = 'username'

        return <div className={'form-group mb-4'}>
            <label htmlFor={IDS.REGISTER.USERNAME} className={'form-label'}>{
            `${ strings.registration?.text.form.username || '' } `
            }</label>
            <input type={'text'}
                value={values.username}
                className={`form-control ${ errors.username && 'border-danger' }`}
                name={fieldName}
                placeholder={strings.registration?.text?.form.username || ''}
                id={IDS.REGISTER.USERNAME}
                onChange={(e) => {
                    e.target.value = e.target.value.replace(/\s/g, '')
                    handleChange(e)
                }}
                onKeyDown={(e) => {
                    handleKeyDown(e, 'password')
                }}
                required />
            <div className={'form-text error'}>{
                errors.username
                    ? errors.username
                    : null
            }</div>
        </div>
    }, [strings, values.username, errors.username])

    const PasswordInput = useMemo(() => {
        const fieldName: RegisterKeys = 'password'

        return <div className={'form-group  mb-3'}>
            <label htmlFor={IDS.REGISTER.PASSWORD} className={'form-label'}>
                {strings.registration?.text?.form.password || ''}</label>
            <div className={'form-control-wrapper icon-end'}>
                <input type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    className={`form-control ${ errors.password && 'border-danger' }`}
                    placeholder={strings.registration?.text?.form.password || ''}
                    id={IDS.REGISTER.PASSWORD}
                    name={fieldName} onChange={handleChange}
                    onKeyDown={(e) => {
                        handleKeyDown(e, 'repeatPassword')
                    }}
                    data-parsley-trigger={'blur'} required />
                <label className={'form-floaticon'} htmlFor={IDS.REGISTER.PASSWORD}>
                    <div className={'icon '} onClick={() => {
                        toggleShowPassword(!showPassword)
                    }}>
                        {!showPassword
                            ? <i className={'fa-regular fa-eye'}
                                aria-hidden={'true'}>
                            </i>
                            : <i className={'fa-regular fa-eye-slash'}
                                aria-hidden={'true'}>
                            </i>}
                    </div>
                </label>
            </div>
            <div className={'form-text error'}>{
                errors.password
                    ? errors.password
                    : weakPasswordMessage
            }</div>
        </div>
    }, [weakPasswordMessage,
        strings, values.password, errors.password, showPassword])

    const RepeatPasswordInput = useMemo(() => {
        const fieldName: RegisterKeys = 'repeatPassword'

        return <div className={'form-group  mb-4 mt-2'}>
            <label htmlFor={IDS.REGISTER.REPEAT_PASSWORD} className={'form-label'}>
                {strings.registration?.text?.form.repeat_password || ''}</label>
            <div className={'form-control-wrapper icon-end'}>
                <input type={showRepeatPassword ? 'text' : 'password'}
                    value={values.repeatPassword}
                    className={`form-control ${ errors.repeatPassword && 'border-danger' }`}
                    placeholder={strings.registration?.text?.form.repeat_password || ''}
                    id={IDS.REGISTER.REPEAT_PASSWORD}
                    name={fieldName} onChange={handleChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') { handleSubmit() }
                    }}
                    data-parsley-trigger={'blur'} required />
                <label className={'form-floaticon'} htmlFor={IDS.REGISTER.REPEAT_PASSWORD}>
                    <div className={'icon '} onClick={() => {
                        toggleShowRepeatPassword(!showRepeatPassword)
                    }}>
                        {!showRepeatPassword
                            ? <i className={'fa-regular fa-eye'}
                                aria-hidden={'true'}>
                            </i>
                            : <i className={'fa-regular fa-eye-slash'}
                                aria-hidden={'true'}>
                            </i>}
                    </div>
                </label>
            </div>
            <div className={'form-text error'}>{
                errors.repeatPassword
                    ? errors.repeatPassword
                    : null
            }</div>
        </div>
    }, [strings, values.repeatPassword, errors.repeatPassword, showRepeatPassword])

    const passwordStrengthCells = useMemo(() => {
        const items = []

        for (let i = 0; i < PASSWORD_STRENGTH_LIMIT; i++) {
            items.push(
                <div key={i} className={`meter-item ${
                    i < strength ? 'active' : ''
                }`}></div>
            )
        }

        return (
            <div className={'password-strength-meter mb-4'}>
                {items}
            </div>
        )
    }, [strength])

    const stepSequenceCells = useMemo(() => {
        const items = []

        for (let i = 0; i < workflowCount; i++) {
            items.push(
                <li key={i} className={'page-item'}>
                    <a className={`page-link clickable ${
                    i === ((currentWorkflowStep?.stepSequence || 0) - 1) ? 'active' : ''
                }`} onClick={(e) => {
                        e.preventDefault()
                    }}></a>
                </li>
            )
        }

        return (
            <ul className={'pagination justify-content-center'}>
                {items}
            </ul>
        )
    }, [currentWorkflowStep, workflowCount])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'header py-3 text-center'}>
        {/* image of logo goes here. fixed width but height can change whatever */}
        <img src={'/images_new/header/logo.svg'} />
    </div>

    const desktopResult = <>
        {/* put header image here */}
        <FixedImage
            imageUrl={'/images_new/header/logo.svg'} position={'bottom-middle'}
        />
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
        <HeaderGradient />

        <div className={'position-absolute w-100 main-content'}>
            <div className={'container card shadow border-0 mb-20'}>

                <div className={'justify-content-center row'}>
                    <div className={'col-10 px-0'}>
                        {/* status card */}
                        <div className={'status-card card border-0'}>
                            <h4>
                                <p className={'mt-2'}>
                                    {strings.login?.message.hello}
                                </p>
                            </h4>
                            <span>
                                {strings.registration?.text.title}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'row form-container'}>
                    <div className={'col-12 col-md-8 col-lg-7 mx-auto'}>
                        <form id={IDS.REGISTER.FORM} onSubmit={handleSubmit}>
                            {UsernameInput}
                            {PasswordInput}
                            {passwordStrengthCells}
                            {RepeatPasswordInput}
                        </form>
                    </div>
                </div>
                <div className={'row align-items-center justify-content-center mb-5'}>
                    <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                        {SubmitButton}
                    </div>
                </div>
                <div className={'row align-items-center justify-content-center'}>
                    <div className={'col-auto'}>
                        {stepSequenceCells}
                    </div>
                </div>
            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>
    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-12'}>
            <div className={'justify-content-center row'}>
                <div className={'col-12'}>
                    {/* status card */}
                    <div className={'status-card card border-0'}>
                        <span className={'fw-semibold mb-2'}>
                            {strings.login?.message.hello}
                        </span>
                        <span>
                            {strings.registration?.text.title}
                        </span>
                    </div>
                </div>
            </div>

            <div className={'form-container'}>
                <div >
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                            <form id={IDS.REGISTER.FORM} onSubmit={handleSubmit}>
                                {UsernameInput}
                                {PasswordInput}
                                {passwordStrengthCells}
                                {RepeatPasswordInput}
                            </form>

                        </div>
                    </div>
                </div>
                <div >

                    <div className={'row align-items-center justify-content-center mb-5'}>
                        <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                            {SubmitButton}
                        </div>
                    </div>
                    <div className={'row align-items-center justify-content-center'}>
                        <div className={'col-auto'}>
                            {stepSequenceCells}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>

    return (<div>
        <div className={'registration-page'}>

            <UpdateWorkflowProgressConsumer
                data={updateWorkflowProgressMutation.data}
                originalArgs={updateWorkflowProgressMutation.originalArgs}
            />

            {
                isMobile ? mobileResult : desktopResult
            }

        </div>
    </div>
    )
}

export default Registration
