import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { PublicClientApplication } from '@azure/msal-browser'
import { MSAL_CONFIG } from '@login/constants'

import { MODULE_TABLE } from '@app/app.config'
import { AuthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react'
import { replace } from '@lagunovsky/redux-react-router'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

const pca = new PublicClientApplication(MSAL_CONFIG)

const Button = () => {
    const { instance } = useMsal()

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const dispatch = useAppDispatch()

    const validateRoute = useValidateRoute()

    const handleLogin = async () => {
        try {
            const loginResponse = await instance.loginPopup({
                scopes: [], // Add your required scopes here,
                prompt: 'select_account' // Forces account selection even if user is authenticated
            })

            // Extract useful information from the login response
            const { idToken } = loginResponse

            // Console log the idToken and any redirect URI if available
            console.log('SSO Login Successful!')

            // store to localStorage.
            localStorage.setItem('sso_idToken', idToken)

            const isValid = validateRoute(
                activeModules.arr,
                MODULE_TABLE.login.moduleName,
                MODULE_TABLE.login.routes.sso,
                true
            )

            if (isValid) {
                dispatch(replace(isValid.route))
            }

            // // cheat
            // dispatch(replace(MODULE_TABLE.login.routes.sso))
        } catch (error) {
            console.error('Login Failed:', error)
        }
    }

    return (
        <button className={'btn btn-primary sso-microsoft btn-lg w-100'} onClick={handleLogin}>
            <div className={'align-items-center justify-content-center gy-2 gx-1 row'}>
                <div className={'col-auto'}>
                    <i className={'fa-2xl fa-brands fa-windows'} aria-hidden={'true'}></i>
                </div>
                <div className={'col-auto'}>
                    <span className={'ms-2'}>{strings.login?.text.form.sso?.microsoft}</span>
                </div>
            </div>
        </button>
    )
}

const MicrosoftPopupButton = () => {
    return (
        <MsalProvider instance={pca}>
            <AuthenticatedTemplate>
                {/* Optional: Display authenticated info here */}
            </AuthenticatedTemplate>
            <div className={'row row-cols-2 g-3'}>
                <div className={'col'}><Button/></div>
            </div>
        </MsalProvider>
    )
}

export default MicrosoftPopupButton
