import { MODULE_TABLE } from '@app/app.config'
import {
    ACTION_MUTATION_PROMISE,
    DESKTOP_RESPONSIVE_LIMIT,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import {
    useInitializeSidebarVisibility
} from '@login/MutationProvider/initializeSidebarVisibility'

import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import {
    useGetMotivationalMessageMutation,
    useGetOverallProgressMutation,
    useGetPersonalCareplanMutation
} from '@careplan/api'
import { IDS, MODULE_VERSION } from '@careplan/constants'
import {
    CareplanParams,
    PersonalCareplanData,
    RecoveryPlanSearchActions,
    RecoveryPlanSearchState
} from '@careplan/type'
import { push } from '@lagunovsky/redux-react-router'
import _ from 'lodash'
import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import NewDatePicker from '@app/components/NewDatePicker'
import { TokenData } from '@app/types/type.token'

import {
    CardImageWrapper,
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/recoveryPlan/Components'
import {
    addDays,
    format,
    fromUnixTime,
    getUnixTime,
    isThisWeek,
    isToday,
    isValid,
    startOfDay,
    startOfWeek
} from 'date-fns'
import produce from 'immer'
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Progress } from 'reactstrap'

import {
    FloatingArrow,
    FloatingFocusManager,
    arrow,
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
    useId
} from '@floating-ui/react'
import { useMediaQuery } from 'react-responsive'

const ProgressBarPopover = ({ actualProgress, progressNumber }: {
    actualProgress: number | undefined
    progressNumber: number | undefined
}) => {
    const arrowRef = useRef(null)
    const strings = useAppSelector(selectStrings)

    const { refs, floatingStyles, context } = useFloating({
        open: true,
        placement: 'bottom',
        middleware: [
            offset(10),
            flip({ fallbackAxisSideDirection: 'end' }),
            shift(),
            arrow({
                element: arrowRef
            })
        ],
        whileElementsMounted: autoUpdate

    })

    const headingId = useId()

    return <>
        <Progress
            className={'actual-recovery'}
            barClassName={'actual-recovery-bar'}
            value={actualProgress}
        >
        </Progress>

        <div className={'expected-recovery'} ref={refs.setReference}>
            <Progress
                barClassName={'expected-recovery-bar'}
                value={progressNumber}
            >
            </Progress>
        </div>
        { progressNumber !== undefined && <FloatingFocusManager context={context} modal={false}>
            <div
                className={'floating-ui'}
                ref={refs.setFloating}
                style={{
                    ...floatingStyles
                }}
                aria-labelledby={headingId}
            >
                <FloatingArrow ref={arrowRef} context={context}
                />
                {
                    <span className={'fw-light progress-label'}>{[
                        [
                            progressNumber,
                            '%'
                        ].join(''),
                        strings.careplanPatient?.text.careplanMenu.progress_bars.expected
                    ].join(' ')}</span>
                }
            </div>

        </FloatingFocusManager>}

    </>
}

const OneCareplanMenu = () => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)

    const validateRoute = useValidateRoute()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const { personalCareplanId } = useParams<CareplanParams>()
    const dateFormats = useAppSelector(selectDateFormats)

    const [getPersonalCareplan, getPersonalCareplanMutation] = useGetPersonalCareplanMutation()
    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()
    const [getMotivationalMessage, getMotivationalMessageMutation] =
     useGetMotivationalMessageMutation()
    const [showOffcanvas, setShowOffcanvas] = useState(false)
    const rootRef = useRef<HTMLDivElement | null>(null)

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const isMedium = useMediaQuery({
        minWidth: MOBILE_RESPONSIVE_LIMIT,
        maxWidth: DESKTOP_RESPONSIVE_LIMIT
    })

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    // already ruled out parent components and routers
    // already ruled out said state variables here.
    // so what's causing this.
    // one of the components below here is causing a re-render

    // useEffect(() => {
    //     console.log('running on undefined')
    // }, undefined)

    const getMinMaxDoneWhen = (data: PersonalCareplanData[]): { min: number, max: number } => {
        if (data.length === 0) {
            return {
                min: 0,
                max: 0
            }
        }

        return data.reduce((result, item) => {
            const doneWhen = item.carePlanStep?.doneWhen || 0 // Assuming doneWhen is of number type

            if (doneWhen < result.min) {
                result.min = doneWhen
            }

            if (doneWhen > result.max) {
                result.max = doneWhen
            }

            return result
        }, {
            min: data[0]?.carePlanStep?.doneWhen || 0,
            max: data[0]?.carePlanStep?.doneWhen || 0
        })
    }

    const [recoveryPlanSearchState, recoveryPlanSearchDispatch] = useReducer(
        (state: RecoveryPlanSearchState, action: RecoveryPlanSearchActions) => {
            switch (action.type) {
                case 'UPDATE_SEARCH': {
                    return produce(state, draft => {
                        draft.search = {
                            everything: false,
                            day: false,
                            week: false
                        }
                        draft.search[action.value.key] = action.value.value
                    })
                } case 'UPDATE_TYPE_FILTERS': {
                    return produce(state, draft => {
                        draft.stepTypeFilters[action.value.key] = action.value.filterValue
                    })
                } case 'UPDATE_STATE_FILTERS': {
                    return produce(state, draft => {
                        draft.stepStateFilters[action.value.key] = action.value.filterValue
                    })
                }
                case 'UPDATE_DATALABELS_FILTERS': {
                    return produce(state, draft => {
                        draft.dataLabelsFilters[action.value.key] = action.value.filterValue
                    })
                } case 'UPDATE_DATE': {
                    return produce(state, draft => {
                        draft.dateRange[action.value.key] = action.value.date
                    })
                } case 'REMOVE_FILTERS': {
                    return produce(state, draft => {
                        draft.search = {
                            everything: true,
                            day: false,
                            week: false
                        }

                        _.forEach(draft.stepTypeFilters, (val, key, obj) => {
                            obj[key] = false
                        })

                        _.forEach(draft.stepStateFilters, (val, key, obj) => {
                            obj[key] = false
                        })

                        _.forEach(draft.dataLabelsFilters, (val, key, obj) => {
                            obj[key] = false
                        })

                        const data = getPersonalCareplanMutation.data

                        if (data) {
                            const arr = _.isArray(getPersonalCareplanMutation.data?.data)
                                ? getPersonalCareplanMutation.data?.data || []
                                : []

                            const { min, max } = getMinMaxDoneWhen(arr)
                            draft.dateRange = {
                                start: min,
                                end: max
                            }
                        }
                    })
                } case 'UPDATE_GROUPED': {
                    return produce(state, draft => {
                        draft.grouped = action.value
                    })
                } case 'UPDATE_COLLAPSIBLE': {
                    return produce(state, draft => {
                        draft.grouped[action.value.key].collapsible = action.value.value
                    })
                } case 'UPDATE_TOTAL_GOAL': {
                    return produce(state, draft => {
                        draft.total_goal = action.value
                    })
                }
            }
        }, {
            search: {
                everything: true,
                day: false,
                week: false
            },
            stepTypeFilters: {},
            stepStateFilters: {},
            dataLabelsFilters: {},
            dateRange: {
                start: getUnixTime(new Date()),
                end: getUnixTime(new Date())
            },
            grouped: {},
            viewMode: 'row',
            total_goal: false

        }
    )

    const unsubscribeGetPersonalCareplan = () => {
        const unsubscribeMutation = getPersonalCareplan({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGetMotivationalMessage = () => {
        const unsubscribeMutation = getMotivationalMessage({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetPersonalCareplan()
        unsubscribeGetOverallProgress()
        unsubscribeGetMotivationalMessage()

        let getPersonalCareplanPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getMotivationalMessagePromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths.getPersonalCareplan.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getPersonalCareplanPromise = getPersonalCareplan({
                            authToken: newToken.value,
                            data: {
                                personalCarePlanId: personalCareplanId
                            }
                        })
                    }

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }

                    const isValid3 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.careplanPatient.moduleName,
                        MODULE_TABLE.careplanPatient
                            .apiPaths.getMotivationalMessage.path,
                        true
                    )

                    if (isValid3 && newToken.value) {
                        getMotivationalMessagePromise = getMotivationalMessage({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getPersonalCareplanPromise && getPersonalCareplanPromise.abort()
            getOverallProgressPromise && getOverallProgressPromise.abort()
            getMotivationalMessagePromise && getMotivationalMessagePromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    useEffect(() => {
        const data = getPersonalCareplanMutation.data

        if (data) {
            const arr = _.isArray(getPersonalCareplanMutation.data?.data)
                ? getPersonalCareplanMutation.data?.data || []
                : []

            const { min, max } = getMinMaxDoneWhen(arr)

            // min and max are already in unix timestamp
            recoveryPlanSearchDispatch({
                type: 'UPDATE_DATE',
                value: {
                    date: min,
                    key: 'start'
                }
            })

            recoveryPlanSearchDispatch({
                type: 'UPDATE_DATE',
                value: {
                    date: max,
                    key: 'end'
                }
            })

            // update filters object. get unique step types.
            const uniqueStepTypes = _.compact(_.uniq(_.map(arr, 'stepType')))

            _.forEach(uniqueStepTypes, (str) => {
                recoveryPlanSearchDispatch({
                    type: 'UPDATE_TYPE_FILTERS',
                    value: {
                        filterValue: false,
                        key: str
                    }
                })
            })

            const uniqueStepState = _.compact(_.uniq(_.map(arr, 'stepState')))

            _.forEach(uniqueStepState, (str) => {
                recoveryPlanSearchDispatch({
                    type: 'UPDATE_STATE_FILTERS',
                    value: {
                        filterValue: false,
                        key: str
                    }
                })
            })

            // datalabels. map all results.
            // this is now an array of array of datalabels.
            // flatten it and filter by true.
            // uniq too
            const uniqueData = _.flatMap(arr, (o) => {
                return o.dataLabels
            })

            // console.log(uniqueData)

            const flattenAndFiltered = _.filter(
                _.uniqBy(uniqueData, function (e) {
                    return e?.labelName
                })
                , (o) => {
                    return o?.filter === true && _.lowerCase(token.details.locale)
                        .replace(' ', '-') === o?.locale
                }
            )

            // console.log(flattenAndFiltered)

            _.forEach(flattenAndFiltered, (o) => {
                recoveryPlanSearchDispatch({
                    type: 'UPDATE_DATALABELS_FILTERS',
                    value: {
                        filterValue: false,
                        key: o?.labelName || ''
                    }
                })
            })
        }
    }, [getPersonalCareplanMutation.data])

    useEffect(() => {
        if (getPersonalCareplanMutation.error) {
            const message = getErrorText(getPersonalCareplanMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPersonalCareplanMutation.error])

    /** EVERYTHING is all records GROUPED in a week. */

    // Determine the grouping key function based on the selected option
    const groupKeyFn = (option: 'week' | 'day' | 'everything', date: Date) => {
        if (option === 'week') {
            return startOfWeek(date)
        }
        if (option === 'day') {
            return startOfDay(date)
        }
        // For "everything" option, return a constant key

        if (option === 'everything') {
            return startOfWeek(date)
        }

        return 'everything'
    }

    const filterPersonalCareplanData = (
        data: PersonalCareplanData[],
        stepTypeFilters: RecoveryPlanSearchState['stepTypeFilters'],
        stepStateFilters: RecoveryPlanSearchState['stepStateFilters'],
        dataLabelsFilters: RecoveryPlanSearchState['dataLabelsFilters'],
        dateRange: RecoveryPlanSearchState['dateRange']
    ): PersonalCareplanData[] => {
        return data.filter((item) => {
            // Apply date range filter
            const itemDate = fromUnixTime(item.carePlanStep?.doneWhen || 0)
            // Assuming doneWhen is in Unix timestamp format
            const startDate = fromUnixTime(dateRange.start)
            const endDate = fromUnixTime(dateRange.end)

            const filterStepType = () => {
            // If all filters are false, include the record anyway.
                const areAllFalse = Object.values(stepTypeFilters).every(value => value === false)

                if (areAllFalse) {
                    if (itemDate < startDate || itemDate > endDate) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    const isMatch = _.some(stepTypeFilters, (_val, key, o) => {
                        return o[key] && item.stepType === key
                    })

                    if (isMatch) {
                        return true
                    }

                    if (itemDate < startDate || itemDate > endDate) {
                        return false
                    }

                    return false
                }
            }

            const filterStepState = () => {
            // If all filters are false, include the record anyway.
                const areAllFalse = Object.values(stepStateFilters).every(value => value === false)
                if (areAllFalse) {
                    if (itemDate < startDate || itemDate > endDate) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    const isMatch = _.some(stepStateFilters, (_val, key, o) => {
                        return o[key] && item.stepState === key
                    })

                    if (isMatch) {
                        return true
                    }

                    if (itemDate < startDate || itemDate > endDate) {
                        return false
                    }

                    return false
                }
            }

            const filterDataLabels = () => {
            // If all filters are false, include the record anyway.
                const areAllFalse = Object.values(dataLabelsFilters).every(value => value === false)

                if (areAllFalse) {
                    if (itemDate < startDate || itemDate > endDate) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    const isMatch = _.some(dataLabelsFilters, (_val, key, o) => {
                        return o[key] && _.map(item.dataLabels, (p) => {
                            return p.labelName
                        }).includes(key)
                    })

                    if (isMatch) {
                        return true
                    }

                    if (itemDate < startDate || itemDate > endDate) {
                        return false
                    }

                    return false
                }
            }

            return filterStepType() && filterStepState() && filterDataLabels()
        })
    }

    useEffect(() => {
        // Sort the activities by their order.

        const arr = _.isArray(getPersonalCareplanMutation.data?.data)
            ? getPersonalCareplanMutation.data?.data || []
            : []

        // now find a way to group the results one week apart or one day apart.
        const groupArrayByTime = (
            data: PersonalCareplanData[], option: 'week' | 'day' | 'everything'
        ): Record<string, {collapsible: boolean, data: PersonalCareplanData[]}> => {
            // Group the formatted data by the grouping key

            const filtered = filterPersonalCareplanData(
                data,
                recoveryPlanSearchState.stepTypeFilters,
                recoveryPlanSearchState.stepStateFilters,
                recoveryPlanSearchState.dataLabelsFilters,
                recoveryPlanSearchState.dateRange
            )

            const groupedData = _.groupBy(
                filtered, (item) => {
                    const toDate = fromUnixTime(item.carePlanStep?.doneWhen || 0)

                    return groupKeyFn(option, toDate)
                }
            )

            // groupBy works as expected. now map each object
            // to add a collapsible object and make this part of the state
            // from now on. Idea is, finish the grouping and the sorting.
            // then finally map over the entire result and then update the object

            // console.log(groupedData)

            Object.keys(groupedData).forEach((key) => {
                groupedData[key] = _.sortBy(groupedData[key], [(obj) => {
                    const matchNumber: RegExpMatchArray | null | undefined =
                    obj.carePlanStep?.sequenceStep?.toString().match(/\d+/)

                    if (matchNumber) {
                        return parseInt(matchNumber[0])
                    } else {
                        return 0
                    }
                }])
            })

            const finalResult = _.fromPairs(
                _.map(
                    _.entries(groupedData)
                    , ([key, value]) => {
                        return [key, {
                            collapsible: true,
                            data: value
                        }]
                    })
            )

            // console.log(finalResult)

            return finalResult
        }

        // sort whichiver is true.
        const grouped: Record<string, {collapsible: boolean, data: PersonalCareplanData[]}> =
        recoveryPlanSearchState.search.everything === true
            ? groupArrayByTime(arr, 'everything')
            : recoveryPlanSearchState.search.week === true
                ? groupArrayByTime(arr, 'week')
                : recoveryPlanSearchState.search.day === true
                    ? groupArrayByTime(arr, 'day')
                    : {}

        recoveryPlanSearchDispatch({
            type: 'UPDATE_GROUPED',
            value: grouped
        })
    }, [
        getPersonalCareplanMutation.data?.data,
        recoveryPlanSearchState.search,
        recoveryPlanSearchState.stepTypeFilters,
        recoveryPlanSearchState.stepStateFilters,
        recoveryPlanSearchState.dataLabelsFilters,
        recoveryPlanSearchState.dateRange
    ])

    // so that the cards will have 0 and then the value again
    const DebouncedProgressBarComponent = ({ valueToPass }: {valueToPass: number}) => {
        const [value, setValue] = useState(0)

        // Define a debounced function to update the state with the provided value
        const debouncedUpdateValue = _.debounce((newValue) => {
            setValue(newValue)
        }, 1000) // Debounce for 2 seconds

        useEffect(() => {
            // Trigger the debounced function with the provided value
            debouncedUpdateValue(valueToPass)

            // Cleanup the debounce function on component unmount
            return () => {
                debouncedUpdateValue.cancel()
            }
        }, [valueToPass]) // Re-run the effect when valueToPass changes

        return <Progress
            value={value}
        >
        </Progress>
    }

    const menu = useMemo(() => {
        // console.log(groupArrayByTime(primarySort, 'week'))
        // console.log(groupArrayByTime(primarySort, 'day'))

        const LoadingContent = (
            <small className={'d-block text-center py-2'}>
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{
                        strings.app?.text.loading || ''
                    }</span>
                </div>
            </small>
        )

        const sortedGrouped = _.orderBy(
            _.toPairs(recoveryPlanSearchState.grouped),
            (pair) => getUnixTime(new Date(pair[0]))
            // (pair) => new Date(pair[0])
            , ['asc']
        ) || []

        // getting the first object and then the key and then checking if valid
        // if valid, check if within this week.
        let restrainedArray: [string, {
            collapsible: boolean;
            data: PersonalCareplanData[];
        // }][] = []
        }][] = []

        // console.log(_.toPairs(recoveryPlanSearchState.grouped))
        // console.log(sortedGrouped)

        // from keys, find index that matches the this week condition.
        const datesArray = _.map(sortedGrouped, ([key, value]) => {
            return key
        })

        if (recoveryPlanSearchState.search.week === true) {
            const foundIndex = datesArray.findIndex((date) => isThisWeek(new Date(date)))
            if (foundIndex !== -1) {
                restrainedArray = [sortedGrouped[foundIndex]]
            }
        } else if (recoveryPlanSearchState.search.day === true) {
            const foundIndex = datesArray.findIndex((date) => isToday(new Date(date)))
            if (foundIndex !== -1) {
                restrainedArray = [sortedGrouped[foundIndex]]
            }
        }

        // console.log(restrainedArray)
        // console.log(dateFormats.format2)

        // make sure you get the one currently this week.
        const toIterate = sortedGrouped.length
            ? (recoveryPlanSearchState.search.week === true || recoveryPlanSearchState
                .search.day === true)
                // ? [sortedGrouped[0]]
                ? restrainedArray
                : sortedGrouped
            : []

        const content = _.map(
            // if it's week, then just get the first one.
            toIterate, (
                [groupKey, { collapsible, data }], outerIndex) => {
                const outerKey = `careplan-${ outerIndex }`

                // this data needs to be sorted by doneWhen.
                const sorted = _.orderBy(data,
                    ['carePlanStep.doneWhen',
                        'carePlanStep.sequenceStep'], ['asc']) as PersonalCareplanData[]

                // console.log(sorted)

                const colImageClassName = recoveryPlanSearchState.viewMode === 'row'
                    ? 'col-auto'
                    : recoveryPlanSearchState.viewMode === 'tile' ? 'col-12' : 'col'

                const colContentClassName = recoveryPlanSearchState.viewMode === 'row'
                    ? 'col'
                    : recoveryPlanSearchState.viewMode === 'tile' ? 'col-12' : 'col'

                let imageWrapperWidth = recoveryPlanSearchState.viewMode === 'row'
                    ? '150px'
                    : recoveryPlanSearchState.viewMode === 'tile' ? '100%' : '100%'

                let imageWrapperHeight = 120

                if (isMobile && recoveryPlanSearchState.viewMode === 'row') {
                    imageWrapperWidth = '65px'
                    imageWrapperHeight = 81
                }

                const children = _.map(sorted, (obj, innerIndex) => {
                    // Generate a unique key for each group.
                    const innerKey = `${ outerKey }-${ innerIndex }`

                    /**
                     * state 1 is checked aka you clicked on complete careplan step
                     */
                    const isComplete = (obj.progress?.percentComplete || 0) >= 100 &&
                    obj.stepState === 'complete'

                    /**
                     * state 2 is locked
                     */
                    const locked = obj.stepState === 'locked'

                    /** state 3 is identified type but no content  */
                    const uncertain = obj.stepState === 'uncertain'

                    /** as for state 3.1, rely on cardIcon and cardType */

                    /** state 4 - is a full progress bar meaning you did all the
                     * lessons but haven't marked as complete yet.
                     * Just display the progress bar as is */

                    /** state 5 - is a progress bar that's incomplete.
                     * Just display the progress bar as is */

                    /**
                     * state 6 where someone marked the content as
                     * 'incomplete' he "i can't do it" button
                     */
                    const isIncomplete = obj.stepState === 'incomplete'

                    const progressBar = obj.progress
                        ?.percentComplete
                        ? <DebouncedProgressBarComponent
                            valueToPass={_.round(obj.progress
                                ?.percentComplete || 0)}
                        />
                        : ''

                    const cardTitle = <span className={
                        [
                            'title',
                            'text-truncate-2 w-100',
                            'mb-2'
                        ].join(' ')
                    }>
                        {obj?.stepName}
                    </span>

                    const renderDate = <div className={'row'}>
                        <div className={'col-auto'}>
                            <span className={'subtitle'}>
                                {strings.careplanPatient?.text.to_do_on}
                            </span>
                            <span className={'subtitle'}>
                                {format(fromUnixTime(
                                    obj.carePlanStep?.doneWhen || 0
                                ), dateFormats.format2)}
                            </span>
                        </div>
                    </div>

                    const completionStatusFlag = obj.stepState !== 'open'
                        ? <div className={[
                            'completion-status-flag text-center',
                            isComplete
                                ? 'complete'
                                : locked
                                    ? 'locked'
                                    : uncertain
                                        ? 'uncertain'
                                        : isIncomplete ? 'incomplete' : ''
                        ].join(' ')}>
                            <span className={'fw-semibold text-capitalize'}>
                                {
                                    isComplete
                                        ? strings.careplanPatient
                                            ?.text.careplanMenu.stepState.complete
                                        : locked
                                            ? strings.careplanPatient
                                                ?.text.careplanMenu.stepState.locked
                                            : uncertain
                                                ? strings.careplanPatient
                                                    ?.text.careplanMenu.stepState.uncertain
                                                : isIncomplete
                                                    ? strings.careplanPatient
                                                        ?.text.careplanMenu.stepState.incomplete
                                                    : ''
                                }
                            </span>
                        </div>
                        : ''

                    let stepTypeString = ''

                    if (obj.stepType === 'activity') {
                        stepTypeString = strings.careplanPatient
                            ?.text.careplanMenu.filters.activity || ''
                    } else if (obj.stepType === 'content') {
                        stepTypeString = strings.careplanPatient
                            ?.text.careplanMenu.filters.content || ''
                    } else if (obj.stepType === 'lesson') {
                        stepTypeString = strings.careplanPatient
                            ?.text.careplanMenu.filters.lesson || ''
                    } else if (obj.stepType === 'uncertain') {
                        stepTypeString = strings.careplanPatient
                            ?.text.careplanMenu.filters.uncertain || ''
                    } else if (obj.stepType === 'reasoning') {
                        stepTypeString = strings.careplanPatient
                            ?.text.careplanMenu.filters.reasoning || ''
                    }

                    const cardBody = <div className={'card-body position-relative'}>
                        {/* put status flag here. if obj.stepState === open don't render */}
                        {completionStatusFlag}
                        <div className={[
                            'row', isMobile ? 'g-3' : ''
                        ].join(' ')}>
                            <div className={colImageClassName}>
                                <CardImageWrapper className={''}
                                    url={obj.imageThumbnail || ''}
                                    style={{
                                        width: imageWrapperWidth,
                                        height: imageWrapperHeight
                                    }}
                                >
                                </CardImageWrapper>
                            </div>
                            <div className={[
                                colContentClassName,
                                !isMobile ? 'mb-3 mt-3' : ''
                            ].join(' ')}>
                                <div className={'row'}>
                                    <div className={'col-auto card-title'}>
                                        {cardTitle}
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col-auto'}>
                                        <span className={'subtitle'}>
                                            {renderDate}
                                        </span>
                                    </div>
                                </div>
                                <div className={
                                    'align-items-center justify-content-between row'
                                }>
                                    <div className={'col-auto'}>
                                        {/* change color based on type */}
                                        <small className={[
                                            'fw-bold content-type',
                                            obj.stepType
                                        ].join(' ')}>
                                            {stepTypeString}
                                        </small>
                                    </div>
                                    <div className={'col-4'}>
                                        {progressBar}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    return (<div className={'col'} key={innerKey}
                        onClick={() => {
                            // now dispatch to another page.

                            if (obj.stepState !== 'locked') {
                                if (obj.carePlanStep
                                    ?.personalCareplanStepId && personalCareplanId) {
                                    const foundRoute = validateRoute(
                                        activeModules.arr,
                                        MODULE_TABLE.careplanPatient.moduleName,
                                        MODULE_TABLE.careplanPatient
                                            .routes.careplanStep,
                                        true
                                    )

                                    console.log('going to careplanStepId path')
                                    if (foundRoute) {
                                        // now push but replace :personalCareplanId
                                        // with something else
                                        // AND you need to include the 4 ids you passed in.
                                        // and that you are able to refresh that
                                        // page at that time.

                                        /** new: set the selected card so the
                                             * controller has access to some items
                                             * like stepState and progress.percentComplete
                                             */

                                        dispatch(push(
                                            _.replace(
                                                _.replace(
                                                    foundRoute.route,
                                                    ':personalCareplanStepId',
                                                    obj.carePlanStep.personalCareplanStepId
                                                ),
                                                ':personalCareplanId',
                                                personalCareplanId
                                            )
                                        ))
                                    }
                                } else {
                                    //
                                }
                            } else {
                                toast.error(
                                    strings.careplanPatient?.message.error.content_locked || '',
                                    { ...TOASTIFY_DEFAULT_OPTIONS }
                                )
                            }
                        }}>
                        {/* card-activity will be something else soon */}
                        <div className={'card careplan-card'}>
                            {cardBody}
                        </div>

                    </div>
                    )
                })

                // Check if the groupKey is a valid date
                const isDateValid = isValid(new Date(groupKey))

                let formattedDate = ''

                // console.log(groupKey)

                if (isDateValid) {
                    // Parse the groupKey string to a Date object
                    const date = new Date(groupKey)
                    // date + 7 days
                    const nextDate = addDays(new Date(groupKey), 7)

                    formattedDate = [
                        format(date, dateFormats.format8),
                        '-',
                        format(nextDate, dateFormats.format8)
                    ].join(' ')
                }

                return <div key={outerKey} className={[
                    'mb-6 mt-lg-12 careplan-group-container',
                    outerIndex ? 'mt-5' : ''
                ].join(' ')} role={'group'}>
                    <div className={['row justify-content-between align-items-center g-3',
                        isMobile ? 'mb-3' : 'mb-5'].join(' ')}>
                        <div className={isMobile ? 'col' : 'col-auto'}>
                            <div className={'row align-items-center'}>
                                <div className={'col-auto'}>
                                    <span className={[
                                        'd-inline-block mb-0 title'
                                    ].join(' ')}>
                                        {formattedDate}
                                    </span>
                                </div>
                                {
                                    !isMobile && <div className={'col-auto ps-0'}>
                                        {/* a circle div and then a text inside */}
                                        <div className={'group-count'}>
                                            <span className={[
                                                'd-inline-block mb-0 title'
                                            ].join(' ')}>
                                                {sorted.length
                                                    ? sorted.length
                                                    : '0'}
                                            </span>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                        <div className={'col-auto'}>
                            <button
                                type={'button'}
                                className={[
                                    'btn btn-round btn-rounded me-3'
                                ].join('')}
                                onClick={() => {
                                    recoveryPlanSearchDispatch({
                                        type: 'UPDATE_COLLAPSIBLE',
                                        value: {
                                            value: !collapsible,
                                            key: groupKey
                                        }
                                    })
                                }}
                            >
                                <i
                                    className={[
                                        'fa-light',
                                        collapsible ? 'fa-chevron-up' : ' fa-chevron-down'
                                    ].join(' ')}
                                    aria-hidden={'true'}>
                                </i>
                            </button>
                        </div>
                    </div>
                    {
                        collapsible
                            ? <div className={'row row-cols-1 g-3 card-grid'}>
                                {children}
                            </div>
                            : '' }
                </div>
            })

        // Render the grouped activities with their corresponding order number.
        return (
            getPersonalCareplanMutation.isLoading
                ? LoadingContent
                : getPersonalCareplanMutation.isSuccess
                    ? content
                    : JSON.stringify(getPersonalCareplanMutation.error)
        )
    }, [
        dateFormats,
        getPersonalCareplanMutation, strings,
        recoveryPlanSearchState, isMobile
    ])

    const offCanvas = useMemo(() => {
        const availableStepTypes = _.compact(
            _.uniq(
                _.map(
                    _.isArray(getPersonalCareplanMutation.data?.data)
                        ? getPersonalCareplanMutation.data?.data || []
                        : [], (o) => o.stepType
                )
            )
        )

        const availableStepStates = _.compact(
            _.uniq(
                _.map(
                    _.isArray(getPersonalCareplanMutation.data?.data)
                        ? getPersonalCareplanMutation.data?.data || []
                        : [], (o) => o.stepState
                )
            )
        )

        const availableDataLabels = _.filter(
            _.uniqBy(
                _.flatMap(
                    _.isArray(getPersonalCareplanMutation.data?.data)
                        ? getPersonalCareplanMutation.data?.data || []
                        : [], (o) => o.dataLabels
                )
                , function (e) {
                    return e?.labelName
                }
            )
            , (o) => {
                return o?.filter === true && _.lowerCase(token.details.locale)
                    .replace(' ', '-') === o?.locale
            }
        )

        let stepTypeCheckboxes: {
            id: string
            isChecked: boolean
            name: keyof RecoveryPlanSearchState['stepTypeFilters']
            label: string | undefined
        }[] = []

        let stepStatesCheckboxes: {
            id: string
            isChecked: boolean
            name: keyof RecoveryPlanSearchState['stepStateFilters']
            label: string | undefined
        }[] = []

        let datalabelsCheckboxes: {
            id: string
            isChecked: boolean
            name: keyof RecoveryPlanSearchState['dataLabelsFilters']
            label: string | undefined
        }[] = []

        stepTypeCheckboxes = _.map(availableStepTypes, (str) => {
            let stepTypeString = ''

            if (str === 'activity') {
                stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.activity || ''
            } else if (str === 'content') {
                stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.content || ''
            } else if (str === 'lesson') {
                stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.lesson || ''
            } else if (str === 'uncertain') {
                stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.uncertain || ''
            } else if (str === 'reasoning') {
                stepTypeString = strings.careplanPatient?.text.careplanMenu.filters.reasoning || ''
            }

            return {
                id: _.upperCase('CAREPLAN__CHECK__STEP_TYPE-'.concat(str)),
                isChecked: Boolean(recoveryPlanSearchState.stepTypeFilters[str]),
                name: str,
                label: stepTypeString
            }
        })

        stepStatesCheckboxes = _.map(availableStepStates, (str) => {
            let stepString = ''

            if (str === 'complete') {
                stepString = strings.careplanPatient
                    ?.text.careplanMenu.stepState.complete || ''
            } else if (str === 'incomplete') {
                stepString = strings.careplanPatient
                    ?.text.careplanMenu.stepState.incomplete || ''
            } else if (str === 'open') {
                stepString = strings.careplanPatient
                    ?.text.careplanMenu.stepState.open || ''
            } else if (str === 'locked') {
                stepString = strings.careplanPatient
                    ?.text.careplanMenu.stepState.locked || ''
            } else if (str === 'uncertain') {
                stepString = strings.careplanPatient
                    ?.text.careplanMenu.stepState.uncertain || ''
            }

            return {
                id: _.upperCase('CAREPLAN__CHECK__STEP_STATE-'.concat(str)),
                isChecked: Boolean(recoveryPlanSearchState.stepStateFilters[str]),
                name: str,
                label: stepString
            }
        })

        datalabelsCheckboxes = _.compact(
            _.map(availableDataLabels, (obj) => {
                return obj
                    ? {
                        id: _.upperCase('CAREPLAN__CHECK__STEP_STATE-'.concat(obj.labelName)),
                        isChecked: Boolean(recoveryPlanSearchState
                            .dataLabelsFilters[obj.labelName]),
                        name: obj.labelName,
                        label: obj.labelName
                    }
                    : undefined
            })
        )

        const renderedStepTypeCheckboxes = _.map(stepTypeCheckboxes, (obj, index) => {
            const key = `checkbox-type-${ index }`

            return <div className={'mb-2 form-check'} key={key}>
                <input type={'checkbox'}
                    className={'form-check-input'}
                    id={obj.id}
                    checked={obj.isChecked}
                    onChange={() => {
                        recoveryPlanSearchDispatch({
                            type: 'UPDATE_TYPE_FILTERS',
                            value: {
                                filterValue: !obj.isChecked,
                                key: obj.name
                            }
                        })
                    }}
                />
                <label htmlFor={obj.id}>
                    <small className={'d-block'}>
                        {obj.label}
                    </small>
                </label>
            </div>
        })

        const renderedStepStatusCheckboxes = _.map(stepStatesCheckboxes, (obj, index) => {
            const key = `checkbox-status-${ index }`

            return <div className={'mb-2 form-check'} key={key}>
                <input type={'checkbox'}
                    className={'form-check-input'}
                    id={obj.id}
                    checked={obj.isChecked}
                    onChange={() => {
                        recoveryPlanSearchDispatch({
                            type: 'UPDATE_STATE_FILTERS',
                            value: {
                                filterValue: !obj.isChecked,
                                key: obj.name
                            }
                        })
                    }}
                />
                <label htmlFor={obj.id}>
                    <small className={'d-block'}>
                        {obj.label}
                    </small>
                </label>
            </div>
        })

        const renderedDataLabelsCheckboxes = _.map(datalabelsCheckboxes, (obj, index) => {
            const key = `checkbox-datalabel-${ index }`

            return <div className={'mb-2 form-check'} key={key}>
                <input type={'checkbox'}
                    className={'form-check-input'}
                    id={obj.id}
                    checked={obj.isChecked}
                    onChange={() => {
                        recoveryPlanSearchDispatch({
                            type: 'UPDATE_DATALABELS_FILTERS',
                            value: {
                                filterValue: !obj.isChecked,
                                key: obj.name
                            }
                        })
                    }}
                />
                <label htmlFor={obj.id}>
                    <small className={'d-block'}>
                        {obj.label}
                    </small>
                </label>
            </div>
        })

        const StartDate = <div className={'form-group'}>
            <label className={'form-label'} htmlFor={IDS.LIBRARY.START}>
                {strings.careplanPatient?.text.careplanMenu.date_range.from}
            </label>
            <NewDatePicker
                // also removed. users want both
                // removeIcon={true}
                id={IDS.LIBRARY.START}
                isDate={(date) => {
                    recoveryPlanSearchDispatch({
                        type: 'UPDATE_DATE',
                        value: {
                            date: getUnixTime(date),
                            key: 'start'
                        }
                    })
                }}
                singleDate={fromUnixTime(recoveryPlanSearchState.dateRange.start)}
                dateType={''}
                timeFormat={dateFormats.format5}
            />
        </div>

        const EndDate = <div className={'form-group'}>
            <label className={'form-label'} htmlFor={IDS.LIBRARY.END}>
                {strings.careplanPatient?.text.careplanMenu.date_range.to}
            </label>
            <div className={'form-control-wrapper'} id={'date-wrapper'}>
                <NewDatePicker
                    // removeIcon={true}
                    id={IDS.LIBRARY.END}
                    isDate={(date) => {
                        recoveryPlanSearchDispatch({
                            type: 'UPDATE_DATE',
                            value: {
                                date: getUnixTime(date),
                                key: 'end'
                            }
                        })
                    }}
                    singleDate={fromUnixTime(recoveryPlanSearchState.dateRange.end)}
                    dateType={''}
                    timeFormat={dateFormats.format5}
                />
            </div>
        </div>

        return <Offcanvas
            // fade={true}
            // container={rootRef}
            isOpen={showOffcanvas}
            direction={'end'}
            toggle={() => {
                setShowOffcanvas(!showOffcanvas)
            }}
        >
            <OffcanvasHeader
                className={'align-items-center offcanvas-header'}
                tag={'div'}
                close={ <button
                    type={'button'}
                    className={
                        'border btn btn-round  offcanvas-trigger shadow-none'
                    }
                    onClick={() => {
                        setShowOffcanvas(!showOffcanvas)
                    }}
                >
                    <i className={'fa-light fa-xmark'} aria-hidden={'true'}></i>
                </button>}
                toggle={() => {
                    setShowOffcanvas(!showOffcanvas)
                }}
            >

                <h2 id={'offcanvas-header-label mb-0'}>
                    {strings.careplanPatient?.text.careplanMenu.filters.name}
                </h2>

            </OffcanvasHeader>
            <OffcanvasBody className={'d-flex flex-column'}>

                <div className={'mb-4'} role={'group'}>

                    <small className={'d-block fw-bold mb-2'}>
                        {strings.careplanPatient?.text.careplanMenu.filters.offcanvas.type}
                    </small>

                    {renderedStepTypeCheckboxes}

                </div>

                <div className={'mb-4'} role={'group'}>

                    <small className={'d-block fw-bold mb-2'}>
                        {strings.careplanPatient?.text.careplanMenu.filters.offcanvas.status}
                    </small>

                    {renderedStepStatusCheckboxes}

                </div>

                <div className={'mb-4'} role={'group'}>

                    <small className={'d-block fw-bold mb-2'}>
                        {strings.careplanPatient?.text.careplanMenu.filters.offcanvas.datalabels}
                    </small>

                    {renderedDataLabelsCheckboxes}

                </div>

                {'\r'}

                <div className={'mb-4'} role={'group'}>

                    <small className={'d-block fw-bold mb-2'}>
                        {strings.careplanPatient?.text.careplanMenu.filters.offcanvas.period}
                    </small>

                    <div className={['row', isMobile ? 'g-3' : ''].join(' ')}>
                        <div className={isMobile ? 'col-12' : 'col-12'}>
                            {StartDate}
                        </div>
                        <div className={isMobile ? 'col-12' : 'col-12'}>
                            {EndDate}
                        </div>
                    </div>

                </div>

                <div className={'mt-3'}>

                    <div className={isMobile ? 'row g-3' : 'row mx-2'}>
                        <div className={isMobile ? 'col-12' : 'col-6 pe-0'}>
                            <button type={'button'} className={[
                                'btn btn-primary btn-lg  mx-auto d-block h-100'
                            ].join(' ')}
                            onClick={() => {
                                setShowOffcanvas(!showOffcanvas)
                            }}>
                                {strings.careplanPatient?.text.careplanMenu
                                    .filters.offcanvas.apply_filters}
                            </button>
                        </div>
                        <div className={isMobile ? 'col-12' : 'col-6'}>
                            <button type={'button'} className={[
                                'btn btn-secondary btn-lg', 'd-block mx-auto h-100'
                            ].join(' ')}
                            onClick={() => {
                                recoveryPlanSearchDispatch({
                                    type: 'REMOVE_FILTERS'
                                })
                            }}>
                                {strings.careplanPatient?.text
                                    .careplanMenu.filters.offcanvas.remove_filters}
                            </button>
                        </div>
                    </div>

                </div>
            </OffcanvasBody>
        </Offcanvas>
    }, [
        dateFormats,
        recoveryPlanSearchState.dateRange,
        getPersonalCareplanMutation.data?.data,
        strings,
        recoveryPlanSearchState.stepTypeFilters,
        recoveryPlanSearchState.stepStateFilters,
        recoveryPlanSearchState.dataLabelsFilters,
        showOffcanvas
    ])

    const LibraryContent = useMemo(() => {
        return <ul className={'nav nav-pills nav-fill'}>
            <li className={'nav-item'}>
                <a className={[
                    'nav-link clickable',
                `${ recoveryPlanSearchState.search.everything ? 'active' : '' }`
                ].join(' ')} onClick={(e) => {
                    e.preventDefault()
                    recoveryPlanSearchDispatch({
                        type: 'UPDATE_SEARCH',
                        value: {
                            key: 'everything',
                            value: true
                        }
                    })
                }}>
                    {strings.careplanPatient?.text.careplanMenu.search.everything}
                </a>
            </li>
            {/* <li className={'nav-item'}>
                <a className={[
                    'nav-link clickable',
                `${ recoveryPlanSearchState.search.day ? 'active' : '' }`
                ].join(' ')} onClick={(e) => {
                    e.preventDefault()
                    recoveryPlanSearchDispatch({
                        type: 'UPDATE_SEARCH',
                        value: {
                            key: 'day',
                            value: true
                        }
                    })
                }}>
                    {strings.careplanPatient?.text.search.day}
                </a>
            </li> */}
            <li className={'nav-item'}>
                <a className={[
                    'nav-link clickable',
                `${ recoveryPlanSearchState.search.week ? 'active' : '' }`
                ].join(' ')} onClick={(e) => {
                    e.preventDefault()
                    recoveryPlanSearchDispatch({
                        type: 'UPDATE_SEARCH',
                        value: {
                            key: 'week',
                            value: true
                        }
                    })
                }}>
                    {/* {strings.careplanPatient?.text.search.week} */}
                    {strings.careplanPatient?.text.careplanMenu.search.this_week}
                </a>
            </li>
        </ul>
    }, undefined)

    const columnOne = <div>
        {
            (!isMobile && !isMedium) && <span className={[
                'my-3 d-inline-block',
                isMobile ? 'h3' : 'h1'
            ].join(' ')}>
                {strings.careplanPatient?.text.careplanMenu.recovery_plan}
            </span>

        }

        {(isMobile || isMedium) && <hr />}
        <div className={['row justify-content-between g-3',
            isMobile ? 'mb-3' : 'mb-5'].join(' ')}>
            <div className={'col-auto'}>
                {/* group filter */}
                {LibraryContent}
            </div>
            <div className={'col-auto'}>
                {/* collapse all and expand all button */}
                <button
                    type={'button'}
                    className={[
                        'btn btn-round btn-rounded',
                        !isMobile ? ' me-3' : ' me-2'
                    ].join(' ')}
                    onClick={() => {
                        // expand or collapse all

                        // expand all IF all items are collapsed.
                        const everyExpand = _.every(recoveryPlanSearchState.grouped, (o) => {
                            return o.collapsible === false
                        })

                        if (everyExpand) {
                            _.forEach(recoveryPlanSearchState.grouped, (obj, key) => {
                                recoveryPlanSearchDispatch({
                                    type: 'UPDATE_COLLAPSIBLE',
                                    value: {
                                        value: true,
                                        key
                                    }
                                })
                            })
                        }

                        // if at least one item is expanded, collapse all.
                        const someExpand = _.some(recoveryPlanSearchState.grouped, (o) => {
                            return o.collapsible === true
                        })

                        if (someExpand) {
                            _.forEach(recoveryPlanSearchState.grouped, (obj, key) => {
                                recoveryPlanSearchDispatch({
                                    type: 'UPDATE_COLLAPSIBLE',
                                    value: {
                                        value: false,
                                        key
                                    }
                                })
                            })
                        }
                    }}
                >
                    <i
                        className={[
                            'fa-light',
                            _.some(recoveryPlanSearchState.grouped, (obj) => {
                                return obj.collapsible === true
                            })
                                ? 'fa-angles-up'
                                : ' fa-angles-down'
                        ].join(' ')}
                        aria-hidden={'true'}>
                    </i>
                </button>
                {/* offcanvas button */}
                <button
                    type={'button'}
                    className={'btn btn-round btn-rounded'}
                    onClick={() => {
                        setShowOffcanvas(!showOffcanvas)
                    }}
                >
                    <i
                        className={'fa-light fa-filter'}
                        aria-hidden={'true'}>
                    </i>
                </button>
            </div>

        </div>

        {offCanvas}

        <div className={'container-fluid'}>
            <div className={'row'}>
                <div className={[
                    'col-12 mx-auto',
                    isMobile ? 'px-0' : ''
                ].join(' ')}>
                    <hr className={'d-lg-none'} />
                    {menu}
                </div>
            </div>
        </div>
    </div>

    const progressNumber: number | undefined = getOverallProgressMutation.data
        ?.data.progressData.expectedProgress

    const MainProgressBar = <div>
        <ProgressBarPopover
            actualProgress={getOverallProgressMutation.data?.data.progressData.actualProgress}
            progressNumber={progressNumber}
        />
    </div>

    const TodayProgressBar = useMemo(() => {
        const columnCount = 8
        const stepTypeCounts: Record<string, number> = {}
        const stepTypeCountsFinished: Record<string, number> = {}

        // Iterate through the array and count occurrences
        const arr = _.isArray(getPersonalCareplanMutation.data?.data)
            ? getPersonalCareplanMutation.data?.data || []
            : []

        arr.forEach((item) => {
            const { stepType, stepState } = item
            if (stepType) {
                stepTypeCounts[stepType] = (stepTypeCounts[stepType] || 0) + 1

                if (stepState === 'complete') {
                    stepTypeCountsFinished[stepType] = (stepTypeCountsFinished[stepType] || 0) + 1
                }
            }
        })

        // Include other stepTypes with values zero in stepTypeCountsFinished
        Object.keys(stepTypeCounts).forEach((stepType) => {
            if (!(stepType in stepTypeCountsFinished)) {
                stepTypeCountsFinished[stepType] = 0
            }
        })

        const uniqueStepTypes = Object.keys(stepTypeCounts)
        const allTotal = _.sum(Object.values(stepTypeCounts))
        const finishedTotal = _.sum(Object.values(stepTypeCountsFinished))

        // console.log(allTotal)
        // console.log(finishedTotal)
        return <div>
            <Progress
                className={'main-recovery'}
                barClassName={'main-recovery-bar'}
                value={(finishedTotal / allTotal) * 100 }
            >
            </Progress>
            <div className={'container progress-divider'}>
                <div className={'row'}>
                    {Array.from({ length: columnCount }).map((_, index) => {
                        const classes = ['col']

                        if (index !== columnCount - 1 && index !== 3) {
                            classes.push('border-end')
                        }

                        if (index >= 4) {
                            classes.push('d-none', 'd-lg-block')
                        }

                        if (index === 3) {
                            classes.push('border-lg-end')
                        }

                        return (
                            <div key={index} className={classes.join(' ')}>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={'mt-3'}>
                {
                    (isMobile && recoveryPlanSearchState.total_goal) ||
                    (!isMobile && recoveryPlanSearchState.total_goal)
                        ? <div className={'my-3'}>
                            {
                                _.map(uniqueStepTypes, (stepType, index) => {
                                    const width = stepTypeCounts[stepType] !== 0
                                        ? (stepTypeCountsFinished[stepType] /
                                    stepTypeCounts[stepType]) * 100
                                        : 0

                                    let stepTypeString = ''

                                    if (stepType === 'activity') {
                                        stepTypeString = strings.careplanPatient
                                            ?.text.careplanMenu.filters.activity || ''
                                    } else if (stepType === 'content') {
                                        stepTypeString = strings.careplanPatient
                                            ?.text.careplanMenu.filters.content || ''
                                    } else if (stepType === 'lesson') {
                                        stepTypeString = strings.careplanPatient
                                            ?.text.careplanMenu.filters.lesson || ''
                                    } else if (stepType === 'uncertain') {
                                        stepTypeString = strings.careplanPatient
                                            ?.text.careplanMenu.filters.uncertain || ''
                                    } else if (stepType === 'reasoning') {
                                        stepTypeString = strings.careplanPatient
                                            ?.text.careplanMenu.filters.reasoning || ''
                                    }

                                    return <div className={'align-items-center mt-1 row'}
                                        key={index}>
                                        <div className={'col-6 col-md step-type-label'}>
                                            <div className={'d-inline-block'}>
                                                <span className={'text-capitalize'}>{
                                                    stepTypeString
                                                }</span>
                                                <span className={'ps-2'}>{'('}{
                                                    stepTypeCounts[stepType]}{')'}</span>
                                            </div>
                                        </div>
                                        <div className={'col-6 col-md'}>
                                            <Progress
                                                className={'step-type-recovery'}
                                                barClassName={'step-type-recovery-bar'}
                                                value={width}
                                            >
                                            </Progress>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        : ''
                }
            </div>

        </div>
    }, undefined)

    const columnTwo = useMemo(() => {
        const pg1 = <div className={[
            'card progress-card',
            getMotivationalMessageMutation.data?.data.show === false ? 'mt-4' : '',
            isMobile ? 'pb-0' : ''
        ].join(' ')}>
            <div className={[
                'card-body',
                isMobile ? 'pb-0 pt-1 px-2' : ''
            ].join(' ')}>
                <h4 className={''}>
                    {[
                        getOverallProgressMutation.data?.data.progressData.actualProgress,
                        '% '
                    ].join('') + strings.careplanPatient?.text.careplanMenu.progress_bars.recovered}
                </h4>
                {MainProgressBar}
            </div>
        </div>

        const pg2 = <div className={[
            'card today-progress-card mt-4',
            isMobile ? 'pb-0' : ''
        ].join(' ')}>
            <div className={[
                'card-body',
                isMobile ? 'pb-0 pt-1 px-2' : ''
            ].join(' ')}>
                <div className={'align-items-center justify-content-between row mb-3'}>
                    <div className={'col-auto'}>
                        <h4 className={' mb-0'}>
                            {strings.careplanPatient?.text.careplanMenu.progress_bars.total_goal}
                        </h4>
                    </div>
                    <div className={'col-auto'}>
                        <button
                            type={'button'}
                            className={[
                                'btn btn-round btn-rounded me-3'
                            ].join('')}
                            onClick={() => {
                                recoveryPlanSearchDispatch({
                                    type: 'UPDATE_TOTAL_GOAL',
                                    value: !recoveryPlanSearchState.total_goal
                                })
                            }}
                        >
                            <i
                                className={[
                                    'fa-light',
                                    recoveryPlanSearchState.total_goal
                                        ? 'fa-chevron-up'
                                        : ' fa-chevron-down'
                                ].join(' ')}
                                aria-hidden={'true'}>
                            </i>
                        </button>
                    </div>
                </div>
                {TodayProgressBar}
            </div>
        </div>

        return <div>

            {
                (isMobile || isMedium) && <span className={[
                    'my-3 d-inline-block',
                    isMobile ? 'h3' : 'h1'
                ].join(' ')}>
                    {strings.careplanPatient?.text.careplanMenu.recovery_plan}
                </span>

            }

            {
                getMotivationalMessageMutation.data?.data.show === true
                    ? <div className={'status-card card border-0'}>
                        <h4 className={''}>
                            {getMotivationalMessageMutation.data?.data.heading}
                        </h4>
                        <span>
                            {getMotivationalMessageMutation.data?.data.message}
                        </span>
                    </div>
                    : ''
            }

            {
                isMedium
                    ? <div className={'row '}>
                        <div className={'col'}>{pg1}</div>
                        <div className={'col'}>{pg2}</div>
                    </div>
                    : <div>
                        {pg1}
                        {pg2}
                    </div>
            }

        </div>
    }, undefined)

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'container-fluid header text-center'}>
        <div className={'align-items-center h-100 justify-content-between row'}>
            <div className={'col-auto'}>
                {/* <h4 className={'mb-0 '} onClick={() => {
                    dispatch(toggleMenuTopSidebarMobileBar(
                        !showMenuBar.mobile.top.sidebar
                    ))
                }}>
                    <i
                        className={ [
                            'fa-light',
                            showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
                        ].join(' ')}
                    >
                    </i>
                </h4> */}
            </div>
            <div className={'col-auto'}>
                {/* image of logo goes here. fixed width but height can change whatever */}
                <img src={'/images_new/header/logo.svg'} />
            </div>
            <div className={'col-auto'}>

            </div>
        </div>

    </div>

    const desktopResult = <>
        {/* put header image here */}
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
        <HeaderGradient/>

        <div className={'main-content'}>
            {/* container with a col width and then set to center */}
            <div className={'container mx-auto'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-auto careplan-cards-container'}>
                        {columnOne}
                    </div>
                    <div className={'col-auto progress-bars-container ms-5'}>
                        {columnTwo}
                    </div>
                </div>
            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>

    const mediumResult = <>
        {/* put header image here */}
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
        <HeaderGradient />

        <div className={'main-content'}>
            {/* container with a col width and then set to center */}
            <div className={'container mx-auto'}>
                <div className={'row justify-content-center'}>
                    {columnTwo}
                    <div className={'pb-5'}>{columnOne}</div>
                </div>
            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-6'}>
            {columnTwo}
            <div className={'pb-5'}>{columnOne}</div>
        </div>
    </>

    return (<div className={[
        'recovery-plan-page', !isMobile ? 'position-relative' : ''
    ].join(' ')} ref={rootRef}>
        {
            isMobile ? mobileResult : isMedium ? mediumResult : desktopResult
        }
    </div>)
}

export default OneCareplanMenu
