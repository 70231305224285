import { MODULE_TABLE } from '@app/app.config'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import {
    IDS,
    MFA_FORMIK_INITIAL_VALUES,
    MFA_VALIDATION_SCHEMA,
    MODULE_VERSION
} from '@registration/constants'
import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
// for react-datepicker locale. requires date-fns.

import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import {
    selectCurrentWorkflowStep,
    selectWorkflowCount,
    selectWorkflowId
} from '@login/slices/slice.workflow'
import {
    useGetOverallProgressMutation,
    useGetPersonalDetailsMutation,
    useSetMFAMutation,
    useUpdateWorkflowProgressMutation,
    useValidateMFAMutation
} from '@registration/api'
import _ from 'lodash'
import { toast } from 'react-toastify'

// for react-datepicker locale. requires date-fns.
import FixedImage from '@app/components/FixedImage'
import { TokenData } from '@app/types/type.token'
import UpdateWorkflowProgressConsumer from '@login/workflow/UpdateWorkflowConsumer'

import { MfaKeys } from '@registration/type'
import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/registration/Components'
import { useMediaQuery } from 'react-responsive'
import { useDebouncedCallback } from 'use-debounce'

function SetMfa () {
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)
    const currentWorkflowStep = useAppSelector(selectCurrentWorkflowStep)
    const workflowId = useAppSelector(selectWorkflowId)
    const workflowCount = useAppSelector(selectWorkflowCount)
    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()

    const validateAPIPath = useValidateAPIPath()
    const revalidateToken = useRevalidateToken()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        initializeSidebarVisibility(false)
    }, [])

    const [setMFA, setMFAMutation] = useSetMFAMutation()
    const [validateMFA, validateMFAMutation] = useValidateMFAMutation()
    const [getPersonalDetails, getPersonalDetailsMutation] = useGetPersonalDetailsMutation()

    const [showQRForm, setShowQRForm] = useState<boolean>(false)

    const unsubscribeGetPersonalDetails = () => {
        const unsubscribeMutation = getPersonalDetails({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    const [
        updateWorkflowProgress,
        updateWorkflowProgressMutation
    ] = useUpdateWorkflowProgressMutation()

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetPersonalDetails()
        unsubscribeGetOverallProgress()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.registration.moduleName,
                        MODULE_TABLE.registration.apiPaths.getPersonalDetails.path,
                        true
                    )

                    // NOTE: not all need to show a toast error.
                    // only do this error toast method AFTER authentication.
                    if (foundApiPath && newToken.value) {
                        promise = getPersonalDetails({
                            authToken: newToken.value
                        })
                    } else {
                        if (strings.login?.message.error.api_path) {
                            toast.error(
                                `${ MODULE_TABLE.registration
                                    .apiPaths.getPersonalDetails.path }:
                                ${ strings.login?.message.error.api_path }`.trim(),
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                        }
                    }

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.registration.moduleName,
                        MODULE_TABLE.registration.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
            getOverallProgressPromise && getOverallProgressPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [activeModules.id])

    useEffect(() => {
        if (getPersonalDetailsMutation.error) {
            const message = getErrorText(getPersonalDetailsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getPersonalDetailsMutation.error])

    const mfaFormik = useFormik({
        initialValues: MFA_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: MFA_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: useDebouncedCallback(async (values) => {
            const newToken = await revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)

            const isValid = validateAPIPath(
                activeModules.arr,
                MODULE_TABLE.registration.moduleName,
                MODULE_TABLE.registration.apiPaths.validateMFA.path,
                true
            )

            if (isValid && newToken.value) {
                validateMFA({
                    authToken: token.value,
                    data: {
                        token: values.pinCode
                    }
                }).unwrap().then((data) => {
                    if (data) {
                        if (data.status === 'OK') {
                            console.log(data.message)
                            toast.success(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                            setShowQRForm(false)
                            // don't do this anymore.
                            // fetchData(token)
                            mfaFormik.resetForm()
                        } else {
                            toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                        }
                    }
                })
            }
        }, 1000)
    })

    const SubmitButton = useMemo(() => {
        const buttonContent = validateMFAMutation.isLoading ||
                updateWorkflowProgressMutation.isLoading
            ? (
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>
                                {strings.app?.text?.submitting || ''}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>
            )
        // if it's not the last step, use "next", else "complete"
            : <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col text-center'}>
                        {
                            currentWorkflowStep && currentWorkflowStep.stepSequence < workflowCount
                                ? strings.app?.text.next
                                : strings.app?.text.complete
                        }
                    </div>
                    <div className={'col-auto'}>
                        <i className={'fa-regular fa-arrow-right float-end'}
                            aria-hidden={'true'} ></i>
                    </div>
                </div>
            </div>

        return (<button type={'submit'}
            disabled={validateMFAMutation.isLoading ||
                updateWorkflowProgressMutation.isLoading}
            form={IDS.MFA.FORM} className={'btn btn-primary btn-lg w-100'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    {buttonContent}
                </div>
            </div>
        </button>)
    }, [strings, validateMFAMutation, currentWorkflowStep, workflowCount,
        updateWorkflowProgressMutation.isLoading])

    const stepSequenceCells = useMemo(() => {
        const items = []

        for (let i = 0; i < workflowCount; i++) {
            items.push(
                <li key={i} onClick={(e) => {
                    e.preventDefault()
                }} className={'page-item'}>
                    <a className={`page-link clickable ${
                    i === ((currentWorkflowStep?.stepSequence || 0) - 1) ? 'active' : ''
                }`}></a>
                </li>
            )
        }

        return (
            <ul className={'pagination justify-content-center'}>
                {items}
            </ul>
        )
    }, [currentWorkflowStep, workflowCount])

    const triggerSetMFACall = async (bool: 'True' | 'False') => {
        const newToken = await revalidateToken({
            value: token.value,
            id: token.id
        }, token.mode)

        const foundApiPath = validateAPIPath(
            activeModules.arr,
            MODULE_TABLE.registration.moduleName,
            MODULE_TABLE.registration.apiPaths.setMFA.path,
            true
        )

        // NOTE: not all need to show a toast error.
        // only do this error toast method AFTER authentication.
        if (foundApiPath && newToken.value) {
            setMFA({
                authToken: newToken.value,
                data: {
                    mfaToggle: bool
                }
            }).unwrap()
                .then((data) => {
                    if (data.mfa === 'True') {
                        setShowQRForm(true)
                        // scroll to qr code.
                    } else if (data.mfa === 'False') {
                        setShowQRForm(false)
                    }
                })
        } else {
            if (strings.login?.message.error.api_path) {
                toast.error(
                    `${ MODULE_TABLE.registration
                        .apiPaths.getPersonalDetails.path }:
                    ${ strings.login?.message.error.api_path }`.trim(),
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }

    useEffect(() => {
        if (showQRForm) {
            const scrollToBottom = () => {
                window.scrollTo(0, document.body.scrollHeight)
            }

            // Delay the scroll by one second (1000 milliseconds)
            const delay = 500
            const timeoutId = setTimeout(scrollToBottom, delay)

            // Clean up the timeout when the component unmounts
            return () => clearTimeout(timeoutId)
        }
        return () => {}
    }, [showQRForm])

    useEffect(() => {
        // always set to true regardless of status.
        triggerSetMFACall('True')
    }, [
        getPersonalDetailsMutation.data?.data.mfa
    ])

    const QRCode = <img
        style={{ width: '10em' }}
        className={'mx-3'}
        src={`data:image/png;base64, ${ setMFAMutation.data?.image_data }`}
        alt={strings.app?.text?.qr_code_needed}
    />

    const PinCodeInput = useMemo(() => {
        const fieldName: MfaKeys = 'pinCode'

        return <div className={'form-group  mb-4'}>
            <label
                htmlFor={IDS.MFA.PIN_CODE}
                className={'form-label'}
            >{strings.profile?.text.account_settings.settings_switch.pin_code}</label>
            <input
                type={'text'}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        mfaFormik.handleSubmit()
                    }
                }}
                name={fieldName}
                className={`form-control form-control-sm ${ mfaFormik.errors.pinCode &&
                    'border-danger' }`}
                placeholder={strings.profile?.text.account_settings.settings_switch.pin_code}
                id={IDS.MFA.PIN_CODE}
                value={mfaFormik.values.pinCode}
                onChange={mfaFormik.handleChange}
                required
            />
            <div className={'form-text error'}>{
                mfaFormik.errors.pinCode
                    ? mfaFormik.errors.pinCode
                    : null
            }</div>
        </div>
    }, [
        strings,
        mfaFormik.values.pinCode,
        mfaFormik.errors.pinCode
    ])

    useEffect(() => {
        const data = validateMFAMutation.data

        const call = async () => {
            if (currentWorkflowStep) {
                if (data?.status === 'OK') {
                    if (token.valid) {
                        const newToken = await revalidateToken({
                            value: token.value,
                            id: token.id
                        }, token.mode)
                        const percentComplete = 100
                        const isValid = validateAPIPath(
                            activeModules.arr,
                            MODULE_TABLE.registration.moduleName,
                            MODULE_TABLE.registration.apiPaths.updateWorkflowProgress.path,
                            true
                        )

                        if (isValid && newToken.value) {
                            updateWorkflowProgress({
                                authToken: newToken.value,
                                data: {
                                    stepId: currentWorkflowStep.stepId,
                                    workflowId,
                                    percentComplete
                                }
                            })
                        }
                    }
                } else if (data?.status === 'NOT_OK') {
                    toast.error(data?.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                }
            }
        }
        call()
    }, [validateMFAMutation.data])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'header py-3 text-center'}>
        {/* image of logo goes here. fixed width but height can change whatever */}
        <img src={'/images_new/header/logo.svg'} />
    </div>

    const showingQRFormCondition = showQRForm
        ? <form
            id={IDS.MFA.FORM}
            onSubmit={mfaFormik.handleSubmit}
        > <div>
                <p className={isMobile ? 'h6 mb-3 mt-2' : 'h4 mb-4 mt-3'}>
                    {strings.profile?.text.account_settings
                        .settings_switch.private_key?.title
                    }</p>
                <div className={'d-flex flex-column px-4'}>
                    <p>{strings.profile?.text.account_settings
                        .settings_switch.private_key
                        ?.instructions?.[1]}</p>
                    <p>{strings.profile?.text.account_settings
                        .settings_switch.private_key
                        ?.instructions?.[2]}</p>
                    <p>{
                        [
                            strings.profile?.text.account_settings
                                .settings_switch.private_key
                                ?.instructions?.[3],
                            ':',
                            setMFAMutation.data?.code
                        ].join(' ')
                    }</p>
                    {/* then input box */}
                    <div className={'row align-items-center g-3'}>
                        <div className={'col-auto'}>
                            {QRCode}

                        </div>
                        <div className={'col'}>
                            {PinCodeInput}
                        </div>
                    </div>

                </div>
            </div>
        </form>
        : ''

    const desktopResult = <>
        {/* put header image here */}
        <FixedImage
            imageUrl={'/images_new/header/logo.svg'} position={'bottom-middle'}
        />
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
        <HeaderGradient />

        <div className={'position-absolute w-100 main-content'}>
            <div className={'container card shadow border-0 mb-20'}>

                <div className={'justify-content-center row'}>
                    <div className={'col-10 px-0'}>
                        {/* status card */}
                        <div className={'status-card card border-0'}>
                            <h4>
                                <p className={'mt-2'}>
                                    {strings.login?.message.hello}
                                </p>
                            </h4>
                            <span>
                                {strings.registration?.text.title}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'row form-container'}>
                    <div className={'col-12 col-md-8 col-lg-7 mx-auto'}>
                        {/* content goes here */}
                        {showingQRFormCondition}

                        <div className={[
                            'row align-items-center justify-content-center mb-5'
                        ].join(' ')}>
                            <div className={'col-12 col-sm-8 col-md-8 col-lg-5 '}>
                                {SubmitButton}
                            </div>
                        </div>
                        <div className={[
                            'row align-items-center justify-content-center mb-5'
                        ].join(' ')}>
                            <div className={'col-auto'}>
                                {stepSequenceCells}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>
    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-12'}>

            <div className={'justify-content-center row'}>
                <div className={'col-12'}>
                    {/* status card */}
                    <div className={'status-card card border-0'}>
                        <span className={'fw-semibold mb-2'}>
                            {strings.login?.message.hello}
                        </span>
                        <span>
                            {strings.registration?.text.title}
                        </span>
                    </div>
                </div>
            </div>

            <div className={'form-container'}>
                <div>
                    <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                        {showingQRFormCondition}
                    </div>
                </div>
                <div >
                    <div className={[
                        'row align-items-center justify-content-center mb-5'
                    ].join(' ')}>
                        <div className={'col-12 col-sm-8 col-md-8 col-lg-5 '}>
                            {SubmitButton}
                        </div>
                    </div>
                    <div className={[
                        'row align-items-center justify-content-center'
                    ].join(' ')}>
                        <div className={'col-auto'}>
                            {stepSequenceCells}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>

    return (<div>
        <div className={'registration-page'}>

            <UpdateWorkflowProgressConsumer
                data={updateWorkflowProgressMutation.data}
                originalArgs={updateWorkflowProgressMutation.originalArgs}
            />

            {
                isMobile ? mobileResult : desktopResult
            }
        </div>
    </div>)
}

export default SetMfa
