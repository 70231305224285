import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import {
    InteractionRequiredAuthError,
    InteractionStatus,
    PublicClientApplication
} from '@azure/msal-browser'
import { useDoLoginMutation, useGetTokenMutation, useTokenValidMutation } from '@login/api'
import { MSAL_CONFIG } from '@login/constants'

import { MODULE_TABLE } from '@app/app.config'
import { AuthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react'
import { replace } from '@lagunovsky/redux-react-router'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'
import { setIsFullscreenOverlay } from '@login/slices/slice.login'
import _ from 'lodash'
import { useEffect } from 'react'

const pca = new PublicClientApplication(MSAL_CONFIG)

const AlreadyLoggedInPage = () => {
    const { instance, accounts, inProgress } = useMsal()
    const [doLogin] = useDoLoginMutation({
        fixedCacheKey: 'shared-login-key'
    })
    const [tokenValid] = useTokenValidMutation()
    const [doGetToken] = useGetTokenMutation()
    const token = useAppSelector(selectToken)
    const activeModules = useAppSelector(selectActiveModules)
    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()
    const dispatch = useAppDispatch()
    // console.log('rendered right')

    const strings = useAppSelector(selectStrings)
    useEffect(() => {
        if (inProgress === InteractionStatus.None && accounts.length > 0) {
            // console.log(accounts)

            const activeIdToken: string = localStorage.getItem('sso_idToken') || ''
            const selectedAccount = _.find(accounts, ({ idToken }) => {
                return idToken === activeIdToken
            })

            if (selectedAccount) {
                const tokenRequest = {
                    account: selectedAccount,
                    // This is an example - Select account based on your app's requirements
                    scopes: ['User.Read']
                }

                // Acquire an access token
                instance.acquireTokenSilent(tokenRequest).then(async (response) => {
                    // Call your API with the
                    // access token and return the data you need to save in state
                    console.log('doing token validation before doLogin on form')
                    const revalidation = await tokenValid({ token: token.value })
                        .unwrap()

                    let activeToken = token.value
                    if (revalidation.status === 'OK') {
                        activeToken = revalidation.token
                    } else if (revalidation.status === 'NOT_OK') {
                        // get a new token again from guest and proceed.
                        const guestRetrieval = await doGetToken({
                            data: { locale: navigator.language }
                        }).unwrap()

                        if (guestRetrieval.status === 'OK') {
                            activeToken = guestRetrieval.token
                        }
                    }

                    // console.log('token string after simple revalidation is: ', activeToken)
                    if (activeToken) {
                        // proceed with login and no need to setToken
                        const isValid = validateAPIPath(
                            activeModules.arr,
                            MODULE_TABLE.login.moduleName,
                            MODULE_TABLE.login.apiPaths.doLogin.path,
                            true
                        )

                        if (isValid) {
                            doLogin({
                                authToken: token.value,
                                id_token: response.idToken
                            })

                            // enable fullscreen
                            dispatch(setIsFullscreenOverlay({
                                show: true,
                                text: strings.login?.text?.form.loading_button || ''
                            }))
                        }
                    }
                }).catch(async (e) => {
                    // Catch interaction_required errors and call interactive method to resolve
                    if (e instanceof InteractionRequiredAuthError) {
                        await instance.acquireTokenRedirect(tokenRequest)
                    }

                    throw e
                })
            } else {
                console.error('no account found go to login page ')
                const isValid = validateRoute(
                    activeModules.arr,
                    MODULE_TABLE.login.moduleName,
                    MODULE_TABLE.login.routes.login,
                    true
                )
                if (isValid) {
                    dispatch(replace(isValid.route))
                }
            }
        }
    }, [inProgress, accounts, instance])

    return <></>
}

const SSOLayout = () => {
    return (<MsalProvider instance={pca}>
        <AuthenticatedTemplate>
            <AlreadyLoggedInPage/>
        </AuthenticatedTemplate>
        {/* <UnauthenticatedTemplate>
            <div className={'row row-cols-2 g-3'}>
                <div className={'col'}><MicrosoftButton/></div>
            </div>
        </UnauthenticatedTemplate> */}
    </MsalProvider>)
}

export default SSOLayout
