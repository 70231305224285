import { MODULE_TABLE } from '@app/app.config'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import {
    IDS,
    MODULE_VERSION,
    UPDATE_TREATMENT_FORMIK_INITIAL_VALUES,
    UPDATE_TREATMENT_VALIDATION_SCHEMA
} from '@registration/constants'
import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
// for react-datepicker locale. requires date-fns.

import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { getErrorText } from '@app/app.method'
import { selectToken } from '@app/slices/slice.token'
import {
    selectCurrentWorkflowStep,
    selectWorkflowCount,
    selectWorkflowId
} from '@login/slices/slice.workflow'
import {
    useGetOverallProgressMutation,
    useGetTreatmentMutation,
    useGetUserTreatmentsMutation,
    useUpdateTreatmentMutation,
    useUpdateWorkflowProgressMutation
} from '@registration/api'
import _ from 'lodash'
import { toast } from 'react-toastify'

import { format, fromUnixTime, getUnixTime } from 'date-fns'
// for react-datepicker locale. requires date-fns.
import NewDatePicker from '@app/components/NewDatePicker'
import { UpdateTreatmentKeys } from '@registration/type'

import { TokenData } from '@app/types/type.token'
import UpdateWorkflowProgressConsumer from '@login/workflow/UpdateWorkflowConsumer'
import TreatmentsInterface from '@registration/components/TreatmentsInterface'
import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/registration/Components'
import { useMediaQuery } from 'react-responsive'

import FixedImage from '@app/components/FixedImage'

import { useDebouncedCallback } from 'use-debounce'

function TreatmentPrompt () {
    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const token = useAppSelector(selectToken)
    const currentWorkflowStep = useAppSelector(selectCurrentWorkflowStep)
    const workflowId = useAppSelector(selectWorkflowId)
    const workflowCount = useAppSelector(selectWorkflowCount)

    const validateAPIPath = useValidateAPIPath()
    const revalidateToken = useRevalidateToken()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }
    const dateFormats = useAppSelector(selectDateFormats)

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        initializeSidebarVisibility(false)
    }, [])

    const [getTreatment, getTreatmentMutation] = useGetTreatmentMutation()
    const [getUserTreatments, getUserTreatmentsMutation] = useGetUserTreatmentsMutation()
    const [
        updateTreatment,
        updateTreatmentMutation
    ] = useUpdateTreatmentMutation()

    const [
        updateWorkflowProgress,
        updateWorkflowProgressMutation
    ] = useUpdateWorkflowProgressMutation()

    const [isOutsideClick, setIsOutsideClick] = useState(false)

    const [reviewAgain, setReviewAgain] = useState(false)
    const [initialCheck, setInitialCheck] = useState<boolean | null>(null)

    const treatmentFormik = useFormik({
        initialValues: UPDATE_TREATMENT_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: UPDATE_TREATMENT_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || '',
            strings.app?.message.error.invalid_date || ''
        ),
        onSubmit: useDebouncedCallback((values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken({
                        value: token.value,
                        id: token.id
                    }, token.mode)
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.registration.moduleName,
                        MODULE_TABLE.registration.apiPaths.updateTreatment.path,
                        true
                    )

                    if (foundApiPath && getTreatmentMutation.data?.data.myCareplanId) {
                        updateTreatment({
                            authToken: newToken.value,
                            data: {
                                treatmentId: values.treatmentId,
                                carepathId: values.careplanId,
                                myCareplanId: getTreatmentMutation.data?.data.myCareplanId,
                                startDate: values.isUnknownChecked ? 0 : values.startDate || 0
                            }
                        })
                    } else {
                        console.log('condition check: ', foundApiPath,
                            getTreatmentMutation.data?.data.myCareplanId)
                    }
                }
            }
            call()
        }, 1000)
    })

    const unsubscribeGetTreatment = () => {
        const unsubscribeMutation = getTreatment({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    const unsubscribeGetUserTreatments = () => {
        const unsubscribeMutation = getUserTreatments({} as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    useEffect(() => {
        if (getTreatmentMutation.error) {
            const message = getErrorText(getTreatmentMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getTreatmentMutation.error])

    useEffect(() => {
        if (getUserTreatmentsMutation.error) {
            const message = getErrorText(getUserTreatmentsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getUserTreatmentsMutation.error])

    // function that will take you to that page if startDate is falsy.
    useEffect(() => {
        if (initialCheck !== null && initialCheck === true) {
            setReviewAgain(true)
        }
    }, [initialCheck])

    useEffect(() => {
        const data = getTreatmentMutation.data
        if (data) {
            // get treatment id.
            const id = _.find(
                getUserTreatmentsMutation.data?.data.carepaths,
                (o) => o.carepathId === data.data.careplanId
            )

            const defaultId = getUserTreatmentsMutation.data?.data.carepaths[0]

            treatmentFormik.setValues({
                treatmentId: id?.treatmentId || defaultId?.treatmentId || '',
                careplanId: id?.carepathId || defaultId?.carepathId || '',
                // no more defaults but
                startDate: data.data.startDate ? data.data.startDate : undefined,
                isUnknownChecked: data.data.startDate === 0 || data.data.startDate === undefined
            })

            // this shouldn't go here again.
            if (initialCheck === null) {
                // means unset.
                // now we set.
                setInitialCheck(
                    // get the boolean alternative of the start date value.
                    Boolean(data.data.startDate)
                )
            }
        }
    }, [getTreatmentMutation.data, getUserTreatmentsMutation.data])

    useEffect(() => {
        if (updateTreatmentMutation.error) {
            const message = getErrorText(updateTreatmentMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [updateTreatmentMutation.error])

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetTreatment()
        unsubscribeGetUserTreatments()
        unsubscribeGetOverallProgress()
        let getTreatmentPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getUserTreatmentsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)

        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const apiPath1 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.registration.moduleName,
                        MODULE_TABLE.registration.apiPaths.getTreatment.path,
                        true
                    )

                    const apiPath2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.registration.moduleName,
                        MODULE_TABLE.registration.apiPaths.getUserTreatments.path,
                        true
                    )

                    // NOTE: not all need to show a toast error.
                    // only do this error toast method AFTER authentication.
                    if (currentWorkflowStep?.stepData.personalCareplanId) {
                        if (apiPath1 && newToken.value) {
                            getTreatmentPromise = getTreatment({
                                authToken: newToken.value,
                                data: {
                                    personalCareplanId: currentWorkflowStep
                                        ?.stepData.personalCareplanId || ''
                                }
                            })
                        } else {
                            if (strings.login?.message.error.api_path) {
                                toast.error(
                                `${ MODULE_TABLE.registration
                                    .apiPaths.getTreatment.path }:
                                ${ strings.login?.message.error.api_path }`.trim(),
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                                )
                            }
                        }
                    } else {
                        toast.error(
                            `${ MODULE_TABLE.registration
                                .apiPaths.getTreatment.path }:
                            ${ strings.app?.message.error.no_careplan_id }`.trim(),
                            { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }

                    if (apiPath2 && newToken.value) {
                        getUserTreatmentsPromise = getUserTreatments({
                            authToken: newToken.value
                        })
                    } else {
                        if (strings.login?.message.error.api_path) {
                            toast.error(
                                `${ MODULE_TABLE.registration
                                    .apiPaths.getUserTreatments.path }:
                                ${ strings.login?.message.error.api_path }`.trim(),
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                            )
                        }
                    }

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.registration.moduleName,
                        MODULE_TABLE.registration.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getTreatmentPromise && getTreatmentPromise.abort()
            getUserTreatmentsPromise && getUserTreatmentsPromise.abort()
            getOverallProgressPromise && getOverallProgressPromise.abort()
        }
    }

    useEffect(() => {
        const data = updateTreatmentMutation.data

        const call = async () => {
            if (data?.status === 'OK') {
                console.log('fetching data on update treatment response')
                setReviewAgain(false)
                fetchData(token)
            }
        }
        call()
    }, [activeModules.id, updateTreatmentMutation.data])

    useEffect(() => {
        console.log('fetching data on active module change')
        return fetchData(token)
    }, [activeModules.id])

    const NoButton = useMemo(() => {
        return (<a
            onClick={(e) => {
                // if no, show ReviewAgain.tsx.
                e.preventDefault()
                setReviewAgain(true)
            }}
            className={'btn btn-secondary btn-lg w-100'}>
            <span style={{ verticalAlign: 'inherit' }}>
                <span style={{ verticalAlign: 'inherit' }}>
                    {strings.app?.text.no}
                </span>
            </span>
        </a>)
    }, [strings])

    const YesButton = useMemo(() => {
        const buttonContent = updateWorkflowProgressMutation.isLoading
            ? (
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>
                                {strings.app?.text?.submitting || ''}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>
            )
        // if it's not the last step, use "next", else "complete"
            : <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col text-center'}>
                        {
                            currentWorkflowStep && currentWorkflowStep.stepSequence < workflowCount
                                ? strings.app?.text.yes
                                : strings.app?.text.complete
                        }
                    </div>
                    <div className={'col-auto'}>
                        <i className={'fa-regular fa-arrow-right float-end'}
                            aria-hidden={'true'} ></i>
                    </div>
                </div>
            </div>

        const buttonClassName = `btn btn-primary btn-lg w-100 
            ${ !currentWorkflowStep || updateWorkflowProgressMutation.isLoading
            ? 'disabled'
            : '' }`.trim()

        // don't do the code below IF startDate is falsy
        // const data = getTreatmentMutation.data

        return <button
            // disabled value can be any now.
            // disabled={Boolean(data?.data.startDate) === false}
            className={buttonClassName}
            onClick={async (e) => {
                e.preventDefault()

                const call = async () => {
                    if (currentWorkflowStep) {
                        if (token.valid) {
                            const newToken = await revalidateToken({
                                value: token.value,
                                id: token.id
                            }, token.mode)
                            const percentComplete = 100
                            const isValid = validateAPIPath(
                                activeModules.arr,
                                MODULE_TABLE.login.moduleName,
                                MODULE_TABLE.login.apiPaths.updateWorkflowProgress.path,
                                true
                            )

                            if (isValid && newToken.value) {
                                updateWorkflowProgress({
                                    authToken: newToken.value,
                                    data: {
                                        stepId: currentWorkflowStep.stepId,
                                        workflowId,
                                        percentComplete
                                    }
                                })
                            }
                        }
                    }
                }
                call()
            }}
        >
            {buttonContent}
        </button>
    }, [strings, currentWorkflowStep, workflowCount,
        getTreatmentMutation.data, updateWorkflowProgressMutation.data])

    const stepSequenceCells = useMemo(() => {
        const items = []

        for (let i = 0; i < workflowCount; i++) {
            items.push(
                <li key={i} className={'page-item'}>
                    <a className={`page-link clickable ${
                    i === ((currentWorkflowStep?.stepSequence || 0) - 1) ? 'active' : ''
                }`} onClick={(e) => {
                        e.preventDefault()
                    }}></a>
                </li>
            )
        }

        return (
            <ul className={'pagination justify-content-center'}>
                {items}
            </ul>
        )
    }, [currentWorkflowStep, workflowCount])

    /** components from ReviewAgainForm */
    const ReviewAgainSubmitButton = useMemo(() => {
        const buttonContent = updateTreatmentMutation.isLoading ||
        updateWorkflowProgressMutation.isLoading
            ? (
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>
                                {strings.app?.text?.submitting || ''}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>
            )
            : <div className={'container'}>
                <div className={'row justify-content-between align-items-center'}>
                    <div className={'col text-center'}>
                        {
                            strings.app?.text.next
                        }
                    </div>
                    <div className={'col-auto'}>
                        <i className={'fa-regular fa-arrow-right float-end'}
                            aria-hidden={'true'} ></i>
                    </div>
                </div>
            </div>

        return (<button type={'submit'}
            disabled={(getTreatmentMutation.isSuccess === false ||
                getUserTreatmentsMutation.isSuccess === false) ||
                (updateTreatmentMutation.isLoading || updateWorkflowProgressMutation.isLoading)
            }
            form={IDS.UPDATE_TREATMENT.FORM} className={'btn btn-primary btn-lg w-100'}>
            {buttonContent}
        </button>)
    }, [strings, updateTreatmentMutation, getTreatmentMutation, getUserTreatmentsMutation])

    // cannot use the default dropdown anymore.
    const TreatmentIdSelection = useMemo(() => {
        // const fieldName: UpdateTreatmentKeys = 'treatmentId'
        // const extraFieldName: UpdateTreatmentKeys = 'careplanId'

        // const result = <div className={'form-group  mb-4'}>
        //     <label htmlFor={IDS.UPDATE_TREATMENT.TREATMENT_ID} className={'form-label'}>{
        // `${ strings.registration?.text.treatment.therapy || '' } `
        //     }</label>
        //     <select
        //         value={treatmentFormik.values.treatmentId}
        //         className={`form-control form-select ${ treatmentFormik
        //             .errors.treatmentId && 'border-danger' }`}
        //         name={fieldName}
        //         placeholder={strings.registration?.text?.treatment.therapy || ''}
        //         id={IDS.UPDATE_TREATMENT.TREATMENT_ID}
        //         onChange={(e) => {
        //             const [treatmentId, careplanId] = e.target.value.split('|')
        //             treatmentFormik.setFieldValue(fieldName, treatmentId)
        //             treatmentFormik.setFieldValue(extraFieldName, careplanId)
        //         }}
        //         required >
        //         {/* show options */}
        //         {
        //             _.map(getUserTreatmentsMutation.data?.data.carepaths, (o, i) => {
        //                 const key = [
        //                     'treatment-choice', '-', i
        //                 ].join('')
        //                 return <option key={key}
        //                     value={`${ o.treatmentId }|${ o.carepathId }`}
        //                 >{[
        //                         o.treatmentName,
        //                         '( ', o.carepathName, ')'
        //                     ].join('')}</option>
        //             })
        //         }
        //     </select>
        //     <div className={'form-text error'}>{
        //         treatmentFormik.errors.treatmentId
        //             ? treatmentFormik.errors.treatmentId
        //             : null
        //     }</div>
        // </div>

        const result = <TreatmentsInterface
            treatmentFormik={treatmentFormik}
            isOutsideClick={isOutsideClick}
            setIsOutsideClick={setIsOutsideClick}
            getUserTreatmentsResponse={getUserTreatmentsMutation.data}
        />

        return result
    }, [strings,
        isOutsideClick,
        setIsOutsideClick,
        treatmentFormik.values.treatmentId,
        treatmentFormik.errors.treatmentId,
        treatmentFormik.values.careplanId,
        treatmentFormik.errors.careplanId,
        getUserTreatmentsMutation.data])

    const DatePickerInput = useMemo(() => {
        const fieldName: UpdateTreatmentKeys = 'startDate'

        // operation_date_name for value Operation Date
        // therapeutic_date_name for value Start Date
        const dateName = getTreatmentMutation.data?.data.dateName || ''
        const label = dateName === 'operation_date_name'
            ? strings.registration?.text.treatment.operation_date
            : dateName === 'therapeutic_date_name'
                ? strings.registration?.text.treatment.therapeutic_date_name
                : ''

        return (<div className={'form-group  mb-4'}>
            <label htmlFor={IDS.UPDATE_TREATMENT.OK_DATE}
                className={'form-label'}>{`${ label } `}</label>
            <NewDatePicker
                disabled={treatmentFormik.values.isUnknownChecked}
                id={IDS.UPDATE_TREATMENT.OK_DATE}
                errors={treatmentFormik.errors.startDate}
                isDate={(date) => {
                    treatmentFormik.setFieldValue(fieldName, getUnixTime(date))
                }}
                singleDate={treatmentFormik.values.startDate !== undefined
                    ? fromUnixTime(treatmentFormik.values.startDate)
                    : undefined
                }
                dateType={''}
                timeFormat={dateFormats.format5}
            />
            <div className={'form-text error'}>{
                treatmentFormik.errors.startDate
                    ? treatmentFormik.errors.startDate
                    : null
            }</div>
            <div>
                <input type={'checkbox'}
                    id={`${ IDS.UPDATE_TREATMENT.UNKNOWN_DATE }`}
                    checked={treatmentFormik.values.isUnknownChecked}
                    className={'form-check-input'}
                    onChange={(e) => {
                        treatmentFormik.setFieldValue('isUnknownChecked', e.target.checked)
                    }}/>
                <label className={'form-check-label ps-3'}
                    htmlFor={`${ IDS.UPDATE_TREATMENT.UNKNOWN_DATE }`}>{
                        strings.profile?.text.therapeutics.unknown}</label>
            </div>
        </div>)
    }, [strings, treatmentFormik.values.startDate,
        treatmentFormik.errors.startDate, treatmentFormik.values.isUnknownChecked,
        treatmentFormik.errors.isUnknownChecked, getTreatmentMutation.data])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'header py-3 text-center'}>
        {/* image of logo goes here. fixed width but height can change whatever */}
        <img src={'/images_new/header/logo.svg'} />
    </div>

    const desktopResult = <>
        {/* put header image here */}
        <FixedImage
            imageUrl={'/images_new/header/logo.svg'} position={'bottom-middle'}
        />
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
        <HeaderGradient />

        <div className={'position-absolute w-100 main-content'}>
            <div className={'container card shadow border-0 mb-20'}>

                {
                    reviewAgain
                        ? <>
                            <div className={'justify-content-center row'}>
                                <div className={'col-10 px-0'}>
                                    {/* status card */}
                                    <div className={'status-card card border-0'}>
                                        <h4>
                                            <p className={'mt-2'}>
                                                {strings.login?.message.hello}
                                            </p>
                                        </h4>
                                        <span>
                                            {strings.registration?.text.title}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={'row form-container'}>
                                <div className={'col-12 col-md-8 col-lg-7 mx-auto'}>
                                    {/* content goes here */}

                                    <form
                                        id={IDS.UPDATE_TREATMENT.FORM}
                                        onSubmit={treatmentFormik.handleSubmit}>
                                        {TreatmentIdSelection}
                                        {DatePickerInput}
                                    </form>

                                    <div className={[
                                        'row align-items-center justify-content-center mb-5'
                                    ].join(' ')}>
                                        <div className={'col-12 col-sm-8 col-md-8 col-lg-5 '}>
                                            {ReviewAgainSubmitButton}
                                        </div>
                                    </div>
                                    <div className={[
                                        'row align-items-center justify-content-center mb-5'
                                    ].join(' ')}>
                                        <div className={'col-auto'}>
                                            {stepSequenceCells}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : <>
                            <div className={'justify-content-center row'}>
                                <div className={'col-10 px-0'}>
                                    {/* status card */}
                                    <div className={'status-card card border-0'}>
                                        <h4>
                                            <p className={'mt-2'}>
                                                {strings.login?.message.hello}
                                            </p>
                                        </h4>
                                        <span>
                                            {strings.registration?.text.review.title}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={'row form-container'}>
                                <div className={'col-12 col-md-8 col-lg-7 mx-auto'}>
                                    {/* content goes here */}

                                    <div className={'mb-5'}>
                                        <b className={'form-label'}>
                                            {strings.registration?.text.treatment.therapy}
                                        </b>
                                        <p>
                                            {getTreatmentMutation.data?.data.careplanName ||
                                            strings.profile?.text.therapeutics.unknown}
                                        </p>
                                        <b className={'form-label'}>
                                            {strings.registration?.text.treatment.operation_date }
                                        </b>
                                        <p>
                                            {getTreatmentMutation.data?.data.startDate
                                                ? format(fromUnixTime(
                                                    getTreatmentMutation.data?.data.startDate || 0
                                                ), dateFormats.format1)
                                                : strings.profile?.text.therapeutics.unknown }
                                        </p>
                                    </div>

                                    <div
                                        className={[
                                            'row align-items-center justify-content-center mb-5'
                                        ].join(' ')}
                                    >
                                        <div className={'col-6 col-md-5 col-lg-5'}>
                                            {NoButton}
                                        </div>
                                        <div className={'col-6 col-md-5 col-lg-5'}>
                                            {YesButton}
                                        </div>
                                    </div>
                                    <div
                                        className={[
                                            'row align-items-center justify-content-center'
                                        ].join(' ')}
                                    >
                                        <div className={'col-auto'}>
                                            {stepSequenceCells}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }

            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>
    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-12'}>

            {
                reviewAgain
                    ? <>

                        <div className={'justify-content-center row'}>
                            <div className={'col-12'}>
                                {/* status card */}
                                <div className={'status-card card border-0'}>
                                    <span className={'fw-semibold mb-2'}>
                                        {strings.login?.message.hello}
                                    </span>
                                    <span>
                                        {strings.registration?.text.title}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className={'form-container'}>
                            <div >
                                <div className={'col-12 col-md-8 col-lg-7 mx-auto'}>
                                    <form
                                        id={IDS.UPDATE_TREATMENT.FORM}
                                        onSubmit={treatmentFormik.handleSubmit}>
                                        {TreatmentIdSelection}
                                        {DatePickerInput}
                                    </form>
                                </div>
                            </div>
                            <div >
                                <div className={'row align-items-center ' +
                                'justify-content-center mb-5'}>
                                    <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                                        {ReviewAgainSubmitButton}
                                    </div>
                                </div>
                                <div className={'row align-items-center ' +
                                'justify-content-center mb-5'}>
                                    <div className={'col-auto'}>
                                        {stepSequenceCells}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </>
                    : <>
                        <div className={'justify-content-center row'}>
                            <div className={'col-12'}>
                                {/* status card */}
                                <div className={'status-card card border-0'}>
                                    <span className={'fw-semibold mb-2'}>
                                        {strings.login?.message.hello}
                                    </span>
                                    <span>
                                        {strings.registration?.text.treatment.title}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className={'form-container'}>
                            <div >
                                <div className={'row'}>
                                    <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                                        <div className={'mb-5'}>
                                            <b className={'form-label'}>
                                                {strings.registration?.text.treatment.therapy}
                                            </b>
                                            <p>
                                                {getTreatmentMutation.data?.data.careplanName ||
                                            strings.profile?.text.therapeutics.unknown}
                                            </p>
                                            <b className={'form-label'}>
                                                {strings.registration?.text
                                                    .treatment.operation_date }
                                            </b>
                                            <p>
                                                {getTreatmentMutation.data?.data.startDate
                                                    ? format(fromUnixTime(
                                                        getTreatmentMutation.data
                                                            ?.data.startDate || 0
                                                    ), dateFormats.format1)
                                                    : strings.profile?.text.therapeutics.unknown }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div >
                                <div
                                    className={[
                                        'row align-items-center justify-content-center mb-5 g-3'
                                    ].join(' ')}
                                >
                                    <div className={'col-12 col-sm-6 col-md-5 col-lg-5'}>
                                        {NoButton}
                                    </div>
                                    <div className={'col-12 col-sm-6 col-md-5 col-lg-5'}>
                                        {YesButton}
                                    </div>
                                </div>
                                <div
                                    className={[
                                        'row align-items-center justify-content-center mb-5'
                                    ].join(' ')}
                                >
                                    <div className={'col-auto'}>
                                        {stepSequenceCells}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }

        </div>
    </>

    return (<div>
        <div className={'registration-page'}>

            <UpdateWorkflowProgressConsumer
                data={updateWorkflowProgressMutation.data}
                originalArgs={updateWorkflowProgressMutation.originalArgs}
            />

            {
                isMobile ? mobileResult : desktopResult
            }
        </div>
    </div>)
}

export default TreatmentPrompt
