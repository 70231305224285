import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { MODULE_TABLE } from '@app/app.config'
import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { push } from '@lagunovsky/redux-react-router'
import { selectCurrentWorkflowStep, selectWorkflowId } from '@login/slices/slice.workflow'
import UpdateWorkflowProgressConsumer from '@login/workflow/UpdateWorkflowConsumer'
import {
    useGetOverallProgressMutation,
    useGetReasoningMutation,
    useReasonWithMeMutation,
    useUpdateWorkflowProgressMutation
} from '@reasonWithMe/api'
import Bodymap from '@reasonWithMe/components/types/Bodymap'
import Bool from '@reasonWithMe/components/types/Bool'
import FloatInput from '@reasonWithMe/components/types/FloatInput'
import List from '@reasonWithMe/components/types/List'
import MultiList from '@reasonWithMe/components/types/MultiList'
import MultiTile from '@reasonWithMe/components/types/MultiTile'
import NumberInput from '@reasonWithMe/components/types/NumberInput'
import Open from '@reasonWithMe/components/types/Open'
import Scale from '@reasonWithMe/components/types/Scale'
import SingleDate from '@reasonWithMe/components/types/SingleDate'
import Text from '@reasonWithMe/components/types/Text'
import { MODULE_VERSION } from '@reasonWithMe/constants'
import {
    selectQuestionInterface,
    setAnswerValue,
    setCurrentReasoningData,
    setCurrentReasoningResults
} from '@reasonWithMe/slice'
import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { toast } from 'react-toastify'
import remarkGfm from 'remark-gfm'

import { TokenData } from '@app/types/type.token'
import OpenMultiQuestion from '@reasonWithMe/components/types/multi-question/MultiQuestion'
import OpenList, {
    OpenListPreviousQuestions
} from '@reasonWithMe/components/types/open-list/OpenList'
import { GetReasoningRequest, OpenListValues, OpenMultiQuestionOption } from '@reasonWithMe/type'
import { HeaderImage } from '@stylesheet/globalStyles/group/endUser/reasonWithMe/Components'
import rehypeRaw from 'rehype-raw'
import remarkBreaks from 'remark-breaks'
import remarkDefinitionList from 'remark-definition-list'
import remarkEmoji from 'remark-emoji'
import remarkHeadingId from 'remark-heading-id'
import supersub from 'remark-supersub'
import { v4 as uuidv4 } from 'uuid'

import { getUnixTime } from 'date-fns'

import FixedImage from '@app/components/FixedImage'
import Resume from '@reasonWithMe/components/types/Resume'
import Toggle from '@reasonWithMe/components/types/Toggle'
import View from '@reasonWithMe/components/types/view/Main'
import { useMediaQuery } from 'react-responsive'
import { useDebouncedCallback } from 'use-debounce'

const QuestionInterface = () => {
    const activeModules = useAppSelector(selectActiveModules)
    const [reasonWithMe, reasonWithMeMutation] = useReasonWithMeMutation()
    const [getReasoning, getReasoningMutation] = useGetReasoningMutation()

    const revalidateToken = useRevalidateToken()
    const validateRoute = useValidateRoute()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const validateAPIPath = useValidateAPIPath()
    const currentWorkflowStep = useAppSelector(selectCurrentWorkflowStep)
    const workflowId = useAppSelector(selectWorkflowId)

    const token = useAppSelector(selectToken)
    const strings = useAppSelector(selectStrings)
    const dispatch = useAppDispatch()

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    const questionInterface = useAppSelector(selectQuestionInterface)
    const [inputKey, setInputKey] = useState<string>('')

    const [
        updateWorkflowProgress,
        updateWorkflowProgressMutation
    ] = useUpdateWorkflowProgressMutation()

    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()

    // a useState to enable validation for the current question.
    const [enableValidation, setEnableValidation] = useState(false)
    // for general div to scroll up to (multi-question atm)
    const divRef = useRef<HTMLDivElement>(null)

    // Function to scroll the div to the top
    const scrollToTop = () => {
        // console.log('scroll multi-question to top')
        if (divRef.current) {
            // console.log('reference is found')
            divRef.current.scrollIntoView()
        }
    }

    // a useState to enable truncation on form submission
    const [enableTruncation, setEnableTruncation] = useState(false)

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    /** create fetch data function */
    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetOverallProgress()

        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.reasonWithMe.moduleName,
                        MODULE_TABLE.reasonWithMe.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            getOverallProgressPromise && getOverallProgressPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [token.id, token.valid])

    const unsubscribeReasonWithMe = () => {
        const unsubscribeMutation = reasonWithMe({ data: {} } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    useEffect(() => {
        initializeSidebarVisibility(false)
    }, [])

    const fetchReasonWithMe = (token:TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeReasonWithMe()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid && questionInterface.firstReasoningSetId &&
                questionInterface.reasoningSetVersion
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.reasonWithMe.moduleName,
                        MODULE_TABLE.reasonWithMe.apiPaths.reasonWithMe.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        promise = reasonWithMe({
                            authToken: newToken.value,
                            data: {
                                // will use test data for now. this is step 3.
                                // will use the dispatches from the slice
                                // at step 2.
                                reasoningSetId: questionInterface.firstReasoningSetId,
                                reasoningSetVersion: questionInterface.reasoningSetVersion
                            }
                        })
                    } else {
                        // i know the toast error will show up if you refresh the rwm question
                        // component because the getWorkflow api call wasn't finsihed yet.
                        // do a console.error mesage instead.
                        // toast.error(strings.reason_with_me?.message.error.fetch_reason_with_me,
                        //     { ...TOASTIFY_DEFAULT_OPTIONS }
                        // )
                        console.error(
                            strings.reason_with_me?.message.error.fetch_reason_with_me
                        )
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
        }
    }

    // this is being called twice. and i hate it.// removing token.id
    // i'm back from april 5, 2024. I want to add the questionInterface dependency
    // because I believe it would be more ideal to call the dependencies that need it.
    // also, it wouldn't make sense to revalidate the token if you don't have these
    // properties at the start. moving from promise to the first parent if statement.
    useEffect(() => {
        fetchReasonWithMe(token)
    }, [
        questionInterface.firstReasoningSetId
    ])

    /** proceed to next question now. */
    const goToNextQuestion = async (token:TokenData) => {
        if (token.valid) {
            const responseData = questionInterface.currentReasonWithMeResponse.reasoningData
            const valueType = responseData.question?.valueType
            let answerValue = questionInterface.currentReasonWithMeResponse.answerValue

            /** there will be cases where you want to present the answerValue a specific
             * way. For example, for lists, if the answer is one, turn it to a string.
             */

            if (
                responseData.question?.questionType === 'list' ||
                responseData.question?.questionType === 'multi-tile' ||
                responseData.question?.questionType === 'multi-list'
            ) {
                if (_.isArray(answerValue) && answerValue.length === 1) {
                    // answerValue = answerValue[0]
                    if (valueType === 'list') {
                        // answerValue = answerValue
                    } else if (valueType === 'tuple') {
                        // answerValue = answerValue
                    } else if (valueType === 'bool') {
                        answerValue = Boolean(answerValue[0])
                    } else if (valueType === 'int') {
                        answerValue = parseInt(answerValue[0], 10)
                    } else if (valueType === 'float') {
                        const floatValue = parseFloat(answerValue[0])

                        // Check if the value is an integer (no decimal point)
                        if (Number.isInteger(floatValue)) {
                            answerValue = parseFloat(floatValue + '.0')
                        } else {
                            answerValue = floatValue
                        }
                    } else if (valueType === 'str') {
                        answerValue = String(answerValue[0])
                    }
                } else if (_.isArray(answerValue && answerValue.length > 1)) {
                    answerValue = JSON.stringify(answerValue)
                }
            } else if (responseData.question?.questionType === 'scale') {
                if (_.isArray(answerValue) && answerValue.length === 1) {
                    answerValue = answerValue[0]
                } else if (_.isArray(answerValue && answerValue.length > 1)) {
                    answerValue = JSON.stringify(answerValue)
                }
            } else if (responseData.question?.questionType === 'int32') {
                /** if it's a string, convert to number */
                // console.log('answer value before: ', answerValue)
                // console.log('typeof answerValue: ', typeof answerValue)
                // if you type +15-, the answerValue is an empty stirng.
                if (typeof answerValue === 'string') {
                    if (answerValue.length <= 0) {
                        answerValue = undefined
                    } else {
                        answerValue = Number(answerValue)
                    }
                }
                // console.log('answer value after: ', answerValue)
            } else if (responseData.question?.questionType === 'float') {
                /** if it's a string, convert to number */
                if (typeof answerValue === 'string') {
                    if (answerValue.length <= 0) {
                        answerValue = undefined
                    } else {
                        answerValue = Number(answerValue)
                    }
                }
            } else if (responseData.question?.questionType === 'bool') {
                /** if it's a string, convert to number */
                // console.log('Type is open and getting acqValue')
                if (_.has(answerValue, 'acqName') && _.has(answerValue, 'acqValue')) {
                    answerValue = answerValue.acqValue
                }
            } else if (responseData.question?.questionType === 'open-list') {
                // answerValue = JSON.stringify(answerValue)
                // shouldn't be a stringified array according to tim.
            } else if (responseData.question?.questionType === 'multi-question') {
                // answerValue = JSON.stringify(answerValue)
                // shouldn't be a stringified array according to tim.
            } else if (responseData.question?.questionType === 'toggle') {
                if (_.isArray(answerValue) && answerValue.length === 1) {
                    answerValue = answerValue[0]
                } else if (_.isArray(answerValue && answerValue.length > 1)) {
                    answerValue = JSON.stringify(answerValue)
                }
            }

            const newToken = await revalidateToken({
                value: token.value,
                id: token.id
            }, token.mode)

            if (responseData.question && responseData.reasoningSetId) {
                const foundApiPath = validateAPIPath(
                    activeModules.arr,
                    MODULE_TABLE.reasonWithMe.moduleName,
                    MODULE_TABLE.reasonWithMe.apiPaths.getReasoningId.path,
                    true
                )

                // NOTE: not all need to show a toast error.
                // only do this error toast method AFTER authentication.
                if (foundApiPath && newToken.value) {
                    const requestData: GetReasoningRequest['data'] = {
                        answerValue,
                        questionId: responseData.question?.questionId,
                        reasoningSetId: responseData.reasoningSetId,
                        reasoningSessionId: responseData.reasoningSessionId
                    }

                    // added by resume.

                    if (responseData.question?.questionType === 'resume') {
                        requestData.answerValue = true
                        requestData.reasoningSessionId = answerValue.reasoningId
                    }

                    getReasoning({
                        authToken: newToken.value,
                        data: requestData
                    }).unwrap()
                        .then((data) => {
                            /** if status is OK, set the data */
                            if (data.status === 'OK') {
                                /** adding new stuff now. Check if the stop_value is true.
                                 * if so, navigate them to a new page instead of this component.
                                 * This page will display results of answering the reasoning set.
                                 */

                                if (data.data.stop_value === true && currentWorkflowStep) {
                                    // don't forget to set the reasoning results.
                                    dispatch(setCurrentReasoningResults(data.data))

                                    // UPDATE: 1/6/2024, it's vague to update the workflow progress
                                    // if there are things to do in the results page.
                                    // just go there. update the workflow later.

                                    if (data.status === 'OK') {
                                        const foundRoute = validateRoute(
                                            activeModules.arr,
                                            MODULE_TABLE.reasonWithMe.moduleName,
                                            MODULE_TABLE.reasonWithMe.routes.results,
                                            true
                                        )

                                        if (foundRoute) {
                                            dispatch(push(foundRoute.route))
                                        }

                                        /** now reset the values */
                                        dispatch(setAnswerValue(undefined))
                                    } else {
                                        toast.error(
                                            data.message, { ...TOASTIFY_DEFAULT_OPTIONS }
                                        )
                                    }
                                } else {
                                    dispatch(setCurrentReasoningData(data.data))

                                    /** now reset the values */
                                    if (!(data.data.expectedType !== undefined &&
                                        data.data.retrievedType !== undefined)) {
                                        // if it's a single date that's next, change it
                                        // to new Date() instead.

                                        if (data.data.question?.questionType === 'date') {
                                            dispatch(setAnswerValue(getUnixTime(new Date())))
                                        } else if (data.data.question?.questionType === 'toggle') {
                                            dispatch(setAnswerValue(
                                                _.map(data.data.question.questionAnswers, (o) => {
                                                    // should return what their default value is.
                                                    return o.acqValue
                                                })
                                            ))
                                        } else {
                                            dispatch(setAnswerValue(undefined))
                                        }

                                        // and generate another key
                                        setInputKey(uuidv4())
                                    }
                                }
                            } else {
                                if (data.validateValue === false) {
                                    toast.error(data.data.message, { ...TOASTIFY_DEFAULT_OPTIONS })
                                }
                            }
                        }).catch((error) => {
                            if (error) {
                                const message = getErrorText(getReasoningMutation.error)
                                console.error(message)
                                toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
                            }
                        })
                } else {
                    if (strings.login?.message.error.api_path) {
                        toast.error(
                                `${ MODULE_TABLE.reasonWithMe.apiPaths
                                    .getReasoningId.path }: 
                                ${ strings.login?.message.error.api_path }`.trim(),
                                { ...TOASTIFY_DEFAULT_OPTIONS }
                        )
                    }
                }
            } else {
                toast.error(
                    strings.reason_with_me?.message.error.missing_parameters || '',
                    { ...TOASTIFY_DEFAULT_OPTIONS }
                )
            }
        }
    }

    useEffect(() => {
        if (reasonWithMeMutation.error) {
            const message = getErrorText(reasonWithMeMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [reasonWithMeMutation.error])

    useEffect(() => {
        // dispatch the questionInterface to store the answers so
        // they can go back to it. Replace the questionInterface
        // values once getReasoningID has invoked.
        const data = reasonWithMeMutation.data

        if (data?.data) {
            if (reasonWithMeMutation.data?.status === 'OK') {
                dispatch(setCurrentReasoningData(data.data))

                // go here if there are any values besides undefined you want to assign.

                if (data.data.question?.questionType === 'date') {
                    dispatch(setAnswerValue(getUnixTime(new Date())))
                } else if (data.data.question?.questionType === 'toggle') {
                    dispatch(setAnswerValue(
                        _.map(data.data.question.questionAnswers, (o) => {
                            // should return what their default value is.
                            return o.acqValue
                        })
                    ))
                } else {
                    dispatch(setAnswerValue(undefined))
                }
            } else if (data.status === 'NOT_OK') {
                // if the message accompanied with is a restriction message.
                // the set the workflow progress to -1.
                // oh and don't forget to display the error message.
                toast.error(data.message, { ...TOASTIFY_DEFAULT_OPTIONS })

                const call = async () => {
                    if (currentWorkflowStep) {
                        if (token.valid) {
                            const newToken = await revalidateToken({
                                value: token.value,
                                id: token.id
                            }, token.mode)
                            const percentComplete = -1
                            const isValid = validateAPIPath(
                                activeModules.arr,
                                MODULE_TABLE.reasonWithMe.moduleName,
                                MODULE_TABLE.reasonWithMe.apiPaths.updateWorkflowProgress.path,
                                true
                            )

                            if (isValid && newToken.value) {
                                updateWorkflowProgress({
                                    authToken: newToken.value,
                                    data: {
                                        stepId: currentWorkflowStep.stepId,
                                        workflowId,
                                        percentComplete
                                    }
                                })
                            }
                        }
                    }
                }
                call()
            }
        }

        // currentWorkflowStep despite being an important dependency
        // is causing the call to be executed twice.
        // ideally, you don't want to remove this dependency
        // as it is involved in this useEffect. Using a debounce in lodash did
        // not work at all. No choice but to remove the dependency
    }, [reasonWithMeMutation.data])

    const Question = useMemo(() => {
        /** how should the input be rendered */
        const data = questionInterface.currentReasonWithMeResponse.reasoningData
        let input = <div></div>

        switch (data?.question?.questionType) {
            case 'multi-tile': input = <MultiTile questionInterface={questionInterface} />
                break
            case 'multi-list': input = <MultiList questionInterface={questionInterface} />
                break
            case 'scale': input = <Scale questionInterface={questionInterface} />
                break
            case 'list': input = <List questionInterface={questionInterface} />
                break
            case 'int32':
                input = <NumberInput
                    questionInterface={questionInterface}
                    goToNextQuestion={goToNextQuestion}
                />
                break
            case 'bodymap': input = <Bodymap questionInterface={questionInterface} />
                break
            case 'bool': input = <Bool questionInterface={questionInterface} />
                break
            case 'toggle': input = <Toggle questionInterface={questionInterface} />
                break
            case 'open':
                input = <Open
                    questionInterface={questionInterface}
                    goToNextQuestion={goToNextQuestion}
                />
                break
            case 'float':
                input = <FloatInput
                    questionInterface={questionInterface}
                    goToNextQuestion={goToNextQuestion}
                />
                break
            case 'text': input = <Text questionInterface={questionInterface} />
                break
            case 'date': input = <SingleDate questionInterface={questionInterface} />
                break
            case 'open-list': input = <OpenList
                questionInterface={questionInterface}
                enableTruncation={enableTruncation}
                setEnableTruncation={setEnableTruncation}
            />
                break
            case 'multi-question': input = <OpenMultiQuestion
                questionInterface={questionInterface}
                enableValidation={enableValidation}
                divRef={divRef}
                enableTruncation={enableTruncation}
                setEnableTruncation={setEnableTruncation}
            />
                break
            case 'view': input = <View questionInterface={questionInterface} />
                break
            case 'resume': input = <Resume questionInterface={questionInterface}/>
                break
            default:
                break
        }

        const forMarkdownContent = _.isString(data?.question?.questionDescription)
            ? data?.question?.questionDescription
                .replace(/==([^=]+)==/g, '<mark>$1</mark>')
                .replace(/~(\d+)~/g, '<sub>$1</sub>')
                .replace(/~~([^~]+)~~/g, '<s>$1</s>')
            : ''

        // mobile header segment.
        const mobileHeaderSegment = <h3 className={data?.question?.questionType === 'text'
            ? 'fw-semibold mb-3 mt-4'
            : 'text-center fw-semibold mb-3 mt-4'}>
            {data?.question?.questionHeader}
        </h3>
        // desktop header segment
        const desktopHeaderSegment = <h1 className={data?.question?.questionType === 'text'
            ? 'mt-5 mb-3'
            : 'text-center mt-5 mb-3'}>
            {data?.question?.questionHeader}
        </h1>

        const content = <div>
            {/* question header */}
            {
                isMobile ? mobileHeaderSegment : desktopHeaderSegment
            }
            {
                data?.question?.questionType !== 'text'
                    ? <span className={'my-4 fw-light text-center'}>
                        <div className={'markdown'}>
                            <ReactMarkdown
                                components={{
                                    // supersub replaces markdown with del tags
                                    // for somereason.
                                    del: (props) => <sub {...props} />,
                                    ul: (props) => {
                                        const modifiedProps = { ...props }
                                        // eslint-disable-next-line react/prop-types
                                        modifiedProps.ordered = props.ordered.toString() as any

                                        if (modifiedProps.className && modifiedProps.className
                                            .includes('contains-task-list')) {
                                            return <ul
                                                {...modifiedProps}
                                                className={[
                                                    'contains-task-list list-unstyled ps-4'
                                                ].join(' ')}
                                            />
                                        } else {
                                            return <ul
                                                {...modifiedProps}

                                            />
                                        }
                                    }
                                }}
                                linkTarget={'_blank'}
                                remarkPlugins={[
                                    remarkBreaks,
                                    remarkGfm,
                                    supersub,
                                    remarkEmoji,
                                    remarkDefinitionList,
                                    remarkHeadingId
                                ]} rehypePlugins={[
                                    rehypeRaw
                                ]}
                            >
                                {`${ forMarkdownContent }`}
                            </ReactMarkdown>
                        </div>
                    </span>
                    : ''
            }

            {/* try to use the key prop so the component is recognized as a new one */}
            <div key={inputKey}>
                {input}
            </div>
        </div>

        return (
            content
        )
        // on second thought, not adding inputKey as a dependency.
    }, [strings, getReasoningMutation, questionInterface,
        token, inputKey, isMobile, enableTruncation, enableValidation])

    const debounceGoToNextQuestion = useDebouncedCallback(goToNextQuestion, 300)

    const NextButton = useMemo(() => {
        /** how should the input be rendered */
        const data = questionInterface.currentReasonWithMeResponse.reasoningData
        const answerValue = questionInterface.currentReasonWithMeResponse.answerValue

        /** push functions in this array wouldn't work on child components. */
        const nextButtonDisabled: boolean[] = [
            answerValue === null,
            answerValue === undefined
        ]

        switch (data?.question?.questionType) {
            case 'multi-tile':
                break
            case 'multi-list':
                break
            case 'scale': {
                nextButtonDisabled.push(answerValue === undefined)
                break
            }
            case 'list':
                break
            case 'int32': {
                const ranges = data.question?.questionAnswers[0]
                const lower = ranges.rangeLower || 0
                const upper = ranges.rangeUpper || 0

                nextButtonDisabled.push(
                    (data?.question?.questionType === 'int32' &&
                        _.inRange(answerValue, lower, upper + 1) === false)
                )
            } break
            case 'bodymap':

                if (_.isArray(answerValue)) {
                    if (data.question.questionAnswersAllowed === 0) {
                    // should be disabled.
                        if (answerValue?.length === data.question.questionAnswersAllowed) {
                            nextButtonDisabled.push(true)
                        }
                    }
                } else {
                    console.log('bodymap answer is not an array: ', answerValue)
                    // the button should be disabled.
                    nextButtonDisabled.push(true)
                }

                break
            case 'bool':
                break
            case 'toggle':
                // if questionAnswersAllowed === 1, all answers must be true.

                if (data.question.questionAnswersAllowed === 1) {
                    nextButtonDisabled.push(
                        _.some(answerValue, (o) => {
                            return o === false
                        })
                    )
                }
                break
            case 'open':
                if (answerValue !== undefined) {
                    if (typeof answerValue === 'string') {
                        nextButtonDisabled.push((answerValue.trim().length <= 0))
                    } else {
                        nextButtonDisabled.push(false)
                    }
                } else {
                    nextButtonDisabled.push(true)
                }
                break
            case 'float': {
                const ranges = data.question?.questionAnswers[0]
                const lower = ranges.rangeLower || 0
                const upper = ranges.rangeUpper || 0

                nextButtonDisabled.push(
                    (data?.question?.questionType === 'float' &&
                        _.inRange(answerValue, lower, upper + 1) === false)
                )
            } break
            case 'text':
                break
            case 'date':
                break
            case 'open-list':{
                let missingValue = false

                // console.log(answerValue)
                // always starts with answerValue.answers
                if (_.isArray(answerValue?.answers)) {
                    const answers = answerValue?.answers as OpenListPreviousQuestions[]

                    // catch how many rows have NOT been answered.
                    let catchAnsweredRows = 0

                    _.forEach(answers, (o) => {
                        // if there is at least one truthy userTypedAnswer, add to record.
                        const addToList = _.some(o.inputs, (p) => {
                            const userTypedAnswer = p.answerValue

                            if (typeof userTypedAnswer === 'string') {
                                // checks if the input is lengthy
                                return userTypedAnswer.length > 0
                            } else if (_.isArray(userTypedAnswer)) {
                                // checks if the input type list has more than one truthy record.
                                return _.some(
                                    userTypedAnswer, (q) => {
                                        return q.length > 0
                                    }
                                )
                            } else if (typeof userTypedAnswer === 'boolean') {
                                // this is included too because we'll have to treat it as
                                // not interacted with.
                                return userTypedAnswer === true
                            } else {
                                return false
                            }
                        })

                        if (addToList === true) {
                            catchAnsweredRows++
                        }
                    })

                    if (data.question.questionAnswersAllowed === 0) {
                        if (catchAnsweredRows === 0) {
                            missingValue = true
                        }
                    } else {
                        if (catchAnsweredRows !== data.question.questionAnswersAllowed) {
                            missingValue = true
                        }
                    }
                }

                nextButtonDisabled.push(missingValue)
                break
            }
            case 'multi-question':{
                let missingValue = false

                if (_.isArray(answerValue?.answers)) {
                    let arr = answerValue?.answers as OpenMultiQuestionOption[]
                    // if questionAnswersAllowed is 0, everything must be answered.
                    // console.log('answer all?: ', data.question.questionAnswersAllowed)
                    if (data.question.questionAnswersAllowed === 0) {
                        // check everything
                    } else {
                        // disabled button will be true
                        // if you didn't meet the number of clicked buttons.
                        // apply validation after
                        arr = _.filter(arr, (o) => {
                            return o.isClickedOnce === true
                        })
                    }

                    if (!arr.length) missingValue = true
                    else {
                        arr.forEach((item) => {
                            const nestedAnswers = item.answers

                            if (nestedAnswers.length === 0) {
                                missingValue = true
                            }

                            nestedAnswers.forEach(outer => {
                                outer.answer.forEach(inner => {
                                    if (inner?.actualValue === undefined) {
                                        missingValue = true
                                        // check if it's a string. then check for trailing spaces.
                                    } else if (
                                        typeof inner?.actualValue === 'string' &&
                                    inner?.actualValue.trim().length <= 0
                                    ) {
                                        missingValue = true
                                    } else if (_.isArray(inner?.actualValue)) {
                                        // new case for input-list. Go to every element and find
                                        // userTypedAnswer if it's truthy or not

                                        const openListCheck = inner
                                            ?.actualValue[0] &&
                                            _.has(inner?.actualValue[0], 'inputs')

                                        if (openListCheck) {
                                            const answerValues = inner.actualValue as OpenListValues[]
                                            // catch how many rows have NOT been answered.
                                            let catchAnsweredRows = 0

                                            _.forEach(answerValues, (o) => {
                                                // if there is at least one truthy userTypedAnswer, add to record.

                                                const addToList = _.some(o.inputs, (p) => {
                                                    // it's not p.answerValue here in multi-question -> userTypedAnswer
                                                    const userTypedAnswer = p.userTypedAnswer

                                                    if (typeof userTypedAnswer === 'string') {
                                                        // checks if the input is lengthy
                                                        return userTypedAnswer.length > 0
                                                    } else if (_.isArray(userTypedAnswer)) {
                                                        // checks if the input type list has more than one truthy record.
                                                        return _.some(
                                                            userTypedAnswer, (q) => {
                                                                return q.length > 0
                                                            }
                                                        )
                                                    } else if (typeof userTypedAnswer === 'boolean') {
                                                        // this is included too because we'll have to treat it as
                                                        // not interacted with.
                                                        return userTypedAnswer === true
                                                    } else {
                                                        return false
                                                    }
                                                })

                                                if (addToList === true) {
                                                    catchAnsweredRows++
                                                }
                                            })

                                            if (data.question?.questionAnswersAllowed === 0) {
                                                if (catchAnsweredRows === 0) {
                                                    missingValue = true
                                                }
                                            } else {
                                                if (catchAnsweredRows !== data.question?.questionAnswersAllowed) {
                                                    missingValue = true
                                                }
                                            }
                                        }
                                    }
                                })
                            })
                        })
                    }
                }

                nextButtonDisabled.push(missingValue)
                break
            }
            default:
                break
        }

        const onClickEvent = () => {
            console.log('next button clicked: ', nextButtonDisabled, data.question?.questionType)
            // now make the next call.
            // bullet 10 item in IHD-332. for multi-questions, make sure that you validate
            // all inputs in there. don't forget to highlight the buttons that are invalid
            if (_.find(nextButtonDisabled, (o) => o === true)) {
                // do validation for question type.
                if (data?.question?.questionType === 'multi-question') {
                    console.log('set validation to multi-question')
                    setEnableValidation(true)
                    scrollToTop()
                }
            } else {
                // if you are on open-list, you'll have to do something else.
                if (
                    data.question?.questionType === 'open-list'
                ) {
                    // set a flag to open-list to truncate the values.
                    setEnableTruncation(true)
                    // now make the call below but debounced
                    // goToNextQuestion(token)
                    debounceGoToNextQuestion(token)
                } else if (
                    data.question?.questionType === 'multi-question'
                ) {
                    setEnableTruncation(true)
                    debounceGoToNextQuestion(token)
                } else {
                    goToNextQuestion(token)
                }
            }
        }

        // disable next button with more conditions.

        const buttonName = data.question?.questionType === 'view'
            ? data.question.questionAnswers[0]?.acqName
            : (
                data.question?.nextButtonValue ||
                            strings.reason_with_me?.text.get_reasoning_id.submit_button || ''
            )

        const buttonContent = getReasoningMutation.isLoading
            ? (
                <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            <span className={'spinner-border spinner-border-sm'}></span>
                            <span className={'ms-2'}>
                                {strings.app?.text?.submitting || ''}
                            </span>
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>
            )
            : updateWorkflowProgressMutation.isLoading
                ? (
                    <div className={'container'}>
                        <div className={'row justify-content-between align-items-center'}>
                            <div className={'col text-center'}>
                                <span className={'spinner-border spinner-border-sm'}></span>
                                <span className={'ms-2'}>
                                    {strings.app?.text?.updating || ''}
                                </span>
                            </div>
                            <div className={'col-auto'}>
                                <i className={'fa-regular fa-arrow-right float-end'}
                                    aria-hidden={'true'} ></i>
                            </div>
                        </div>
                    </div>
                )
                : <div className={'container'}>
                    <div className={'row justify-content-between align-items-center'}>
                        <div className={'col text-center'}>
                            {
                                buttonName
                            }
                        </div>
                        <div className={'col-auto'}>
                            <i className={'fa-regular fa-arrow-right float-end'}
                                aria-hidden={'true'} ></i>
                        </div>
                    </div>
                </div>

        const buttonClassName = `btn btn-primary btn-lg submit-button
            ${ _.find(nextButtonDisabled, (o) => o === true) ||
            getReasoningMutation.isLoading
            //
            ? 'disabled'
            : '' }`.trim()

        return <div onClick={onClickEvent}>
            <a
                className={buttonClassName}
            >
                {buttonContent}

            </a>
        </div>
    }, [strings, activeModules.arr, getReasoningMutation,
        questionInterface, token, currentWorkflowStep])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'header py-3 text-center'}>
        {/* image of logo goes here. fixed width but height can change whatever */}
        <img src={'/images_new/header/logo.svg'} />
    </div>

    const desktopResult = <>
        <FixedImage
            imageUrl={'/images_new/header/logo.svg'} position={'bottom-middle'}
        />
        <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
        <div className={'position-absolute w-100 main-content'}>
            <div className={'container card shadow border-0 mb-20'}>

                <div className={'row form-container'}>
                    <div className={'col-12 col-md-8 col-lg-7 mx-auto'}>
                        {/* content goes here */}
                        {
                            reasonWithMeMutation.isLoading && !reasonWithMeMutation.data
                                ? <small className={'d-block text-center my-2'}>
                                    <div className={'spinner-container'}>
                                        <span
                                            className={'spinner-border spinner-border-sm'}
                                        ></span>
                                        <span className={'ms-2 mt-5'}>{
                                            strings.reason_with_me?.message
                                                .loading.question_interface || ''
                                        }</span>
                                    </div>
                                </small>
                                : ''
                        }
                        {/* show helpmeout error message IF it crashes */}
                        {JSON.stringify(reasonWithMeMutation.error) && <small
                            className={'d-block text-center py-2'}>
                            {JSON.stringify(reasonWithMeMutation.error)}
                        </small>}
                        {/* the cause of subsequent inputs of the
                             same type not being a fresh render. */}
                        {Question}
                    </div>
                </div>
                <div className={'row align-items-center justify-content-center mb-5'}>
                    <div className={'col text-center'}>
                        {NextButton}
                    </div>
                </div>

            </div>
        </div>

        <div className={'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'}>
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = useMemo(() => {
        const data = questionInterface.currentReasonWithMeResponse.reasoningData

        const containerClass = data.question?.questionType === 'text'
            ? 'container-fluid main-content px-12'
            : data.question?.questionType === 'multi-question'
                ? 'container-fluid main-content px-2'
                : 'container-fluid main-content px-6'

        return <>
            {mobileHeader}
            <HeaderImage url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} />
            <div className={containerClass}>
                <div className={'form-container'}>
                    <div >
                        <div className={'row'}>
                            <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                                {/* content goes here */}
                                {
                                    reasonWithMeMutation.isLoading && !reasonWithMeMutation.data
                                        ? <small className={'d-block text-center my-2'}>
                                            <div className={'spinner-container'}>
                                                <span
                                                    className={'spinner-border spinner-border-sm'}
                                                ></span>
                                                <span className={'ms-2 mt-5'}>{
                                                    strings.reason_with_me?.message
                                                        .loading.question_interface || ''
                                                }</span>
                                            </div>
                                        </small>
                                        : ''
                                }
                                {/* show helpmeout error message IF it crashes */}
                                {JSON.stringify(reasonWithMeMutation.error) && <small
                                    className={'d-block text-center py-2'}>
                                    {JSON.stringify(reasonWithMeMutation.error)}
                                </small>}
                                {/* the cause of subsequent inputs of the
                             same type not being a fresh render. */}
                                {Question}
                            </div>
                        </div>
                    </div>
                    <div >
                        <div className={'row align-items-center justify-content-center mb-5'}>
                            <div className={'col-12 col-sm-8 col-md-8 col-lg-5 text-center '}>
                                {NextButton}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }, undefined)

    return (<div className={'reason-with-me-page'}>

        <UpdateWorkflowProgressConsumer
            data={updateWorkflowProgressMutation.data}
            originalArgs={updateWorkflowProgressMutation.originalArgs}
        />

        {
            isMobile ? mobileResult : desktopResult
        }

    </div>)
}

export default QuestionInterface
