/* eslint-disable max-len */
/* eslint-disable indent */
import { MOBILE_RESPONSIVE_LIMIT, SIDEBAR_WIDTH } from '@app/app.constants'
import { css, useTheme } from '@emotion/react'
const GlobalStyle = () => {
    const theme = useTheme()

    return css`
    .facility-header {
        height: 60px;
        width: 100%;
        background: ${ (() => {
            const gradientColors = theme.config.admin?.fmt
            .facilityHeader.backgroundColor
            if (gradientColors && gradientColors.length >= 3) {
                return `linear-gradient(180deg, ${ gradientColors[0] } 9.04%, ${ gradientColors[1] } 65.47%, ${ gradientColors[2] } 91.12%)`
            } else {
                return 'transparent' // fallback if gradientColors is not properly defined
            }
        })() };
        @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
            height: auto;
            position: fixed;
            top: 0;
            z-index: 2;
        }

        .icon-container {
            cursor: pointer;
            font-size: 1em;
            color: ${ theme.config.admin?.fmt.facilityHeader.iconContainerColor };
            background-color: transparent;
            border: 2px solid ${ theme.config.admin?.fmt.facilityHeader.iconContainerBorderColor };
            border-radius: 16px;
            padding: 10.25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .search-box {
            width: 400px;

            &:focus {
                border-color: ${ theme.config.admin?.fmt.facilityHeader.searchBoxFocusBorderColor };
                outline: 0;
                box-shadow: 0 0 0 0.25rem ${ theme.config.admin?.fmt.facilityHeader.searchBoxFocusBoxShadowColor };
            }

            display: flex;
            align-items: center;
            background-color: ${ theme.config.admin?.fmt.facilityHeader.searchBoxBackgroundColor };
            border-radius: 16px;
            padding: 10px 20px;
            height: 40px;

            input {
                font-weight: 300;
                padding: 0;
                background: inherit;
                color: ${ theme.config.admin?.fmt.facilityHeader.searchBoxInputColor };

                &:focus {
                    outline: none;
                    box-shadow: none;
                    border: 0;
                }
            }

            @media (max-width: 767px) {
                input {
                    width: 100%;
                }
            }

            i {
                color: ${ theme.config.admin?.fmt.facilityHeader.searchBoxIconColor };
                margin-right: 10px;
            }
        }
    }

    .form-select {
        display: block;
        width: 100%;
        padding: 0.9375rem 5.625rem 0.9375rem 1.875rem;
        -moz-padding-start: calc(1.875rem - 3px);
        font-weight: 300;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.3125;
        color: ${ theme.config.admin?.fmt.formSelect.color };
        background-color: ${ theme.config.admin?.fmt.formSelect.background };
        background-image: ${ theme.config.admin?.fmt.formSelect.backgroundImage };
        background-repeat: no-repeat;
        background-position: right 1.875rem center;
        background-size: 16px 12px;
        border: 0.125rem solid ${ theme.config.admin?.fmt.formSelect.border };
        border-radius: 1.125rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    @media (prefers-reduced-motion: reduce) {
        .form-select {
            transition: none;
        }
    }

    .form-select:focus {
        border-color: ${ theme.config.admin?.fmt.formSelect.focus.border };
        outline: 0;
        box-shadow: 0 0 0 0.25rem ${ theme.config.admin?.fmt.formSelect.focus.boxShadow };
    }

    .form-select[multiple],
    .form-select[size]:not([size="1"]) {
        padding-right: 1.875rem;
        background-image: none;
    }

    .form-select:disabled {
        background-color: ${ theme.config.admin?.fmt.formSelect.disabled.background };
    }

    .form-select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 ${ theme.config.admin?.fmt.formSelect.mozFocusring.textShadow };
    }


    .form-select-sm {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        padding-left: 1.875rem;
        font-size: 1rem;
        border-radius: 1.125rem;
    }

    .form-select-lg {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        padding-left: 1.875rem;
        font-size: 1rem;
        border-radius: 1.125rem;
    }
    //needs to be global for modals to be used here.
    .chart-container {
        position: relative;
        width: 100%;
        /** disable maintainAspectRatio on chartjs if this is the case. */
        height: 250px;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: ${ theme.config.admin?.fmt.chartContainer.overlayBackgroundColor };
            opacity: 0.8;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .floating-ui {
        border: 0.1em solid transparent;
        padding-block: 0.2rem;
        padding-inline: 0.5em;
        z-index: 1;
        //focus visible shows on divs and that gets annoying.
        &:focus-visible {
            outline: 0;
        }

        &.answer-overview {
            background-color: ${ theme.config.admin?.fmt.answerSummary.floatingUiProgress.background };
            box-shadow: 0px 2px 10px 0px ${ theme.config.admin?.fmt.answerSummary.floatingUiProgress.shadowColor };
            > svg {
                fill: ${ theme.config.admin?.fmt.answerSummary.floatingUiProgress.background };
            }
            color: ${ theme.config.admin?.fmt.answerSummary.floatingUiProgress.textColor };
            border-radius: 18px;

            .popup-button {
                min-width: 170px;
            }
        }

        &.information {
            background-color: ${ theme.config.admin?.fmt.answerSummary.informationFloatingUi.background };
            box-shadow: 0px 2px 10px 0px ${ theme.config.admin?.fmt.answerSummary.informationFloatingUi.shadowColor };
            > svg {
                fill: ${ theme.config.admin?.fmt.answerSummary.informationFloatingUi.background };
            }
            color: ${ theme.config.admin?.fmt.answerSummary.informationFloatingUi.textColor };
            border-radius: 0.3em;
        }

        &.bodymap {
            z-index: 10;
            background-color: ${ theme.config.admin?.fmt.answerSummary.bodymapFloatingUi.background };
            > svg {
                fill: ${ theme.config.admin?.fmt.answerSummary.bodymapFloatingUi.background };
            }
            color: ${ theme.config.admin?.fmt.answerSummary.bodymapFloatingUi.textColor };
            border-radius: 0.3em;
        }
    }


    .patient-page {
        .patient-body {

            max-width: 1366px;


            & .navigation-content {
                box-shadow: 0px -23px 18px -26px ${ theme.config.admin?.fmt.patientPage.navigation.contentBoxShadow };
                position: relative;
                //i wonder why this was z-Index -1.
                /* z-index: -1; */
            }

            & .navigation-container {
                display: flex;
                height: 38px;
                border-radius: 18px 18px 0 0;
                padding-right: 4rem;
                padding-left: 4rem;

                & .navigation-tab {
                    width: 180px;
                    height: 38px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    cursor: pointer;
                    border-bottom: 2px solid transparent;
                    font-size: 14px;
                    box-shadow: ${ theme.config.admin?.fmt.patientPage.navigation.tab.shadowColor } 0px -17px 15px -16px;
                    position: relative;
                    z-index: 1;
                    background: ${ theme.config.admin?.fmt.patientPage.navigation.tab.background };
                    border-radius: 18px 18px 0px 0px;
                    margin-inline: 0.5em;

                    &.active-tab {
                        background: ${ theme.config.admin?.fmt.patientPage.navigation.tab.activeBackground };
                        font-weight: 600;
                    }
                }


            }

            .cells {

                display: flex;
                div {
                    border-width: 0.5px;
                    border-style: solid;
                    border-color: ${ theme.config.admin?.fmt.patientPage.cells.defaultBorderColor };
                    padding: 2px 9px; /* top right bottom left */
                    border-radius: 18px;
                    margin: 5px; /* Adjust spacing between cells as needed */

                    /* green, yellow, orange, red and grey */
                    &.green {
                        border-color: ${ theme.config.admin?.fmt.patientPage.cells.green.borderColor };
                        color: ${ theme.config.admin?.fmt.patientPage.cells.green.color };
                    }

                    &.yellow {
                        border-color: ${ theme.config.admin?.fmt.patientPage.cells.yellow.borderColor };
                        color: ${ theme.config.admin?.fmt.patientPage.cells.yellow.color };
                    }

                    &.orange {
                        border-color: ${ theme.config.admin?.fmt.patientPage.cells.orange.borderColor };
                        color: ${ theme.config.admin?.fmt.patientPage.cells.orange.color };
                    }

                    &.red {
                        border-color: ${ theme.config.admin?.fmt.patientPage.cells.red.borderColor };
                        color: ${ theme.config.admin?.fmt.patientPage.cells.red.color };
                    }

                    &.grey {
                        border-color: ${ theme.config.admin?.fmt.patientPage.cells.grey.borderColor };
                        color: ${ theme.config.admin?.fmt.patientPage.cells.grey.color };
                    }
                }
            }

            .risk-border{
                border-left-width: 3px;
                border-left-style: solid;
                border-color: transparent;
                border-color: ${ theme.config.admin?.fmt.patientPage.cells.defaultBorderColor };

                /* green, yellow, orange, red and grey */
                &.green {
                    border-color: ${ theme.config.admin?.fmt.patientPage.cells.green.borderColor };
                    color: ${ theme.config.admin?.fmt.patientPage.cells.green.color };
                }

                &.yellow {
                    border-color: ${ theme.config.admin?.fmt.patientPage.cells.yellow.borderColor };
                    color: ${ theme.config.admin?.fmt.patientPage.cells.yellow.color };
                }

                &.orange {
                    border-color: ${ theme.config.admin?.fmt.patientPage.cells.orange.borderColor };
                    color: ${ theme.config.admin?.fmt.patientPage.cells.orange.color };
                }

                &.red {
                    border-color: ${ theme.config.admin?.fmt.patientPage.cells.red.borderColor };
                    color: ${ theme.config.admin?.fmt.patientPage.cells.red.color };
                }

                &.grey {
                    border-color: ${ theme.config.admin?.fmt.patientPage.cells.grey.borderColor };
                    color: ${ theme.config.admin?.fmt.patientPage.cells.grey.color };
                }
            }

            & .progress {
                height: 8px;
                width: 6em;
            }

            & .progress-bar {
                background-color: ${ theme.config.admin?.fmt.patientPage.progressBar.backgroundColor };
            }


            .risk-container {

                padding: 5px 9px 5px 9px;
                font-size: 15px;
                border-radius: 5px;

                display: flex;
                align-items: center;

                border-width: 1px;
                border-style: solid;
                border-color: ${ theme.config.admin?.fmt.patientPage.cells.defaultBorderColor };

                &.green {
                    border-color: ${ theme.config.admin?.fmt.patientPage.cells.green.borderColor };
                    color: ${ theme.config.admin?.fmt.patientPage.cells.green.color };
                }

                &.yellow {
                    border-color: ${ theme.config.admin?.fmt.patientPage.cells.yellow.borderColor };
                    color: ${ theme.config.admin?.fmt.patientPage.cells.yellow.color };
                }

                &.orange {
                    border-color: ${ theme.config.admin?.fmt.patientPage.cells.orange.borderColor };
                    color: ${ theme.config.admin?.fmt.patientPage.cells.orange.color };
                }

                &.red {
                    border-color: ${ theme.config.admin?.fmt.patientPage.cells.red.borderColor };
                    color: ${ theme.config.admin?.fmt.patientPage.cells.red.color };
                }

                &.grey {
                    border-color: ${ theme.config.admin?.fmt.patientPage.cells.grey.borderColor };
                    color: ${ theme.config.admin?.fmt.patientPage.cells.grey.color };
                }

                .fraction {
                    font-size: 1em; /* Adjust font size as needed */
                }

                .fraction span {

                    &.numerator {
                        font-weight: 600;
                        padding-inline-end: 3px;
                    }
                    
                    &.denominator {

                    }
                }
            }

            .advice-container {
                border-radius: 10px;
                border: 1px solid ${ theme.config.admin?.fmt.patientPage.adviceContainer.borderColor };
                display: flex;
                padding: 8px;


                & i {
                    font-size: 24px;
                }

                .title {
                    font-weight: 600;
                }

                .subtitle {
                    font-weight: 300;

                }
            }

            .custom-icon-rounded {
                display: inline-block;
                position: relative;
            }

            .custom-icon i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .custom-icon .fa-circle {
                font-size: 20px; /* Adjust size as needed */
                color: ${ theme.config.admin?.fmt.patientPage.customIcon.circleColor }; /* Change color if needed */
            }

            .custom-icon .fa-minus {
                font-size: 10px; /* Adjust size as needed */
                color: ${ theme.config.admin?.fmt.patientPage.customIcon.minusColor }; /* Change color if needed */
            }


            .task-status-checker {
                
                margin-block-end: 0em;

                overflow-x: scroll;


                .task-cell-container {

                    border-bottom-width: 0px;

                    &:first-of-type {
                        .task-header {
                            padding-inline-start: 2vw;
                        }
                        .task-body {
                            padding-inline-start: 2vw;
                        }
                    }


                    &:last-child{
                        .task-header{
                            padding-inline-end: 2vw;
                        }
                        .task-body{
                            padding-inline-end: 2vw;
                        }
                    }

                        
                    .task-header{
                        font-weight: 200;
                        font-size: 1em;
                        line-height: 21px;
                        letter-spacing: 0em;
                        text-align: center;
                        white-space: nowrap;
                        margin-block-end: 1rem;
                        padding-block-end: 0.5rem;
                        padding-inline: 1rem;

                        &.current {
                            font-weight: normal;
                        }

                        /** important that you put the background color */
                        background: ${ theme.config.admin?.fmt.patientPage.taskCellContainer.taskHeader.background }; /* Change color if needed */
                    }


                    .task-body {
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        padding-inline-start: 0.4rem;
                        padding-inline-end: 0.4rem;
                    }

                    background: ${ (() => {
                        const str = [
                            'linear-gradient(',
                                `180deg, ${ theme.config.admin?.fmt.patientPage.taskCellContainer.background[0] } 0%,`,
                                `${ theme.config.admin?.fmt.patientPage.taskCellContainer.background[1] } 100%`,
                            ')'
                        ].join(' ')

                        return str
                    })() };
                                        
                    &.current {
                        background: ${ (() => {
                            const str = [
                                'linear-gradient(',
                                    `0deg, ${ theme.config.admin?.fmt.patientPage.taskCellContainer.current.background[0] } 0%,`,
                                    `${ theme.config.admin?.fmt.patientPage.taskCellContainer.current.background[1] } 34.23%,`,
                                    `${ theme.config.admin?.fmt.patientPage.taskCellContainer.current.background[2] } 65.48%,`,
                                    `${ theme.config.admin?.fmt.patientPage.taskCellContainer.current.background[3] } 100%`,
                                ')'
                            ].join(' ')

                            return str
                        })() };

                    }

                    .task-cell{
                        background: ${ theme.config.admin?.fmt.patientPage.taskCellContainer.taskCell.background };
                        box-shadow: 0px 5px 15px 0px ${ theme.config.admin?.fmt.patientPage.taskCellContainer.taskCell.boxShadow };
                        /* fixed values */
                        width: 120px;
                        height: 60px;
                        padding: 0.25rem;
                        border-radius: 6px;

                        .icon {
                            background: ${ theme.config.admin?.fmt.patientPage.taskCellContainer.taskCell.icon.background };;
                            display: inline-block;
                            /* needs to be fixed */
                            width: 26px;
                            height: 26px;
                            border-radius: 4.29px;
                            color: ${ theme.config.admin?.fmt.patientPage.taskCellContainer.taskCell.icon.color };
                            text-align: center;

                            & i {
                                vertical-align: middle;
                            }

                            &.complete {
                                background: ${ theme.config.admin?.fmt.patientPage.taskCellContainer.taskCell.icon.complete.background };
                            }
                            &.locked {
                                background: ${ theme.config.admin?.fmt.patientPage.taskCellContainer.taskCell.icon.locked.background };
                                color: ${ theme.config.admin?.fmt.patientPage.taskCellContainer.taskCell.icon.locked.color };
                            }
                            &.incomplete {
                                background: ${ theme.config.admin?.fmt.patientPage.taskCellContainer.taskCell.icon.incomplete.background };
                            }

                        }

                        .title{
                            display: block;
                            font-size: 0.79em;
                        }

                        .subtitle{
                            display: block;
                            font-weight: 200;
                            font-size: 0.7em;
                        }

                        & .progress {
                            height: 0.3rem;
                            --bs-progress-bar-bg: var(--card-progress-bg);
                        }

                        & .progress-bar {
                            background-color: ${ theme.config.admin?.fmt.patientPage.progressBar.backgroundColor };
                        }
                    }
                }


            }
        }
    }
        
    .facility-page, .department-page, .careprofessional-page, .treatment-page, .carepath-page, .patient-page {

        height: 100%;
        position: absolute;

        @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
            width: 100%;
        }

        @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
            width: calc(100% - ${ [SIDEBAR_WIDTH, 'px'].join('') });
        }

        /** why? because div id=root doesn't have a height property assigned.
        doing 100vh will not work as you are assigning it the current screen size.
        even so, you need to assign position:absolute to avoid relying on a parent element
         */

        & .main-content {

            width: 100%;

            //then apply a min-height so you can scroll down with the height increasing as more 
            //content grows.
            min-height: calc(100% - 60px);

            color:  ${ theme.config.admin?.fmt.fmtPages.mainContent.color };
            background: ${ (() => {
                const str = [
                    'linear-gradient(',
                        `153.58deg, ${ theme.config.admin?.fmt.fmtPages.mainContent.background.start } 0%,`,
                        `${ theme.config.admin?.fmt.fmtPages.mainContent.background.end } 100%`,
                    ')'
                ].join(' ')

                return str
            })() };
        }

        .facility-body, .department-body, .careprofessional-body, .treatment-body, .carepath-body, .patient-body {

            &.list-interface {
                //idk why i had to remove it. probably because of flex-column
                /* height: 70vh; */
            }

            .add-interface, .edit-interface, .picture-interface, .course-interface, .diagnosis-interface {
                /* overflow-y: auto; */
                border-radius: 18px;
                border: 2px solid ${ theme.config.admin?.fmt.fmtPages.body.interface.borderColor };
                padding: 2em;
                background: ${ theme.config.admin?.fmt.fmtPages.body.interface.background };

                            
                //taken from ih-app-my-profile
                .img-wrapper {
                    position: relative;
                    width: 220px;

                    .rounded-square {
                        border-radius: 18px;
                    }

                    .img-placeholder, .profile {
                        background: ${ theme.config.admin?.fmt.profileImgPlaceholder.background };;
                        width: 220px;
                        height: 220px;
                    }
                }

                .img-wrapper .btn {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }

                //IHD-363
                .react-datepicker-popper {
                    z-index: 10;
                }
            }

            .record-menu {
                /* CSS Trick Explanation:
                Imagine a suitcase that starts with no height (height: 0px).
                As you add clothes (content) to the suitcase, it magically expands (flex: 1 1 auto)
                to fit them, but it won't exceed a certain height (container's height).
                When you try to put too much, you need to close the suitcase (scrolling).
                */
                /* height: 0px; */
                //UPDATE: 9/15/2023: As sgguested by Tim
                /* height: auto;
                flex: 1 1 auto; */

                //REMOVED flex column to regular.
                // max height will be calculated in react.
                /* max-height: 600px; */

                /* overflow-y: auto; */
                border-radius: 16px;
                border: 2px solid ${ theme.config.admin?.fmt.fmtPages.body.recordMenu.borderColor };
                padding: 0;

                overflow-x: auto;

                table {
                    border-collapse: collapse; /* Merge table borders */
                    width: 100%; /* Make the table full-width */

                    input[type="checkbox"]:not([role="switch"]) {
                        width: 20px;
                        height: 20px;
                        border-radius: 3px;
                        border: 2px solid ${ theme.config.admin?.fmt.fmtPages.body.recordMenu.table.input.checkbox.borderColor };

                        &:focus-visible {
                            outline: 0;
                        }
                    }
                }

                tr {
                    cursor: pointer;

                    &.accordion{
                        cursor: default;

                        & .button-container {
                            & button {
                                font-weight: 600;
                            }
                        }
                    }
                }

                th, td {
                    text-align: left;
                    padding: 8px; /* Add padding to table cells */
                }

                th {
                    font-weight: 600;
                    white-space: nowrap;
                    padding-block: 14px;
                    padding-inline: 14px;
                    background-color: ${ theme.config.admin?.fmt.fmtPages.body.recordMenu.th.background };; /* Background color for table header */
                    border-bottom: 1px solid ${ theme.config.admin?.fmt.fmtPages.body.recordMenu.th.borderBottomColor };
                }

                td {
                    white-space: nowrap;
                    padding-block: 14px;
                    padding-inline: 14px;
                    font-weight: 300;
                    border-bottom: 1px solid ${ theme.config.admin?.fmt.fmtPages.body.recordMenu.td.borderBottomColor };
                }

                /* Style even rows with a white background */
                tbody tr:nth-of-type(4n-1), /* Selects row 1, 5, 9, 13, ... */
                tbody tr:nth-of-type(4n) { /* Selects row 2, 6, 10, 14, ... */
                    background-color: ${ theme.config.admin?.fmt.fmtPages.body.recordMenu.tr.even.background };
                }

                tbody tr:nth-of-type(4n-3), /* Selects row 3, 7, 11, 15, ... */
                tbody tr:nth-of-type(4n-2) { /* Selects row 4, 8, 12, 16, ... */
                    background-color: ${ theme.config.admin?.fmt.fmtPages.body.recordMenu.tr.odd.background };
                }

            }

        }

    }

    //found for facility's previous button
    //if the btn has a btn-dark, btn-outline-white, and btn-secondary  change it to navy blue.
    & .btn.btn-dark.btn-outline-white {

        border-radius: 24px;
        border-width: 1px;

        --bs-btn-color: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.color };
        --bs-btn-bg: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.bg };
        --bs-btn-border-color: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.borderColor };
        --bs-btn-hover-color: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.hoverColor };
        --bs-btn-hover-bg: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.hoverBg };
        --bs-btn-hover-border-color: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.hoverBorderColor };
        --bs-btn-focus-shadow-rgb: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.focusShadowRgb };
        --bs-btn-active-color: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.activeColor };
        --bs-btn-active-bg: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.activeBg };
        --bs-btn-active-border-color: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.activeBorderColor };
        --bs-btn-active-shadow: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.activeShadow };
        --bs-btn-disabled-color: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.disabledColor };
        --bs-btn-disabled-bg: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.disabledBg };
        --bs-btn-disabled-border-color: ${ theme.config.admin?.fmt.btnDark.btnOutlineWhite.disabledBorderColor };

    }

    /* Pagination container */
    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        font-weight: 300;
    }

    /* Previous and next buttons */
    .previous,
    .next {
        text-decoration: none;
        font-size: 18px;
        color: ${ theme.config.admin?.fmt.directionButtons.color };
        margin: 0 10px;

        &.disabled {
            color: ${ theme.config.admin?.fmt.directionButtons.disabled.color };
            pointer-events: none;
        }
    }

    /* Page numbers */
    .page-numbers a {
        text-decoration: none;
        background: ${ theme.config.admin?.fmt.pageNumbers.background };
        font-size: 16px;
        color: ${ theme.config.admin?.fmt.pageNumbers.color };
        padding: 11px 16px;
        margin: 0 5px;
        border-radius: 10px;
        transition: background-color 0.2s;
    }

    /* Active page */
    .page-numbers a.active {
        background: ${ theme.config.admin?.fmt.pageNumbers.active.background };
        color: ${ theme.config.admin?.fmt.pageNumbers.active.color };

        font-weight: 600;
    }

    /* Ellipsis */
    .ellipsis {
        margin: 0 5px;
    }

    /* Hover effect for page numbers */
    .page-numbers a:hover {
        background-color: ${ theme.config.admin?.fmt.pageNumbers.hover.background };
    }

    /* Style for step circles */
    .step-indicator {
        width: 40px;
        height: 40px;
        background: transparent;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
        font-weight: 600;
        display: inline-block;
        margin-right: 10px; 
        border: 1px solid ${ theme.config.admin?.fmt.stepIndicator.border };

        &.active, &.finished {
            background: ${ (() => {
                const str = [
                    'linear-gradient(',
                        `180deg, ${ theme.config.admin?.fmt.stepIndicator.active.background[1] } 9.04%,`,
                        `${ theme.config.admin?.fmt.stepIndicator.active.background[2] } 65.47%,`,
                        `${ theme.config.admin?.fmt.stepIndicator.active.background[3] } 91.12%`,
                    ')'
                ].join(' ')

                return str
            })() };
            color: ${ theme.config.admin?.fmt.stepIndicator.active.color };
        }

    }

    .step-active{
        font-weight: 300;
        font-size: 0.87em;
        &.current {
            font-weight: 600;
        }
    }

    /* Style for the thin rounded rectangle */
    .step-divider {
        vertical-align: middle;
        /* width: 30px; */
        width: 100%;
        height: 3px;
        background: ${ theme.config.admin?.fmt.stepDivider.background };
        display: inline-block;
        border-radius: 2px;
    }

    .review-tag {
        background: ${ theme.config.admin?.fmt.reviewTag.background };
    }

    //modified style changes to all form inside a html.page-dashboard
    .form-label, .form-text {
        font-weight: 600;
        font-size: 0.8em;
    }

    .form-control {
        font-weight: 300;
    }

    .form-select {
        font-weight: 300;
    }

    .dropdown-list{

        border: 2px solid ${ theme.config.admin?.fmt.dropdownList.borderColor };
        border-radius: 18px;
        z-index: 2;
        background: ${ theme.config.admin?.fmt.dropdownList.backgroundColor };

        .option {
            margin-block-end: 1em;
            padding-block: 0.75em;
            padding-inline: 2.5em;
            border-radius: 18px;
            box-shadow: ${ theme.config.admin?.fmt.dropdownList.option.boxShadow };
            cursor: default;

            &.selected {
                background: ${ theme.config.admin?.fmt.dropdownList.option.selected.background };
            }
        }

        .search-box {
            background-color: ${ theme.config.admin?.fmt.dropdownList.searchBox.backgroundColor };
        }

    }

    .inner-department-interface {
        border: 2px solid ${ theme.config.admin?.fmt.innerDepartmentInteface.borderColor };
        border-radius: 10px;
        background: linear-gradient(0deg, ${ theme.config.admin?.fmt.innerDepartmentInteface.background[1] }, ${ theme.config.admin?.fmt.innerDepartmentInteface.background[2] }),
                    linear-gradient(0deg, ${ theme.config.admin?.fmt.innerDepartmentInteface.background[3] }, ${ theme.config.admin?.fmt.innerDepartmentInteface.background[4] });
        //if there is a .dropdown-list class inside this, increase the z-index by 2.
        .dropdown-list {
            z-index: 2;
        }

    }

    .modal {
        &.add-facility-form, &.add-patient-form {

            color:  ${ theme.config.admin?.fmt.modal.addForm.color };

            .modal-footer {
                border-width: 0;
                padding: 0;
                .footer.wave-background {
                    padding: 0px;
                    border-radius: 0px 0px 10px 10px;

                    & .btn {
                        /* width: 200px;
                        height: 40px; */
                    }
                }
                background: ${ theme.config.admin?.fmt.modal.addForm.footer.background };


            }

            /** fixed height for the modal content */
            .modal-content {
                /* max-height: 850px; */
                border-radius: 18px;
            }

            //has to be fixed height for consistency in other steps.
            .modal-body {
                /* height: 650px; */
                /* overflow-y: scroll; */
                /* min-height: 650px; */
                background: ${ theme.config.admin?.fmt.modal.addForm.body.background };
            }

            .modal-header {
                background: ${ theme.config.admin?.fmt.modal.addForm.header.background };
                border-width: 0;

            }

            *::-webkit-scrollbar-track {
                background: ${ theme.config.admin?.fmt.modal.addForm.webkitScrollbar.track.background };

            }
            *::-webkit-scrollbar-thumb {
                background-color: ${ theme.config.admin?.fmt.modal.addForm.webkitScrollbar.thumb.background };
            }
        }
    }

    .rounded-square {

        &.yellow {
            background: ${ theme.config.admin?.fmt.roundedSquare.yellow };
            color: ${ theme.config.admin?.fmt.roundedSquare.dark };
        }

        &.dark {
            background: ${ theme.config.admin?.fmt.roundedSquare.dark };
            color: ${ theme.config.admin?.fmt.roundedSquare.white };
        }

        width: 50px;
        height: 50px;
        border-radius: 18px;
        transform: rotate(0deg);
        cursor: pointer; /* Optional: Add cursor style for interactivity */
    }

    .search-box {

        &:focus {
        border-color: ${ theme.config.admin
        ?.fmt.searchBox.focus.borderColor };
            outline: 0;
        box-shadow: 0 0 0 0.25rem ${ theme.config.admin
        ?.fmt.searchBox.focus.boxShadow };
        }

        width: 100%;
        display: flex;
        align-items: center;
        background-color: ${ theme.config.admin?.fmt.searchBox.background };
        border-radius: 16px;
        padding: 25px 20px;
        height: 40px;

        input {
            padding: 0;
            background: inherit;
            border: 0;
            :focus {
                outline: none;
                box-shadow: none;
                border: 0;
            }
        }

        @media (max-width: 767px) {
            input {
                width: 100%;
            }
        }

        i {
        color: ${ theme.config.admin?.fmt.searchBox.icon.textColor };
            margin-right: 15px;
        }
    }

    `
}

export default GlobalStyle
