
import { MODULE_TABLE } from '@app/app.config'
import {
    ACTION_MUTATION_PROMISE,
    MOBILE_RESPONSIVE_LIMIT,
    TOASTIFY_DEFAULT_OPTIONS
} from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { back } from '@lagunovsky/redux-react-router'
import {
    useDoContactMutation,
    useGetContactMutation,
    useGetOverallProgressMutation
} from '@profile/api'
import {
    DO_CONTACT_FORMIK_INITIAL_VALUES,
    DO_CONTACT_VALIDATION_SCHEMA,
    IDS,
    MODULE_VERSION
} from '@profile/constants'

import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { TokenData } from '@app/types/type.token'

import DoContactInterface from '@profile/components/contactForm/DoContactInterface'
import { DoContactKeys } from '@profile/type'
import {
    HeaderContent,
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/profilePage/Components'

import { useFormik } from 'formik'
import _ from 'lodash'
import { useMediaQuery } from 'react-responsive'

const Contact = () => {
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)
    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const initializeSidebarVisibility = useInitializeSidebarVisibility()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()

    const [getContact, getContactMutation] = useGetContactMutation()
    const [doContact, doContactMutation] = useDoContactMutation()
    const [getOverallProgress, getOverallProgressMutation] = useGetOverallProgressMutation()
    const [isOutsideClick, setIsOutsideClick] = useState(false)

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        initializeSidebarVisibility(true)
    }, [])

    const unsubscribeGetContact = () => {
        const unsubscribeMutation = getContact({ } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.reset()
    }

    const unsubscribeGetOverallProgress = () => {
        const unsubscribeMutation = getOverallProgress({
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    useEffect(() => {
        if (getContactMutation.error) {
            const message = getErrorText(getContactMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [getContactMutation.error])

    useEffect(() => {
        if (doContactMutation.error) {
            const message = getErrorText(doContactMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [doContactMutation.error])

    const contactFormik = useFormik({
        initialValues: DO_CONTACT_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: DO_CONTACT_VALIDATION_SCHEMA(
            strings.app?.message.error.empty || ''
        ),
        onSubmit: (values) => {
            const call = async () => {
                if (token.valid) {
                    const newToken = await revalidateToken({
                        value: token.value,
                        id: token.id
                    }, token.mode)
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.doContact.path,
                        true
                    )

                    if (foundApiPath && newToken.value) {
                        doContact({
                            authToken: newToken.value,
                            data: {
                                categoryId: values.categoryId,
                                subject: values.subject,
                                message: values.message
                            }
                        })
                    }
                }
            }
            call()
        }
    })

    useEffect(() => {
        const data = doContactMutation.data

        const call = async () => {
            if (data?.status === 'OK') {
                contactFormik.resetForm()
            }
        }
        call()
    }, [activeModules.id, doContactMutation.data])

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGetContact()
        unsubscribeGetOverallProgress()

        let promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let getOverallProgressPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const foundApiPath = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getContact.path,
                        true
                    )

                    // NOTE: not all need to show a toast error.
                    // only do this error toast method AFTER authentication.
                    if (foundApiPath && newToken.value) {
                        promise = getContact({
                            authToken: newToken.value,
                            data: {}
                        })
                    }

                    const isValid2 = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.profile.moduleName,
                        MODULE_TABLE.profile.apiPaths.getOverallProgress.path,
                        true
                    )

                    if (isValid2 && newToken.value) {
                        getOverallProgressPromise = getOverallProgress({
                            authToken: newToken.value
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
            getOverallProgressPromise && getOverallProgressPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [activeModules.id])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'container-fluid header text-center'}>
        <div className={'align-items-center h-100 justify-content-between row'}>
            <div className={'col-auto'}>
                {/* <h4 className={'mb-0 '} onClick={() => {
                    dispatch(toggleMenuTopSidebarMobileBar(
                        !showMenuBar.mobile.top.sidebar
                    ))
                }}>
                    <i
                        className={ [
                            'fa-light',
                            showMenuBar.mobile.top.sidebar ? 'fa-bars-staggered' : 'fa-bars'
                        ].join(' ')}
                    >
                    </i>
                </h4> */}
            </div>
            <div className={'col-auto'}>
                {/* image of logo goes here. fixed width but height can change whatever */}
                <img src={'/images_new/header/logo.svg'} />
            </div>
            <div className={'col-auto'}>

            </div>
        </div>

    </div>

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        fieldName?: DoContactKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                contactFormik.handleSubmit()
            }
        }
    }

    const SubjectInput = useMemo(() => {
        const fieldName: DoContactKeys = 'subject'

        return <div className={'form-group  mb-4'}>
            <label
                htmlFor={IDS.DO_CONTACT.SUBJECT}
                className={'form-label'}
            >{strings.profile?.text.contact_form.subject}</label>
            <input
                type={'text'}
                name={fieldName}
                className={`form-control ${ contactFormik.errors.subject &&
                'border-danger' }`}
                placeholder={strings.profile?.text.contact_form.subject}
                id={IDS.DO_CONTACT.SUBJECT}
                value={contactFormik.values.subject}
                onChange={contactFormik.handleChange}
                onKeyDown={(e) => {
                    handleKeyDown(e)
                }}
                required
            />
            <div className={'form-text error'}>{
                contactFormik.errors.subject ? contactFormik.errors.subject : null
            }</div>
        </div>
    }, [
        strings,
        contactFormik.values.subject,
        contactFormik.errors.subject
    ])

    const MessageInput = useMemo(() => {
        const fieldName: DoContactKeys = 'message'

        return <div className={'form-group  mb-4'}>
            <label
                htmlFor={IDS.DO_CONTACT.MESSAGE}
                className={'form-label'}
            >{strings.profile?.text.contact_form.message}</label>
            <textarea
                name={fieldName}
                className={`form-control ${ contactFormik.errors.message &&
                'border-danger' }`}
                placeholder={strings.profile?.text.contact_form.message}
                id={IDS.DO_CONTACT.MESSAGE}
                value={contactFormik.values.message}
                onChange={contactFormik.handleChange}
                // onKeyDown={(e) => {
                //     handleKeyDown(e)
                // }}
                required
            />
            <div className={'form-text error'}>{
                contactFormik.errors.message ? contactFormik.errors.message : null
            }</div>
        </div>
    }, [
        strings,
        contactFormik.values.message,
        contactFormik.errors.message
    ])

    const SubmitButton = useMemo(() => {
        const buttonContent = doContactMutation.isLoading
            ? (
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{strings.app?.text?.submitting}</span>
                </div>
            )
            : strings.app?.text.submit

        const buttonClassName = [
            'submit-button btn btn-primary btn-lg',
            isMobile ? 'px3 py-2' : ''
        ].join(' ')

        return (<button type={'submit'}
            disabled={doContactMutation.isLoading}
            form={IDS.DO_CONTACT.FORM}
            className={buttonClassName}>
            {buttonContent}
        </button>)
    }, [strings, doContactMutation, isMobile])

    const InterfaceCard = <div className={'mb-5'}>
        <div className={'card'}>
            <div className={'card-body py-1'}>
                <div
                    className={'row row-cols-1'}
                >
                    {/* custom dropdown interface */}

                    <form
                        id={IDS.DO_CONTACT.FORM}
                        onSubmit={contactFormik.handleSubmit}>
                        <DoContactInterface
                            doContactFormik={contactFormik}
                            isOutsideClick={isOutsideClick}
                            setIsOutsideClick={setIsOutsideClick}
                            getContactResponse={getContactMutation.data}
                        />
                        {SubjectInput}
                        {MessageInput}
                    </form>

                    <div className={[
                        'align-items-center g-3 justify-content-end pb-4 row'
                    ].join(' ')}>
                        <div className={'col-auto'}>
                            {
                                doContactMutation.data?.message && <p
                                    className={'fw-semibold mb-0 text-center'}
                                >
                                    {doContactMutation.data.message}
                                </p>
                            }
                        </div>
                        <div className={'col-auto '}>
                            {SubmitButton}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    const desktopResult = <>
        <HeaderImage
            className={'p-4'}
            url={getOverallProgressMutation.data?.data.progressData.headerImage || ''} >
        </HeaderImage>
        <HeaderGradient />
        <HeaderContent
            className={'p-4'}
        >
            <button
                type={'button'}
                className={[
                    'btn btn-round btn-rounded'
                ].join('')}
                onClick={(e) => {
                    e.preventDefault()
                    dispatch(back())
                }}
            >
                <i
                    className={[
                        'fa-light',
                        'fa-arrow-left'
                    ].join(' ')}
                    aria-hidden={'true'}>
                </i>
            </button>
        </HeaderContent>
        <div className={'main-content'}>
            <div className={'container-fluid mx-auto'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-10 col-md-8'}>
                        <h1 className={'mt-4'}>{strings.profile?.text.contact_form.title}</h1>
                        <p className={!isMobile ? 'my-5' : 'mt-3 mb-5'}>
                            {strings.profile?.text.contact_form.description}
                        </p>
                        {InterfaceCard}
                    </div>
                </div>

            </div>
        </div>
        <div
            className={'position-fixed bottom-0 end-0 pe-5 fs-label fw-light version-text'}
        >
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        <div className={'main-content'}>
            <div className={'container-fluid px-4'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-12'}>
                        <div className={'row align-items-center g-3'}>
                            <div className={'col-auto'}>
                                <button
                                    type={'button'}
                                    className={[
                                        'btn btn-round btn-rounded'
                                    ].join('')}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        dispatch(back())
                                    }}
                                >
                                    <i
                                        className={[
                                            'fa-light',
                                            'fa-arrow-left'
                                        ].join(' ')}
                                        aria-hidden={'true'}>
                                    </i>
                                </button>
                            </div>
                            <div className={'col-auto'}>
                                <h2 className={'mb-0'}>{strings.profile?.text
                                    .contact_form.title}</h2>
                                <p className={!isMobile ? 'my-5' : 'mt-3 mb-5'}>
                                    {strings.profile?.text.contact_form.description}
                                </p>
                                {InterfaceCard}
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </>

    return <div className={'profile-page position-relative'}>
        {/* the container */}

        {
            isMobile ? mobileResult : desktopResult
        }
    </div>
}

export default Contact
