import { MODULE_TABLE } from '@app/app.config'
import { MOBILE_RESPONSIVE_LIMIT, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import FixedImage from '@app/components/FixedImage'
import { selectActiveModules, selectStrings, setShowMessages } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { replace } from '@lagunovsky/redux-react-router'
import { useDoLoginMutation, useGetTokenMutation, useTokenValidMutation } from '@login/api'
import MicrosoftPopupButton from '@login/components/SSOMicrosoft/MicrosoftPopupButton'
import SSOLayout from '@login/components/SSOMicrosoft/SSOAutoLogin'
import {
    IDS,
    LOGIN_FORMIK_INITIAL_VALUES,
    LOGIN_VALIDATION_SCHEMA,
    MODULE_VERSION
} from '@login/constants'
import { useInitializeSidebarVisibility } from '@login/MutationProvider/initializeSidebarVisibility'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'

import { setIsFullscreenOverlay } from '@login/slices/slice.login'
import { resetWorkflow } from '@login/slices/slice.workflow'
import { LoginKeys } from '@login/type'
import { resetReasonWithMe } from '@reasonWithMe/slice'
import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/login/Components'

import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

const Login = () => {
    const [doLogin, doLoginMutation] = useDoLoginMutation({
        fixedCacheKey: 'shared-login-key'
    })

    const activeModules = useAppSelector(selectActiveModules)
    const strings = useAppSelector(selectStrings)
    const appMessage = strings.app?.message
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)

    const initializeSidebarVisibility = useInitializeSidebarVisibility()

    const validateAPIPath = useValidateAPIPath()
    const validateRoute = useValidateRoute()

    const [showPassword, toggleShowPassword] = useState(false)
    const [tokenValid] = useTokenValidMutation()
    const [doGetToken] = useGetTokenMutation()

    const isMobile = useMediaQuery({
        query: `(max-width: ${ MOBILE_RESPONSIVE_LIMIT })`
    })

    useEffect(() => {
        initializeSidebarVisibility(false)
    }, [])

    // a case made for tokenValid, getModules, and getLanguageFile.
    // if you are on this page, under any circumstance, remove the overlay.
    useEffect(() => {
        // disable fullscreen
        dispatch(setIsFullscreenOverlay({
            show: false,
            text: ''
        }))

        // if you leave the modal open during expiration and you go to this page
        // set to false.
        dispatch(setShowMessages(false))
    }, [])

    const {
        handleSubmit, handleChange, values, errors
    } = useFormik({
        initialValues: LOGIN_FORMIK_INITIAL_VALUES,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: LOGIN_VALIDATION_SCHEMA(
            appMessage?.error.username_validation_message || '',
            appMessage?.error.empty || ''
        ),
        onSubmit: useDebouncedCallback(async (values) => {
            /** you want to revalidate the token before doLogging in
             * if it's valid, get another token and then you can proceed.
             *
             * problem is that if you reset the token, you'll lose
             * access to the login page and do it all over again.
             *
             * so a fair way to do it is to make the tokenValid api call
             * and get its status.
             */

            console.log('doing token validation before doLogin on form')
            const revalidation = await tokenValid({ token: token.value })
                .unwrap()

            let activeToken = token.value
            if (revalidation.status === 'OK') {
                activeToken = revalidation.token
            } else if (revalidation.status === 'NOT_OK') {
                // get a new token again from guest and proceed.
                const guestRetrieval = await doGetToken({
                    data: { locale: navigator.language }
                }).unwrap()

                if (guestRetrieval.status === 'OK') {
                    activeToken = guestRetrieval.token
                }
            }

            // console.log('token string after simple revalidation is: ', activeToken)
            if (activeToken) {
                // proceed with login and no need to setToken
                const isValid = validateAPIPath(
                    activeModules.arr,
                    MODULE_TABLE.login.moduleName,
                    MODULE_TABLE.login.apiPaths.doLogin.path,
                    true
                )

                if (isValid) {
                    doLogin({
                        authToken: token.value,
                        username: values.username,
                        password: values.password
                    })
                }
            }
        }, 1000)
    })

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>,
        fieldName?: LoginKeys
    ) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const nextInput = document.querySelector(`[name=${ fieldName }]`) as HTMLInputElement
            if (nextInput) {
                nextInput.focus()
                nextInput.select()
            } else {
                handleSubmit()
            }
        }
    }

    const UsernameInput = useMemo(() => {
        const fieldName: LoginKeys = 'username'

        return <div className={'form-group  mb-4'}>
            <label htmlFor={IDS.LOGIN.USERNAME} className={'form-label'}>{
            `${ strings.login?.text?.form.username || '' } `
            }</label>
            <input type={'text'}
                value={values.username}
                className={`form-control ${ errors.username ? 'border-danger' : '' }`}
                name={fieldName}
                placeholder={strings.login?.text?.form.username || ''}
                id={IDS.LOGIN.USERNAME}
                onChange={handleChange}
                onKeyDown={(e) => {
                    handleKeyDown(e, 'password')
                }}
                required />
            <div className={'form-text error'}>{
                errors.username ? errors.username : null
            }</div>
        </div>
    }, [strings.login?.text, values.username, errors.username])

    const PasswordInput = useMemo(() => {
        const fieldName: LoginKeys = 'password'

        return <div className={'form-group'}>
            <label htmlFor={IDS.LOGIN.PASSWORD} className={'form-label'}>
                {strings.login?.text?.form.password || ''}</label>
            <div className={'form-control-wrapper icon-end'}>
                <input type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    className={`form-control ${ errors.password ? 'border-danger' : '' }`}
                    placeholder={strings.login?.text?.form.password || ''} id={IDS.LOGIN.PASSWORD}
                    name={fieldName} onChange={handleChange}
                    data-parsley-trigger={'blur'}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') { handleSubmit() }
                    }}
                    required />
                <label className={'form-floaticon'} htmlFor={IDS.LOGIN.PASSWORD}>
                    <div className={'icon '} onClick={() => {
                        toggleShowPassword(!showPassword)
                    }}>
                        {!showPassword
                            ? <i className={'fa-regular fa-eye'}
                                aria-hidden={'true'}>
                            </i>
                            : <i className={'fa-regular fa-eye-slash'}
                                aria-hidden={'true'}>
                            </i>}
                    </div>
                </label>
            </div>
            <div className={'form-text error'}>{
                errors.password ? errors.password : null
            }</div>
        </div>
    }, [strings.login?.text, values.password, errors.password, showPassword])

    const ForgotPasswordText = useMemo(() => {
        return <a className={'fs-6 text-decoration-none clickable fw-bold'} onClick={(e) => {
            e.preventDefault()

            const isValid = validateRoute(
                activeModules.arr,
                MODULE_TABLE.login.moduleName,
                MODULE_TABLE.login.routes.resetPassword,
                true
            )

            if (isValid) {
                dispatch(replace(isValid.route))
            }
        }}>
            {strings.login?.text?.form.forgot_password}
        </a>
    }, [strings.login?.text, activeModules.id])

    useEffect(() => {
        if (doLoginMutation.error) {
            const message = getErrorText(doLoginMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [doLoginMutation.error])

    /** reset all slices on mount. */
    useEffect(() => {
        // dispatch(resetToken())
        dispatch(resetReasonWithMe())
        dispatch(resetWorkflow())

        // make sure that if you are in the doLogin page, reset it
        // as it has a fixedCacheKey.
        doLoginMutation.reset()
    }, [])

    const SubmitButton = useMemo(() => {
        const buttonContent = doLoginMutation.isLoading
            ? (
                <div className={'spinner-container'}>
                    <span className={'spinner-border spinner-border-sm'}></span>
                    <span className={'ms-2'}>{strings.login?.text?.form.loading_button}</span>
                </div>
            )
            : strings.login?.text?.form.submit_button

        return (<button type={'submit'}
            disabled={doLoginMutation.isLoading}
            form={IDS.LOGIN.FORM} className={'btn btn-primary btn-lg w-100'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    {buttonContent}
                </div>
            </div>
        </button>)
    }, [
        strings.login?.text,
        doLoginMutation
    ])

    /** components that show up in mobile header only */
    const mobileHeader = <div className={'header py-3 text-center'}>
        {/* image of logo goes here. fixed width but height can change whatever */}
        <img src={'/images_new/header/logo.svg'} />
    </div>

    const desktopResult = <>
        <FixedImage
            imageUrl={'/images_new/header/logo.svg'} position={'bottom-middle'}
        />
        {/* put header image here */}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />

        <div className={'position-absolute w-100 main-content'}>
            <div className={'container card shadow border-0 mb-5'}>

                <div className={'justify-content-center row'}>
                    <div className={'col-10 px-0'}>
                        {/* status card */}
                        <div className={'status-card card border-0'}>
                            {
                                (doLoginMutation.data?.status === 'OK' &&
                                         token.details.name)
                                    ? <h4><p className={'mt-2'}>{`
                                        ${ strings.login?.message?.hello } ${ token.details.name }
                                    `.trim()}</p></h4>
                                    : ''
                            }
                            <span>
                                {`${ strings.login?.text?.title.login } `}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'row form-container'}>
                    <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                        <form id={IDS.LOGIN.FORM} onSubmit={handleSubmit}>
                            {UsernameInput}
                            {PasswordInput}
                        </form>

                    </div>
                </div>
                <div className={'row mb-5'}>
                    <div className={'col-12 col-md-8 col-lg-6 mx-auto text-end'}>
                        {ForgotPasswordText}
                    </div>
                </div>
                <div className={'row align-items-center justify-content-center'}>
                    <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                        {SubmitButton}
                    </div>
                </div>
                <div className={'row align-items-center justify-content-center'}>
                    <div className={'col-12 col-sm-8 col-md-8 col-lg-5 mt-3  '}>
                        {/* this should just be a button that takes you to a popup window. */}
                        {/* no conditions, just that */}
                        <MicrosoftPopupButton />
                    </div>
                </div>
            </div>
        </div>
        <div className={
            'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'
        }>
            {MODULE_VERSION}
        </div>
    </>

    const mobileResult = <>
        {mobileHeader}
        <HeaderImage url={'/images_new/header/1.png'} />
        <HeaderGradient />
        <div className={'container-fluid main-content px-12'}>
            <div className={'justify-content-center row'}>
                <div className={'col-12'}>
                    {/* status card */}
                    <div className={'status-card card border-0'}>
                        {
                            (doLoginMutation.data?.status === 'OK' &&
                                         token.details.name)
                                ? <span className={'fw-semibold mb-2'}>{`
                                        ${ strings.login?.message?.hello } ${ token.details.name }
                                    `.trim()}</span>
                                : ''
                        }
                        <span>
                            {`${ strings.login?.text?.title.login } `}
                        </span>
                    </div>
                </div>
            </div>

            <div className={'form-container'}>
                <div >
                    <div className={'row'}>
                        <div className={'col-12 col-md-8 col-lg-6 mx-auto'}>
                            <form id={IDS.LOGIN.FORM} onSubmit={handleSubmit}>
                                {UsernameInput}
                                {PasswordInput}
                            </form>

                        </div>
                    </div>
                    <div className={'row mb-5'}>
                        <div className={'col-12 col-md-8 col-lg-6 mx-auto text-end'}>
                            {ForgotPasswordText}
                        </div>
                    </div>
                </div>
                <div >

                    <div className={'row align-items-center justify-content-center'}>
                        <div className={'col-12 col-sm-8 col-md-8 col-lg-5  '}>
                            {SubmitButton}
                        </div>
                    </div>

                    <div className={'row align-items-center justify-content-center'}>
                        <div className={'col-12 col-sm-8 col-md-8 col-lg-5 mt-3  '}>
                            <SSOLayout />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>

    return (<div>
        <div className={'login-page'}>

            {
                isMobile ? mobileResult : desktopResult
            }

        </div>

    </div>
    )
}

export default Login
