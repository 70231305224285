import { MODULE_TABLE } from '@app/app.config'

import _ from 'lodash'

import ForcePasswordChangePage from '@login/components/ForcePasswordChangePage'
import HomePage from '@login/components/HomePage'
import LoginPage from '@login/components/LoginPage'
import LogoutPage from '@login/components/LogoutPage'
import MFAPage from '@login/components/MFAPage'
import ResetPassword from '@login/components/ResetPasswordPage'
import SSOAutoLogin from '@login/components/SSOMicrosoft/SSOAutoLogin'
import { SystemModule } from '@login/type'
import React from 'react'

interface ComponentProps {
    config: SystemModule[]
}
const selectedModule = MODULE_TABLE.login
/** The final array of route configurations with their corresponding components */
const Routes = ({ config }: ComponentProps) => {
    const arr: {
            moduleName: string,
            path: string,
            element: React.ReactNode
        }[] = []

    const found = _.find(config, (o) => {
        return o.moduleName === selectedModule.moduleName
    })

    if (found) {
        _.forEach(_.cloneDeep(found.feRoutes), (o) => {
            let element: React.ReactNode | undefined
            // a new variable named route to pass in so we can cater to routes
            // with parameters on them.
            let routeName = ''
            switch (o.route) {
                case selectedModule.routes.login: {
                    element = <LoginPage/>
                    routeName = o.route
                    break
                }
                case selectedModule.routes.sso: {
                    element = <SSOAutoLogin/>
                    routeName = o.route
                    break
                }
                case selectedModule.routes.logout: {
                    element = <LogoutPage/>
                    routeName = o.route
                    break
                }
                case selectedModule.routes.home: {
                    element = <HomePage />
                    routeName = o.route
                    break
                }
                case selectedModule.routes.mfaPass: {
                    element = <MFAPage />
                    routeName = o.route
                    break
                }
                case selectedModule.routes.resetPassword: {
                    element = <ResetPassword />
                    routeName = o.route
                    break
                }
                case selectedModule.routes.forcePassword: {
                    element = <ForcePasswordChangePage />
                    routeName = o.route
                    break
                }
                case selectedModule.routes.loginByEmail: {
                    element = <></>
                    routeName = o.route
                    // also allow those without ":route"

                    const originalRoute = selectedModule.routes.loginByEmail
                    const updatedRoute = _.replace(originalRoute, '/:route', '')

                    arr.push({
                        moduleName: found.moduleName,
                        path: updatedRoute,
                        element
                    })
                    break
                }
                default:
                    break
            }

            element && arr.push({
                moduleName: found.moduleName,
                path: routeName,
                element
            })
        })
    }

    return arr
}

export default Routes
