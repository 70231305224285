import { MODULE_TABLE } from '@app/app.config'
import { ACTION_MUTATION_PROMISE, TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { getErrorText } from '@app/app.method'
import { selectActiveModules, selectDateFormats, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useGPACareplanStepIdDetailsMutation } from '@doc/api'
import { GPACareplanStepIdDetails } from '@doc/type'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

// import _ from 'lodash'
import Pagination from '@app/components/Pagination'
import { BuildingBlock, ReasoningCard } from '@careplan/type'
// eslint-disable-next-line max-len
import ReadonlyReasonWithMe from '@doc/components/patients/details/components/modal/ReadonlyReasonWithMe/Main'
import { format, fromUnixTime } from 'date-fns'
import _ from 'lodash'
import { useEffect, useState } from 'react'
// eslint-disable-next-line max-len

import { toast } from 'react-toastify'
interface ComponentProps {
    buildingBlock: BuildingBlock | undefined,
    userId: string | undefined
    careplanStepId: string | undefined
}

const ReasoningBlockModal = (props : ComponentProps) => {
    const activeModules = useAppSelector(selectActiveModules)

    const revalidateToken = useRevalidateToken()
    const token = useAppSelector(selectToken)
    const validateAPIPath = useValidateAPIPath()
    const dateFormats = useAppSelector(selectDateFormats)

    const strings = useAppSelector(selectStrings)

    const [
        gPACSIDetailsReasoningSessions,
        gPACSIDetailsReasoningSessionsMutation
    ] = useGPACareplanStepIdDetailsMutation()

    const [
        gPACSIDetailsReasoningSet,
        gPACSIDetailsReasoningSetMutation
    ] = useGPACareplanStepIdDetailsMutation()

    const sessionData = gPACSIDetailsReasoningSessionsMutation.data as
     GPACareplanStepIdDetails['response']['reasoningSessions'] | undefined

    const reasoningData = gPACSIDetailsReasoningSetMutation.data as
     GPACareplanStepIdDetails['response']['reasoningData'] | undefined

    const [activeReasoningId, setActiveReasoningId] = useState<string>('')
    const [reasoningDetailsCurrentPage, setReasoningDetailsCurrentPage] = useState(0)
    const [reasoningButtonsCurrentPage, setReasoningButtonsDetailsCurrentPage] = useState(0)

    const unsubscribeGPACSIDetailsReasoningSessions = () => {
        const unsubscribeMutation = gPACSIDetailsReasoningSessions({
            urlParams: {},
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const unsubscribeGPACSIDetailsReasoningSet = () => {
        const unsubscribeMutation = gPACSIDetailsReasoningSet({
            urlParams: {},
            data: {}
        } as any)
        unsubscribeMutation.abort()
        unsubscribeMutation.unsubscribe()
    }

    const contents = props.buildingBlock?.buildingBlockValue !== null &&
    typeof props.buildingBlock?.buildingBlockValue !== 'string' &&
    !_.isArray(props.buildingBlock?.buildingBlockValue)
        ? props.buildingBlock?.buildingBlockValue as ReasoningCard
        : {
            reasoningSetTitle: '',
            reasoningSetDescription: '',
            // for reasoning set.
            reasoningSetId: props.buildingBlock?.buildingBlockValue
        } as ReasoningCard

    const fetchData = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
             * to the server.
             */
        unsubscribeGPACSIDetailsReasoningSessions()

        const promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let gPACareplanStepIdDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths.gPACareplanStepIdDetails.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        const skip = reasoningButtonsCurrentPage * (sessionData
                            ?.data.limit || 1)

                        const totalRecords = (sessionData
                            ?.data.totalCount[0]?.totalRecords || 0) - 1

                        gPACareplanStepIdDetailsPromise = gPACSIDetailsReasoningSessions({
                            authToken: newToken.value,
                            data: {
                                type: 'reasoningSessions',
                                userId: props.userId || '',
                                reasoningSetId: contents.reasoningSetId,
                                skip: skip > (sessionData?.data
                                    .totalCount[0]?.totalRecords || 0)
                                    ? totalRecords
                                    : skip,
                                limit: sessionData?.data.limit
                            },
                            urlParams: {
                                careplanStepId: props.careplanStepId || ''
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
            gPACareplanStepIdDetailsPromise && gPACareplanStepIdDetailsPromise.abort()
        }
    }

    const fetchData2 = (token: TokenData) => {
        /** this will reset the data to unInitialized AND prevent sending a request
         * to the server.
         */
        unsubscribeGPACSIDetailsReasoningSet()

        const promise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let gPACareplanStepIdDetailsPromise = _.cloneDeep(ACTION_MUTATION_PROMISE)
        let isMounted = true

        const call = async () => {
            if (token.valid) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths.gPACareplanStepIdDetails.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        const skip = reasoningDetailsCurrentPage * (reasoningData
                            ?.data.limit || 1)

                        const totalRecords = (reasoningData
                            ?.data.totalCount[0]?.totalRecords || 0) - 1

                        gPACareplanStepIdDetailsPromise = gPACSIDetailsReasoningSet({
                            authToken: newToken.value,
                            data: {
                                type: 'reasoningData',
                                userId: props.userId || '',
                                reasoningSessionId: activeReasoningId,
                                skip: skip > (reasoningData?.data
                                    .totalCount[0]?.totalRecords || 0)
                                    ? totalRecords
                                    : skip,
                                limit: reasoningData?.data.limit
                            },
                            urlParams: {
                                careplanStepId: props.careplanStepId || ''
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
            promise && promise.abort()
            gPACareplanStepIdDetailsPromise && gPACareplanStepIdDetailsPromise.abort()
        }
    }

    useEffect(() => {
        return fetchData(token)
    }, [reasoningButtonsCurrentPage])

    useEffect(() => {
        if (activeReasoningId) {
            return fetchData2(token)
        }

        return () => {}
    }, [activeReasoningId, reasoningDetailsCurrentPage])

    useEffect(() => {
        // console.log(contentData)
    }, [sessionData])

    useEffect(() => {
        if (gPACSIDetailsReasoningSessionsMutation.error) {
            const message = getErrorText(gPACSIDetailsReasoningSessionsMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [gPACSIDetailsReasoningSessionsMutation.error])

    useEffect(() => {
        if (gPACSIDetailsReasoningSetMutation.error) {
            const message = getErrorText(gPACSIDetailsReasoningSetMutation.error)
            console.error(message)
            toast.error(message, { ...TOASTIFY_DEFAULT_OPTIONS })
        }
    }, [gPACSIDetailsReasoningSetMutation.error])

    const reasoningSessionButtons = _.map(
        sessionData?.data?.reasoningSessions, (o) => {
            // will reuse the same style from the popover.
            const key = `reasoning-session-details-${ o.reasoningId }`

            return <div key={key} className={'col'}>
                <button type={'button'}
                    className={[
                        'btn w-100 py-2',
                        o.reasoningId === activeReasoningId ? 'btn-primary' : 'btn-secondary'
                    ].join(' ')}
                    onClick={() => {
                        // clicking on this button sets the active session id.
                        setActiveReasoningId(o.reasoningId)
                    }}>
                    <span>
                        {[
                            strings.doc?.text.patient.actions_in_app
                                .answer_summary.second_step?.added_on,
                            ': '
                        ].join(' ')}
                    </span>
                    <span>
                        {format(fromUnixTime(
                            o.addedOn || 0
                        ), dateFormats.format9) || ''}
                    </span>
                </button>
            </div>
        }
    )

    const LoadingReasoning = (
        <small className={'d-block text-center py-2'}>
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        </small>
    )

    return <div>
        {/* generate buttons */}
        {
            gPACSIDetailsReasoningSessionsMutation.isLoading
                ? LoadingReasoning
                : gPACSIDetailsReasoningSessionsMutation.isSuccess
                    ? <div className={'card'}>
                        <div className={'card-body'}>
                            <h4 className={'mb-5 mt-3 text-center'}>{
                                strings.doc
                                    ?.text.patient.actions_in_app.answer_summary.second_step.reasoning_sessions}
                            </h4>
                            <div className={'row row-cols-2 g-2 mb-2'}>
                                {reasoningSessionButtons}
                            </div>
                            <div>
                                {
                                    sessionData?.status === 'OK'
                                        ? <>
                                            {
                                                (sessionData?.data?.limit || 0) > (sessionData
                                                    ?.data?.totalCount?.[0]?.totalRecords || 0)
                                                    ? <></>
                                                    : <Pagination
                                                        currentPage={reasoningButtonsCurrentPage}
                                                        setCurrentPageState={setReasoningButtonsDetailsCurrentPage}
                                                        limit={sessionData?.data?.limit ||
                                            sessionData?.data?.totalCount[0]?.totalRecords || 1}
                                                        skip={sessionData?.data?.skip || 1}
                                                        totalRecords={sessionData?.data?.totalCount[0]?.totalRecords || 0}
                                                    />
                                            }
                                            {
                                                (sessionData?.data?.totalCount?.[0]?.totalRecords || 0) === 0 && (
                                                    <span>{
                                                        strings.app?.message.error.empty_records || ''
                                                    }</span>
                                                )
                                            }
                                        </>
                                        : <></>
                                }

                            </div>
                        </div>
                    </div>
                    : reasoningData?.message
        }
        {
            gPACSIDetailsReasoningSetMutation.isLoading
                ? LoadingReasoning
                : gPACSIDetailsReasoningSetMutation.isSuccess
                    ? <div className={'card mt-5'}>
                        <div className={'card-body'}>
                            <ReadonlyReasonWithMe reasoningData={reasoningData}
                                currentPage={reasoningDetailsCurrentPage}
                                setCurrentPageState={setReasoningDetailsCurrentPage}
                                reasoningModal={{
                                    reasoningSessionId: activeReasoningId,
                                    reasoningSetId: contents.reasoningSetId,
                                    addedOn: 0
                                }}
                                userId={props.userId}
                                careplanStepId={props.careplanStepId}
                            />
                        </div>
                    </div>
                    : reasoningData?.message
        }
    </div>
}

export default ReasoningBlockModal
