import { OpenListActions, OpenListInputs, OpenListValues } from '@reasonWithMe/type'
import produce from 'immer'
import _ from 'lodash'
import { useEffect, useReducer } from 'react'
import { v4 as uuidV4 } from 'uuid'

type AnswerTypes = 'input' | 'radio' | 'checkbox' | 'input-list' | 'input-list-split' | 'multi-list'

interface ComponentProps {
    answerType: AnswerTypes
    outerIndex: number,
    innerIndex: number,
    formInput: OpenListInputs,
    findPreviousInput: any,
    formsDispatch: (value: OpenListActions) => void
    form: OpenListValues,
}

const InputList = ({
    answerType, outerIndex, innerIndex,
    formInput, findPreviousInput, formsDispatch, form
}: ComponentProps) => {
    // const prevInputStrings: string[] = (findPreviousInput
    //     ?.userTypedAnswer || []) as string[]

    const inputArr = formInput
        .userTypedAnswer as string[]

    // console.log(inputArr)

    // get the right input id list for this one.

    // because each row needs to be a unique id as well, let's make a useState.

    // useEffect(() => {
    //     console.log('input list component is mounted on add and remove')
    // }, [])

    const [idsState, idsReducer] = useReducer((
        state: string[],
        action: | { type:'ADD_ROW', value: string }
        | { type:'REMOVE_ROW', index: number }
    ) => {
        switch (action.type) {
            case 'ADD_ROW':
                return produce(state, draft => {
                    draft.push(action.value)
                })
            case 'REMOVE_ROW':
                return produce(state, draft => {
                    if (action.index !== undefined && action.index > -1) {
                        _.pullAt(draft, [action.index])
                    }
                })
        }
    // on default from the OpenList component, a row with an empty string
    // is provided. so assign a unique id right away.
    }, _.map(inputArr, (o) => uuidV4()))

    useEffect(() => {
        // console.log('input list ids: ', idsState)
    }, [idsState])

    const inputList = _.map(inputArr, (inputString,
        inputIndex) => {
        // const isDisabled = prevInputStrings
        //     .includes(inputString)

        const isDisabled = (findPreviousInput &&
                                 !findPreviousInput?.answerEditable) ||
                            !formInput.answerEditable

        const showButtons = !isDisabled && (<>
            {
                inputArr.length > 1 && (
                    <div className={'col-auto'}>
                        <div className={'question-plussign ms-auto'}
                            onClick={() => {
                                const newArray = [...inputArr]

                                // console.log('input index: ', inputIndex)
                                // console.log('Before: ', inputArr)

                                _.pullAt(newArray, inputIndex)

                                // console.log('After: ', newArray)

                                formsDispatch({
                                    type: 'UPDATE_FORM',
                                    id: form.id,
                                    columnOrder: Number(formInput
                                        .answerValue || 0),
                                    value: newArray
                                })

                                // then remove the id by index
                                idsReducer({
                                    type: 'REMOVE_ROW',
                                    index: inputIndex
                                })
                            }}>
                            <i className={'fa-light fa-minus mx-auto'}></i>
                        </div>

                    </div>
                )
            }

            {
                inputIndex === inputArr.length - 1 && (
                    <div className={'col-auto'}>
                        <div className={'question-plussign ms-auto'}
                            onClick={() => {
                                formsDispatch({
                                    type: 'UPDATE_FORM',
                                    id: form.id,
                                    columnOrder: Number(formInput
                                        .answerValue || 0),
                                    value: [
                                        ...inputArr,
                                        ''
                                    ]
                                })

                                idsReducer({
                                    type: 'ADD_ROW',
                                    value: uuidV4()
                                })
                            }}>
                            <i
                                className={'fa-light fa-plus mx-auto'}></i>
                        </div>

                    </div>
                )
            }
        </>)

        // this needs its own unique id as well whether you like it or not.
        return <li key={[
            'input-cell', form.id, idsState[inputIndex]
        ].join('-')}
        className={'align-middle text-center mb-4'}>
            <div className={'row align-items-center'}>
                <div className={'col'}>
                    <input
                        className={'form-control form-control-sm'}
                        type={'text'}
                        defaultValue={inputString}
                        disabled={isDisabled}
                        onChange={(e) => {
                            const newArray = [...inputArr]
                            newArray[inputIndex] = e.target.value
                            formsDispatch({
                                type: 'UPDATE_FORM',
                                id: form.id,
                                columnOrder: Number(formInput
                                    .answerValue || 0),
                                value: newArray
                            })
                        }}
                        onBlur={(e) => {
                            const newArray = [...inputArr]
                            newArray[inputIndex] = e.target.value
                            formsDispatch({
                                type: 'UPDATE_FORM',
                                id: form.id,
                                columnOrder: Number(formInput
                                    .answerValue || 0),
                                value: newArray
                            })
                        }}
                    />

                </div>

                {showButtons}

            </div>

        </li>
    })

    return <ol type={'a'} className={'mb-0'}>
        {
            inputList
        }
    </ol>
}

export default InputList
