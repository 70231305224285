
import { useValidateRoute } from '@login/MutationProvider/validateRoute'
import { useValidateRouteAll } from '@login/MutationProvider/validateRouteAll'

import { MODULE_TABLE } from '@app/app.config'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules } from '@app/slices/slice.app'
import { replace } from '@lagunovsky/redux-react-router'
import { MODULE_VERSION } from '@login/constants'
import { HeaderContent } from '@stylesheet/globalStyles/group/endUser/profilePage/Components'
import {
    HeaderGradient,
    HeaderImage
} from '@stylesheet/globalStyles/group/endUser/recoveryPlan/Components'
import { useEffect } from 'react'

import { selectToken } from '@app/slices/slice.token'

const HomePage = () => {
    const dispatch = useAppDispatch()

    const validateRoute = useValidateRoute()
    const validateRouteAll = useValidateRouteAll()
    const activeModules = useAppSelector(selectActiveModules)

    const token = useAppSelector(selectToken)

    const goToDefaultRoute = () => {
        // console.log('returnLink is empty. ' +
        // 'auth is finished, redirecting user to default route')

        let routeToUse = token.details.roid

        if (['/', ''].includes(token.details.roid)) {
            console.warn('Default route changed to login.')
            routeToUse = MODULE_TABLE.login.routes.login
        }

        const defaultRoute = validateRouteAll(activeModules.arr,
            routeToUse, false)
        console.log('route: ', defaultRoute)
        if (defaultRoute.route) {
            dispatch(replace(routeToUse))
        }
    }

    // redirect to default route.
    useEffect(() => {
        goToDefaultRoute()
    }, [activeModules.id])

    return <>
        <div>
            <div className={'login-page position-relative'}>
                {/* put header image here */}
                <HeaderImage url={''} />
                <HeaderGradient />
                <HeaderContent
                    className={'p-4'}
                >
                    <button
                        type={'button'}
                        className={[
                            'btn btn-round btn-rounded'
                        ].join('')}
                        onClick={(e) => {
                            e.preventDefault()

                            const isValid = validateRoute(
                                activeModules.arr,
                                MODULE_TABLE.login.moduleName,
                                MODULE_TABLE.login.routes.logout,
                                true
                            )

                            if (isValid) {
                                dispatch(replace(isValid.route))
                            }
                        }}
                    >
                        <i
                            className={[
                                'fa-light',
                                'fa-arrow-left'
                            ].join(' ')}
                            aria-hidden={'true'}>
                        </i>
                    </button>
                </HeaderContent>

            </div>

            <div className={'position-fixed bottom-0 end-0 py-2 pe-5 fs-label fw-light'}>
                {MODULE_VERSION}
            </div>
        </div>
    </>
}
export default HomePage
